import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useContainer} from 'timepad-di';
import {useLocation, useNavigate} from 'react-router';
import {Button, Dropdown, Row, useMedia, Divider, SearchInput, component, SpinLoader} from 'front-components';
import cx from 'classnames';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {SearchStore} from 'stores/Search';
import {NavHeaderQaAttrs} from 'interfaces/models';
import IconLocation from 'assets/svg/32/icon-new-pin-32.svg';
import SuccessIcon from 'assets/svg/24/icon-check-long-24.svg';
import {IEventCityFilter} from 'services/Api/DictionariesApiService';
import {isCategoryOrSearchPage, getRoute, isCollectionsPage, isFeedPage, TPRoutes} from 'routing/config';
import {useFilterParams} from 'services/hooks';

interface ICityFilterProps {
    color?: string;
}

export const CityFilter: React.FC<ICityFilterProps> = observer(({color}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchStorage] = useContainer(SearchStore);
    const {city, setCity, cityFilters, isCityFiltersLoading, setCityFiltersLoading, filters} = searchStorage;

    const [searchName, setSearchName] = useState('');

    const filterNavigate = (params: {city?: string; category?: number; searchParams?: string}): void => {
        const {city, category, searchParams} = params;
        if (isCategoryOrSearchPage(location.pathname)) {
            const baseUrl = category ? `/category/${category}` : '/search';
            return navigate(`/${city}${baseUrl}?${searchParams.toString()}`);
        }
        if (isFeedPage(location.pathname)) {
            return navigate(getRoute(TPRoutes.CITY_FEED, {city}));
        }
        if (isCollectionsPage(location.pathname)) {
            return navigate(getRoute(TPRoutes.CITY_COLLECTIONS, {city}));
        }
    };

    const {matchCategory, pushHandle} = useFilterParams(filterNavigate);

    const [isOpen, setIsOpen] = useState(false);

    const {isMobilePortraitMax} = useMedia();

    const handleResetSearchInput = () => setSearchName('');

    const handleFilterRowClick = (city: IEventCityFilter) => () => {
        setCity(city);
        setIsOpen(false);
        pushHandle({city: city.alias, category: matchCategory});
        handleResetSearchInput();
    };

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityFiltersLoading(true);
        const value = event.currentTarget.value;
        setSearchName(value);
        searchStorage.getCityFiltersByName(value);
    };

    const cities = searchName ? cityFilters : filters?.cityFilters;

    return (
        !!city && (
            <Dropdown
                customMobileBreakpoint={767}
                fixPositionOnScroll={isMobilePortraitMax}
                trigger={() => (
                    <div
                        className="city--dropdown-btn"
                        onClick={() => setIsOpen(!isOpen)}
                        data-analytics={DataAnalytics.userPlace}
                        data-qa={NavHeaderQaAttrs.BtnGeolocation}
                    >
                        <Button
                            variant={Button.variant.transparent}
                            label={city.title}
                            labelColor={color}
                            iconAlignment={Button.alignment.left}
                            icon={<IconLocation />}
                        />
                    </div>
                )}
                show={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    handleResetSearchInput();
                }}
                priorityPositions={['bottom-left']}
            >
                <Dropdown.Header down element>
                    <Row className={component('city-filter', 'list-item')({['cursor-default']: true})}>
                        <Row.Body>
                            <SearchInput
                                value={searchName}
                                onChange={handleSearchInputChange}
                                onReset={handleResetSearchInput}
                                placeholder="Поиск по городам"
                                withSearchIcon
                            />
                        </Row.Body>
                    </Row>
                </Dropdown.Header>
                <div
                    className={cx(
                        component('theme--darkpic-bg')(),
                        component('theme--darkpic')(),
                        component('city-filter', 'cities-list')({['mobile-height']: isMobilePortraitMax}),
                    )}
                >
                    {isCityFiltersLoading ? (
                        <Row className={component('city-filter', 'list-item')({['cursor-default']: true})}>
                            <Row.Body>
                                <SpinLoader />
                            </Row.Body>
                        </Row>
                    ) : (
                        <>
                            {!searchName ? (
                                <>
                                    <Row className={component('city-filter', 'list-item')({['cursor-default']: true})}>
                                        <Row.Body>
                                            <Row.Text>{city.title}</Row.Text>
                                        </Row.Body>
                                        <Row.Icon>
                                            <SuccessIcon />
                                        </Row.Icon>
                                    </Row>
                                    <Divider />
                                    <Row disabled>
                                        <Row.Body>
                                            <Row.Text className="t-color-gray-35">Популярные города</Row.Text>
                                        </Row.Body>
                                    </Row>
                                </>
                            ) : (
                                <Divider />
                            )}
                            {!!cities?.length ? (
                                cities.map((c) => (
                                    <Row
                                        data-analytics={DataAnalytics.userPlaceCity}
                                        key={c.id}
                                        onClick={handleFilterRowClick(c)}
                                        hoverable
                                    >
                                        <Row.Body>
                                            <Row.Text>{c.title}</Row.Text>
                                        </Row.Body>
                                    </Row>
                                ))
                            ) : (
                                <Row disabled>
                                    <Row.Body>
                                        <Row.Text>Ничего не найдено</Row.Text>
                                    </Row.Body>
                                </Row>
                            )}
                        </>
                    )}
                </div>
            </Dropdown>
        )
    );
});
