import {BaseApiService} from './base/BaseApiService';

interface IPaymentData {
    method?: string;
    paymentId?: number | string;
    name?: string;
    hash: string;
}

export class PaymentApiService extends BaseApiService {
    constructor() {
        super('');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getCardPay({paymentId, name, hash, method = 'cloudpayments'}: IPaymentData): Promise<any> {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const {data} = await this.post({payment_id: paymentId, name, hash, method}, '/card/pay');
        return data;
    }

    async createApplePaySession(props) {
        const {data} = await this.post(props, '/apple-pay/start-session');
        return data;
    }
}
