import {transports, createLogger, format} from 'winston';
import moment from 'moment';
import {BackendTransport} from './backend-transport.service';

const logTransports = [];

if (process.env.NODE_ENV !== 'production') {
    logTransports.push(new transports.Console({}));
}
if (process.env.BACKEND_LOGGER === 'true') {
    logTransports.push(new BackendTransport({}));
}

export function formatMessage(message, level) {
    return `[${moment().format('DD/MM/YYYY HH:MM:ss:SSS')} ${level}]: ${message}`;
}

const options = {
    exitOnError: false,
    level: process.env.LOG_LEVEL || 'info',
    format: format.printf((info) => formatMessage(info.message, info.level)),
    transports: logTransports,
};

export const logger = createLogger(options);
