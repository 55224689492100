import {BaseApiService} from './base/BaseApiService';
import {ISuccessResponse} from 'interfaces/models/Response';

type ITrackData<T> = {
    [key in keyof T]: T[key]; // TODO: тут как бы пока неизвестно
};

interface IEventHitTrackData {
    from: 'affiche_new';
    event_id: number;
}

interface ITrackRequest<T> {
    tracked_event: string;
    data: ITrackData<T>;
}

export class TrackApiService extends BaseApiService {
    constructor() {
        super('track');
    }

    async sendEventHit(eventId: number): Promise<ISuccessResponse> {
        const payload: ITrackRequest<IEventHitTrackData> = {
            // TODO: не нравятся мне все эти кэмелкейсовые истории
            ['tracked_event']: 'view_event',
            ['data']: {
                ['from']: 'affiche_new',
                ['event_id']: eventId,
            },
        };
        const {data} = await this.post(payload);
        return data as ISuccessResponse;
    }
}
