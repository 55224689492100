import {BaseApiService} from './base/BaseApiService';

export enum ResendTicketsResult {
    queued = 'queued',
    notQueued = 'not_queued',
}

export interface IResendTicketsResponse {
    result: ResendTicketsResult;
}

interface IResendTicketsRequest {
    order_id: number;
}

export class UtilApiService extends BaseApiService {
    constructor() {
        super('');
    }

    async resendTickets(orderId: number): Promise<IResendTicketsResponse> {
        const payload: IResendTicketsRequest = {['order_id']: orderId};
        const {data} = await this.post(payload, '/ntp_resend_tickets');
        return data as IResendTicketsResponse;
    }
}
