import moment, {Moment, Duration} from 'moment';
import {minutePluralize, hourPluralize, pluralizeHelper} from 'services/Pluralizer';

interface IWeekdayGenders {
    neuter: string;
    masculine: string;
    feminine: string;
}

/** возвращает в формате 'D MMMM' */
export const getYearDate = (moment: Moment): string => {
    return moment.format('D MMMM');
};

const getYearIfNotSame = (date: Moment, now = moment()): string => {
    return !moment(now).isSame(date, 'year') ? date.format(', YYYY') : '';
};

export const getDateRangeDescription = (start: Moment, end: Moment, isSession = false): string => {
    const now = moment();
    if (!start.isValid?.()) {
        return 'Дата неизвестна';
    } else if (isSession) {
        if (end.isSame(start, 'second')) {
            if (moment(now).isSame(start, 'year')) {
                return start.format('D MMMM');
            } else {
                return start.format('D MMMM, YYYY');
            }
        } else if (start.isSame(end, 'year')) {
            return start.format('[с] D MMMM ') + end.format('[по] D MMMM');
        } else {
            return start.format('[с] D MMMM, YYYY ') + end.format('[по] D MMMM, YYYY');
        }
    } else if (start.isValid() && !end.isValid()) {
        return start.format('D MMMM HH:mm') + getYearIfNotSame(start);
    } else if (start.isSame(end, 'day')) {
        return start.format('D MMMM HH:mm–') + end.format('HH:mm') + getYearIfNotSame(start);
    } else {
        return (
            start.format('D MMMM HH:mm') +
            getYearIfNotSame(start) +
            ' — ' +
            end.format('D MMMM') +
            getYearIfNotSame(end)
        );
    }
};

export const getVideoDuration = (duration: Duration): string => {
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours < 1) {
        return minutePluralize(minutes, true);
    } else if (hours === 1.5) {
        return hourPluralize(hours, true);
    } else if (hours && minutes) {
        return hourPluralize(hours, true) + ' ' + minutePluralize(minutes, true, false, false);
    } else if (hours) {
        return hourPluralize(hours, true);
    }
};

export const getVideoCardDuration = (duration: Duration): string => {
    function addZeros(time: number) {
        return ('0' + String(time)).substr(-2);
    }

    const hours = duration.hours() ? `${addZeros(duration.hours())}:` : '';
    const minutes = duration.minutes() ? `${addZeros(duration.minutes())}` : '00';
    const seconds = duration.seconds() ? `:${addZeros(duration.seconds())}` : ':00';

    return `${hours}${minutes}${seconds}`;
};

const getDurationHours = (start: Moment, end: Moment): string => {
    const hoursPreciseDiff = end.diff(start, 'hours', true);
    const minutesInHourDiff = end.diff(start, 'minutes') - Math.floor(hoursPreciseDiff) * 60;

    if (hoursPreciseDiff < 1) {
        return minutePluralize(minutesInHourDiff, true);
    } else if (hoursPreciseDiff === 1.5) {
        return hourPluralize(hoursPreciseDiff, true);
    } else if (hoursPreciseDiff && minutesInHourDiff) {
        return hourPluralize(hoursPreciseDiff, true) + ' ' + minutePluralize(minutesInHourDiff, true, false, false);
    } else if (hoursPreciseDiff) {
        return hourPluralize(hoursPreciseDiff, true);
    }
};

/**
 * Возвращает человекочитаемую длительность
 * @param start начало
 * @param end конец
 * @param returnIfNone? возвращаемое значение, если длительности нет
 */
export const getDuration = (start: Moment, end: Moment, returnIfNone?: string) => {
    const duration = moment.duration(end.diff(start));
    if (duration.asHours() < 24) {
        return getDurationHours(start, end);
    } else {
        if (duration.isValid()) {
            // т.к. moment.humanize не умеет в недели
            const weeks = duration.asWeeks() | 0;
            if (weeks >= 1 && duration.asMonths() < 1) {
                return `${weeks} ${pluralizeHelper(weeks, ['неделю', 'недели', 'недель'])}`;
            } else {
                return duration.humanize();
            }
        } else {
            return returnIfNone == null ? 'не указана' : returnIfNone;
        }
    }
};

export const getTimer = (start: Moment, end: Moment): string => {
    if (!start.isValid?.()) {
        return 'Дата неизвестна';
    }

    const now = moment();

    const minutes = start.diff(moment(now).startOf('minute'), 'minutes');
    // const hours = start.diff(now, 'hours', true)
    // use minutes / 60 for hours value, because time diff
    // between f.e. 11:00 and 14:00
    // is 180 minutes, but 2.99 hours
    const hours = minutes / 60;
    const days = start.diff(moment(now).startOf('day'), 'days');
    const weeks = start.diff(moment(now).startOf('day'), 'weeks');
    const months = start.diff(now, 'months');
    const years = start.diff(now, 'years');

    const after = 'Через ';
    if (now.isAfter(end)) {
        return 'Закончилось';
    } else if (now.isSameOrAfter(start, 'minutes')) {
        return 'Идёт сейчас';
    } else if (minutes < 60) {
        return after + pluralizeHelper(minutes, ['минуту', 'минуты', 'минут'], minutes !== 1);
    } else if (minutes === 60) {
        return 'Через час';
    } else if (hours < 24) {
        return (
            after +
            hourPluralize(hours < 1 ? 1 : hours, true) +
            (minutes > 60 && minutes < 120
                ? ` ${pluralizeHelper(minutes - 60, ['минуту', 'минуты', 'минут'], true)}`
                : '')
        );
    } else if (days < 7) {
        return days === 1 ? 'Завтра' : after + days + ' ' + pluralizeHelper(days, ['день', 'дня', 'дней']);
    } else if (weeks <= 4 && months === 0) {
        return after + pluralizeHelper(weeks, ['неделю', 'недели', 'недель'], weeks !== 1);
    } else if (months < 12) {
        return after + pluralizeHelper(months, ['месяц', 'месяца', 'месяцев'], months !== 1);
    } else {
        return after + pluralizeHelper(years, ['год', 'года', 'лет'], years !== 1);
    }
};
