import React, {useEffect, useRef, useState} from 'react';
import {Button, component, Logo} from 'front-components';
import {observer} from 'mobx-react';
import {useContainer} from 'timepad-di';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {SearchStore} from 'stores/Search';
import IconArrowLink from 'assets/svg/32/icon-arrow-link-32.svg';
import {NavHeaderQaAttrs} from 'interfaces/models';
import {TPRoutes} from 'routing/config';
import {SearchBar} from './SearchBar';
import {BurgerMenu} from './BurgerMenu';
import {ProfileButton} from './ProfileButton';
import {externalLinks, headerLinks, internalLinks} from '../const';
import {CityFilter} from '../../Filters';
import {RichLink} from '../../utility/RichLink';

interface IFullHeaderBarProps {
    mediaPoint: number;
    location: string;
    isAuthorized: boolean;
    fullHeaderRef: React.MutableRefObject<HTMLDivElement>;
    onAuthClick: () => void;
    isEventPage: boolean;
}
const topHeaderColor = '#5D5D5D';
const bottomHeaderColor = '#252525';
export const FullHeaderBar: React.FC<IFullHeaderBarProps> = observer(
    ({location, fullHeaderRef, isAuthorized, mediaPoint, onAuthClick, isEventPage}) => {
        const [{city}] = useContainer(SearchStore);

        const logoRef = useRef<HTMLDivElement>(null);

        const [activeLink, setActiveLink] = useState('');

        useEffect(() => {
            const activeLink = internalLinks(city?.alias).find((nav) => nav.route === location)?.id;
            setActiveLink(activeLink);
        }, [city, location]);

        const isDesktop = mediaPoint > 1;

        useEffect(() => {
            if (isDesktop) {
                const logo = logoRef?.current;
                const onScroll = () => {
                    const scrollPosition = window.scrollY;
                    const {top, height} = fullHeaderRef.current.getBoundingClientRect();
                    if (top < scrollPosition) {
                        const opacity = Math.max(0, Math.min(1, ((height - scrollPosition) * 2) / height));
                        return (logo.style.opacity = opacity < 0.3 ? '0.3' : String(opacity));
                    }
                    logo.style.opacity = '1';
                };
                window.addEventListener('scroll', onScroll);
                return () => {
                    window.removeEventListener('scroll', onScroll);
                    logo.style.opacity = '1';
                };
            }
        }, [fullHeaderRef, isDesktop]);

        const filteredLinks = internalLinks(city?.alias).filter(
            (el) => !(el.id === 'subscriptions' && !isAuthorized) && !(el.id === 'collections' && !city),
        );

        const isMobile = mediaPoint === 0;

        const mobileAuthButton = isMobile ? (
            <BurgerMenu onAuthClick={onAuthClick} />
        ) : (
            <Button
                variant={Button.variant.secondary}
                label="Войти"
                onClick={onAuthClick}
                data-qa={NavHeaderQaAttrs.BtnLogin}
            />
        );

        return (
            <>
                <div
                    className={component('top-headerbar')({
                        [`media-point-${mediaPoint}`]: true,
                    })}
                >
                    <div className={component('top-headerbar', 'city-filter')()}>
                        <CityFilter color={topHeaderColor} />
                    </div>
                    {!isMobile && (
                        <div className={component('top-headerbar', 'external-links')()}>
                            {externalLinks.map((el) => (
                                <RichLink
                                    key={el.href}
                                    external={el.external}
                                    target={el.target}
                                    to={el.href}
                                    data-analytics={el?.analytics}
                                    data-qa={el?.dataQaAttr}
                                >
                                    <Button
                                        variant={Button.variant.transparent}
                                        label={el.label}
                                        icon={<IconArrowLink />}
                                        labelColor={topHeaderColor}
                                    />
                                </RichLink>
                            ))}
                        </div>
                    )}
                </div>

                <div
                    className={component('bottom-headerbar')({
                        [`media-point-${mediaPoint}`]: true,
                    })}
                    ref={fullHeaderRef}
                >
                    <div className={component('bottom-headerbar', 'logo')()} ref={logoRef}>
                        <RichLink to={TPRoutes.FEED}>
                            <Logo />
                        </RichLink>
                    </div>

                    <div className={component('bottom-headerbar', 'internal-links')()}>
                        {filteredLinks.map((link) => (
                            <RichLink
                                key={link.id}
                                data-analytics={DataAnalytics.clickThemes}
                                to={link.route}
                                data-qa={link.dataQaAttr}
                            >
                                <Button
                                    variant={Button.variant.transparent}
                                    label={link.label}
                                    labelColor={activeLink !== link.id && bottomHeaderColor}
                                />
                            </RichLink>
                        ))}
                    </div>

                    <SearchBar mediaPoint={mediaPoint} bottomHeaderBarRef={fullHeaderRef} />

                    <div className={component('bottom-headerbar', 'actions')()}>
                        {!isMobile && (
                            <RichLink
                                external
                                data-analytics={DataAnalytics.clickThemes}
                                to={headerLinks.login}
                                data-qa={NavHeaderQaAttrs.BtnCreateEvent}
                            >
                                <Button variant={Button.variant.secondary} label="Создать событие" />
                            </RichLink>
                        )}
                        {isAuthorized ? (
                            <div className={component('bottom-headerbar', 'profile-btn')({visible: !isDesktop})}>
                                <ProfileButton mediaPoint={mediaPoint} isEventPage={isEventPage} />
                            </div>
                        ) : (
                            mobileAuthButton
                        )}
                    </div>
                </div>
            </>
        );
    },
);
