import {IPluralFormConfig, pluralizeHelper, IPluralizerException} from './base';

const exceptions: IPluralizerException[] = [
    {
        amount: 30,
        handler: () => 'полчаса',
    },
];

const config: IPluralFormConfig = ['минута', 'минуты', 'минут'];

export const minutePluralize = (
    amount: number,
    withAmount = false,
    floorAmount = true,
    allowExceptions = true,
): string => {
    if (allowExceptions) {
        const exception = exceptions.find((exception) => exception.amount === amount);
        if (exception) return exception.handler(exception.amount);
    }

    const flooredAmount = floorAmount ? Math.floor(amount) : amount;
    return pluralizeHelper(flooredAmount, config, withAmount);
};
