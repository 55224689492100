import {action, observable} from 'mobx';

export class AnimationHeaderBarStore {
    @observable isMiniHeaderBarHidden = false;

    @action.bound
    setIsMiniHeaderBarHidden(value: boolean): void {
        this.isMiniHeaderBarHidden = value;
    }
}
