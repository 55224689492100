import React, {FC, useEffect, useRef, useState} from 'react';
import {useContainer} from 'timepad-di';
import {component, useMedia} from 'front-components';
import {observer} from 'mobx-react';
import cx from 'classnames';

import {useLocation} from 'react-router';
import {useBanners} from 'services/hooks';
import {BannersStore} from 'stores/Banners';

const enum BannerType {
    TopLine,
    MidLine,
    BannerX1,
    BannerX2,
    CheckOut,
}

interface IBannerProps extends Partial<HTMLDivElement> {
    name: keyof typeof BannerType;
}

export const BannerBase: FC<IBannerProps> = observer(({name, className}) => {
    useBanners();
    const location = useLocation();
    const bannerContainer = useRef(null);
    const [show, setShow] = useState(false);
    const [isExternalClassName, setIsExternalClassName] = useState(false);
    const [bannersStorage] = useContainer(BannersStore);
    const {isMobilePortraitMax} = useMedia();

    const bannerIdPattern = /(?<code>adfox_\d+)/;
    const bannerName = isMobilePortraitMax ? `${name}-mobile` : name;
    const bannerPlaceCode = bannersStorage.bannersList[bannerName]?.placeCode;
    const bannerId = bannerPlaceCode ? bannerPlaceCode.match(bannerIdPattern)[0] : '';

    useEffect(() => {
        if (bannerPlaceCode) {
            bannerContainer.current.innerHTML = ''; // удаляем баннер
            try {
                new Function(bannerPlaceCode)();
            } catch (err) {
                console.error('Ошибочка вышла', err);
            }
            return bannerInsertObserve();
        }
        // зависимость от location.pathname нужна для добавления или удаления баннера из компоненты GlobalHeaderLayout
    }, [bannerPlaceCode, location.pathname]);

    const bannerInsertObserve = () => {
        const bannerNode = bannerContainer.current;
        const checkElementClassName = (element: Element) => {
            element.className.includes('ccustom-banner') ? setIsExternalClassName(true) : setIsExternalClassName(false);
        };
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (mutation.addedNodes.length > 0) {
                        checkElementClassName(mutation.target as Element);
                        setShow(true);
                    } else if (mutation.removedNodes.length > 0) {
                        setShow(false);
                    }
                }
            }
        });
        observer.observe(bannerNode, {childList: true});
        return () => {
            observer.disconnect();
        };
    };

    const internalClassName = cx(component('ad-banner')({[bannerName?.toLowerCase()]: true, hidden: !show}), className);
    const externalClassName = cx(
        component('ad-banner')({hidden: !show}),
        component('custom-banner')({[bannerName?.toLowerCase()]: true}),
        className,
    );
    const classes = isExternalClassName ? externalClassName : internalClassName;
    return bannerPlaceCode && <div id={bannerId} className={classes} ref={bannerContainer}></div>;
});

const TopLine: React.FC<Omit<IBannerProps, 'name'>> = (props) => <BannerBase name="TopLine" {...props} />;
const MidLine: React.FC<Omit<IBannerProps, 'name'>> = (props) => <BannerBase name="MidLine" {...props} />;
const BannerX1: React.FC<Omit<IBannerProps, 'name'>> = (props) => <BannerBase name="BannerX1" {...props} />;
const BannerX2: React.FC<Omit<IBannerProps, 'name'>> = (props) => <BannerBase name="BannerX2" {...props} />;
const Checkout: React.FC<Omit<IBannerProps, 'name'>> = (props) => <BannerBase name="CheckOut" {...props} />;

export const Banner = Object.assign(BannerBase, {TopLine, MidLine, BannerX1, BannerX2, Checkout});
