import {useEffect} from 'react';

export const useTimeout = (callback: (...arg: unknown[]) => unknown, tries = 4, timeout = 1000): void => {
    useEffect(() => {
        let count = 0;
        let timerId = setTimeout(() => {
            if (count > tries) return;
            count++;
            //взависисости от условия внутри колбэка, он либо возвращает что-то и выполнение прерывается, либо выполнение идет дальше и колбэк попадает в setTimeout
            callback();
            timerId = setTimeout(callback, timeout);
        }, timeout);
        return () => {
            clearTimeout(timerId);
        };
    }, [callback, tries, timeout]);
};
