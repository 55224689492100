import React, {FC, lazy} from 'react';
import {Routes as ReactRouterRoutes, Route, Navigate} from 'react-router';
import {BrowserRouter, BrowserRouterProps} from 'react-router-dom';
import Sentry, { isSentrySetup } from 'init-sentry';
import cx from "classnames";
import {component, Modal, SnackbarProvider} from "front-components";

import {CookieAccept} from "components/CookieAccept";
import { ErrorBoundary } from 'components/ErrorBoundary';
import {NotFoundPage} from 'pages/NotFound';
import {AuthLayout, GlobalHeaderLayout} from './layouts';
import {FacebookPixelWatch, VKPixelComponent, ScrollToTop, renderMultiRoutes, EnkodRouterComponent} from './utils';
import {TPRoutes} from './config';

const ModalContainer = lazy(() => import(/* webpackChunkName: "Modals" */ '../components/Modal/ModalContainer'));

const FeedPage = lazy(() => import(/* webpackChunkName: "FeedPage" */ '../pages/Feed/FeedPage'));
const SearchPage = lazy(() => import(/* webpackChunkName: "SearchPage" */ '../pages/Search/SearchPage'));
const CategoriesPage = lazy(()=>import(/* webpackChunkName: "CategoriesPage" */ '../pages/Categories/CategoriesPage'));

// Organization
const OrganizationPageLayout = lazy(() => import(/* webpackChunkName: "OrganizationPageLayout" */ '../pages/Organization/OrganizationPageLayout'));
const OrgEventsPage = lazy(() => import(/* webpackChunkName: "OrgEventsPage" */ '../pages/Organization/OrgEventsPage'));
const OrgContentPage = lazy(() => import(/* webpackChunkName: "OrgContentPage" */ '../pages/Organization/OrgContentPage'));

// Video
const VideoPage = lazy(() => import(/* webpackChunkName: "VideoPage" */ '../pages/Video/VideoPage'));
const VideoAccessDenied = lazy(() => import(/* webpackChunkName: "VideoAccessDenied" */ '../pages/Video/VideoAccessDenied'));
const VideoAccessPage = lazy(() => import(/* webpackChunkName: "VideoAccessPage" */ '../pages/Video/VideoAccessPage'));

const EventPage = lazy(() => import(/* webpackChunkName: "EventPage" */ '../pages/Event/EventPage'));
const CollectionsPage = lazy(() => import(/* webpackChunkName: "CollectionsPage" */ '../pages/Collection/CollectionsPage'));
const CollectionPage = lazy(() => import(/* webpackChunkName: "CollectionPage" */ '../pages/Collection/CollectionPage'));

// Purchases
const MyPurchasesLayout = lazy(() => import(/* webpackChunkName: "MyPurchasesLayout" */ '../pages/MyPurchases/MyPurchasesLayout'));
const MyEventsPage = lazy(() => import(/* webpackChunkName: "MyEventsPage" */ '../pages/MyPurchases/MyEventsPage'));
const MyVideoPage = lazy(() => import(/* webpackChunkName: "MyVideoPage" */ '../pages/MyPurchases/MyVideoPage'));

// Favorites
const MyFavoritesLayout = lazy(() => import(/* webpackChunkName: "MyFavoritesLayout" */ '../pages/MyFavorites/MyFavoritesLayout'));
const MyFavoriteEventsPage = lazy(() => import(/* webpackChunkName: "MyFavoriteEventsPage" */ '../pages/MyFavorites/MyFavoriteEventsPage'));
const MyFavoriteVideosPage = lazy(() => import(/* webpackChunkName: "MyFavoriteVideosPage" */ '../pages/MyFavorites/MyFavoriteVideosPage'));

// Subscribes
const SubscribesLayout = lazy(() => import(/* webpackChunkName: "SubscribesLayout" */ '../pages/Subscribes/SubscribesLayout'));
const SubscriptionsEventsPage = lazy(() => import(/* webpackChunkName: "SubscriptionsEventsPage" */ '../pages/Subscribes/SubscriptionsEventsPage'));
const SubscriptionsVideoPage = lazy(() => import(/* webpackChunkName: "SubscriptionsVideoPage" */ '../pages/Subscribes/SubscriptionsVideoPage'));
const ManageOrgSubscriptionsPage = lazy(() => import(/* webpackChunkName: "ManageOrgSubscriptionsPage" */ '../pages/Subscribes/ManageOrgSubscriptionsPage'));


const Routes = isSentrySetup ? Sentry.withSentryReactRouterV6Routing(ReactRouterRoutes) : ReactRouterRoutes;

export const Routing: FC<BrowserRouterProps> = (props) => {
    return (
        <BrowserRouter {...props}>
            <ErrorBoundary>
            <SnackbarProvider>
            <ModalContainer />
            {/* На каждой странице при переходе на нее необходимо прокручивать вверх */}
            <ScrollToTop />
            <FacebookPixelWatch />
            <EnkodRouterComponent />
            <VKPixelComponent />
            <Modal.ClickOutsideException><CookieAccept /></Modal.ClickOutsideException>
            {/* В этот div вставляются Admin и Edit панели на странице события */}
            <div id="admin-panel"/>
            <div className={cx(component('modal', 'affect')(), component('page-container')())}>
                <Routes>
                    <Route element={<GlobalHeaderLayout />}>
                        {renderMultiRoutes({
                            element: <FeedPage />,
                            paths: [TPRoutes.FEED, TPRoutes.CITY_FEED, TPRoutes.AUTH],
                        })}

                        {renderMultiRoutes({
                            element: <SearchPage />,
                            paths: [
                                TPRoutes.SEARCH,
                                TPRoutes.CITY_SEARCH,
                                TPRoutes.CATEGORY,
                                TPRoutes.CITY_CATEGORY,
                            ],
                        })}

                        <Route path={TPRoutes.ORGANIZATION} element={<OrganizationPageLayout />}>
                            <Route path={TPRoutes.ORGANIZATION_EVENTS} element={<OrgEventsPage />} />
                            <Route path={TPRoutes.ORGANIZATION_VIDEO} element={<OrgContentPage />} />
                        </Route>

                        <Route path={TPRoutes.VIDEO} element={<VideoPage />} />
                        <Route path={TPRoutes.ACCESS_VIDEO} element={<VideoAccessPage />} />
                        <Route path={TPRoutes.ACCESS_DENIED} element={<VideoAccessDenied />} />

                        {renderMultiRoutes({
                            element: <EventPage />,
                            paths: [
                                TPRoutes.EVENT,
                                TPRoutes.EVENT_SESSION,
                                TPRoutes.EVENT_SESSION_PREFILL,
                                TPRoutes.EVENT_PREFILL,
                            ],
                        })}

                        <Route path={TPRoutes.CITY_COLLECTIONS} element={<CollectionsPage />} />
                        <Route path={TPRoutes.CITY_COLLECTION} element={<CollectionPage />} />

                        <Route path={TPRoutes.CATEGORIES} element={<CategoriesPage />} />

                        <Route element={<AuthLayout />}>
                            <Route path={TPRoutes.PURCHASES} element={<MyPurchasesLayout />}>
                                <Route index element={<MyEventsPage />} />
                                <Route path={TPRoutes.PURCHASED_VIDEO} element={<MyVideoPage />} />
                            </Route>

                            <Route path={TPRoutes.FAVORITES_EVENTS} element={<MyFavoritesLayout />}>
                                <Route index element={<MyFavoriteEventsPage />} />
                                <Route path={TPRoutes.FAVORITES_VIDEO} element={<MyFavoriteVideosPage />} />
                            </Route>

                            <Route path={TPRoutes.EVENT_SUBSCRIPTIONS} element={<SubscribesLayout />}>
                                <Route index element={<SubscriptionsEventsPage />} />
                                <Route path={TPRoutes.VIDEO_SUBSCRIPTIONS} element={<SubscriptionsVideoPage />} />
                            </Route>

                            <Route path={TPRoutes.ORGANIZATION_SUBSCRIPTIONS} element={<ManageOrgSubscriptionsPage />} />

                            {/* Здесь мульти используется для того, чтобы при покупке был роут с билетом, но при этом показывался список покупок */}
                            {renderMultiRoutes({
                                element: <MyEventsPage />,
                                paths: [TPRoutes.PURCHASES, TPRoutes.PURCHASED_EVENT],
                            })}

                            <Route path={TPRoutes.EVENT_SUBSCRIPTIONS} element={<SubscribesLayout />} />

                            <Route path={TPRoutes.ORGANIZATION_SUBSCRIPTIONS} element={<ManageOrgSubscriptionsPage />} />
                        </Route>
                    </Route>

                    <Route path={TPRoutes.NOT_FOUND} element={<NotFoundPage />} />
                    <Route path={TPRoutes.CATCH_ALL} element={<Navigate to={TPRoutes.NOT_FOUND} />} />
                </Routes>
            </div>
            </SnackbarProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
};
