import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import {createPortal} from 'react-dom';
import {observer} from 'mobx-react';
import {useContainer} from 'timepad-di';
import {useNavigate} from 'react-router';
// TODO: разобраться с bridge
// import bridge from '@vkontakte/vk-bridge';
import {Button, component, SearchInput} from 'front-components';
import SearchMobileIcon from 'front-components/src/assets/svg/24/icon-search-24.svg';

import {SearchStore} from 'stores/Search';
import {NavHeaderQaAttrs} from 'interfaces/models';
import SearchIcon from 'assets/svg/16/icon-search-16.svg';
import {useFilterParams} from 'services/hooks';

interface ISearchBarProps {
    mediaPoint: number;
    bottomHeaderBarRef: React.MutableRefObject<HTMLDivElement>;
}

const color = '#252525';
export const SearchBar: React.FC<ISearchBarProps> = observer(({mediaPoint, bottomHeaderBarRef, ...rest}) => {
    const navigate = useNavigate();
    const filterNavigate = (params: {city?: string; category?: number; searchParams?: string}) => {
        const {city, category, searchParams} = params;
        const baseUrl = category ? `/category/${category}` : '/search';
        navigate(`/${city}${baseUrl}?${searchParams.toString()}`);
    };
    const {computedCity, matchCategory, queryParams, pushHandle, deleteParam} = useFilterParams(filterNavigate);
    const [{setQuery}] = useContainer(SearchStore);

    const [searchValue, setSearchValue] = useState('');
    const [isInputView, setIsInputView] = useState(false);

    // нужен чтобы отличать ручной сброс и ставить фокус на поле
    const [isManualReset, setIsManualReset] = useState(false);

    const inputRef = useRef<HTMLInputElement>();

    const isMobile = mediaPoint === 0;
    const isDesktop = mediaPoint > 1;

    const searchQueryHandler = (value = '') => {
        setQuery(value);
        pushHandle({
            city: computedCity,
            param: ['query', value],
            category: matchCategory,
        });
    };

    // нужно для анимирования поиска в мобильной версии
    useLayoutEffect(() => {
        if (bottomHeaderBarRef.current && mediaPoint === 0) {
            const className = 'cbottom-headerbar--show-mobile-search';
            if (isInputView) {
                bottomHeaderBarRef.current.classList.add(className);
            } else {
                bottomHeaderBarRef.current.classList.remove(className);
            }
        }
    }, [bottomHeaderBarRef, mediaPoint, isInputView]);

    // Получаем из адреса значение поиска
    useEffect(() => {
        if (!queryParams && !isManualReset) {
            setSearchValue('');
            setQuery('');
            deleteParam('query');
            setIsInputView(false);
        }
        if (queryParams) {
            setQuery(queryParams);
            setSearchValue(queryParams);
            isMobile ? setIsInputView(false) : setIsInputView(true);
        }
    }, [queryParams, setQuery, isMobile, isManualReset]);

    const handleSearchInputBlur = (event: React.FocusEvent) => {
        const clearSearchValue = () => {
            if (!queryParams) setSearchValue('');
        };
        // Останавливаем погружения для события Blur в мобильной версии при повторном нажатии на кнопку поиск
        if (event.relatedTarget?.classList?.contains(searchButtonClassName)) {
            return clearSearchValue();
        }
        // Делаем проверку на нажатие кнопки сброса
        if (event.currentTarget.parentNode.parentNode.contains(event.relatedTarget)) return;
        clearSearchValue();
        setIsInputView(false);
        setIsManualReset(false);
    };

    const handleResetButtonClick = () => {
        if (queryParams) {
            searchQueryHandler();
        }
        setSearchValue('');
        setIsInputView(true);
        setIsManualReset(true);
    };

    const handleEnterPress = () => {
        searchQueryHandler(searchValue);
    };

    const handleEscPress = () => {
        if (!queryParams) {
            setIsInputView(false);
            setSearchValue('');
        }
    };

    const searchButtonClassName = component('bottom-headerbar', 'search-button')();

    return (
        <>
            {(!isInputView || isMobile) && (
                <Button
                    icon={isDesktop ? <SearchIcon /> : <SearchMobileIcon />}
                    label={isDesktop && 'Поиск событий'}
                    variant={Button.variant.transparent}
                    onClick={() => setIsInputView((_isInputView) => !_isInputView)}
                    iconAlignment={Button.alignment.left}
                    labelColor={color}
                    data-qa={NavHeaderQaAttrs.BtnSearch}
                    className={searchButtonClassName}
                    tabIndex={-1}
                />
            )}
            {isInputView &&
                createPortal(
                    <div className={component('bottom-headerbar', `${isMobile ? 'mobile-search' : 'search'}`)()}>
                        <SearchInput
                            value={searchValue}
                            onChange={(event) => setSearchValue(event.currentTarget.value)}
                            onBlur={handleSearchInputBlur}
                            onReset={handleResetButtonClick}
                            onEnterPress={handleEnterPress}
                            onEscPress={handleEscPress}
                            inputRef={inputRef}
                            data-qa={NavHeaderQaAttrs.InputSearch}
                            autoFocus
                            isWide
                            {...rest}
                        />
                    </div>,
                    bottomHeaderBarRef.current,
                )}
        </>
    );
});
