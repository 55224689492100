import {CSSProperties} from 'react';
import {observable, computed} from 'mobx';

import {IOrganization, IOrganizationSocialMedia, IOrganizationAnalyticServices} from 'interfaces/models';
export class OrganizationEntity implements IOrganization {
    @observable id: number;
    @observable name: string;
    @observable description: string;
    @observable poster: string | null = null;
    @observable logo: string | null = null;
    @observable pdAddress: string;
    @observable pdEmail: string;
    @observable inn: string;
    @observable ogrn: string;
    @observable workingTime: string;
    @observable contactPhone: string;
    @observable shareLink: string;
    @observable personalDataPolicyLink: string;
    @observable usesTickets: boolean;
    @observable usesCustoms: boolean;
    @observable afishaForbidden: boolean;
    @observable subscribersCount: number;
    @observable eventsCount: number;
    @observable contentCount: number;
    @observable isFavorite: boolean;
    @observable socials: IOrganizationSocialMedia[];
    @observable analytics: IOrganizationAnalyticServices;

    constructor(organization: IOrganization) {
        Object.assign(this, organization);
    }

    @computed
    get largeLogoStyle(): CSSProperties {
        return this.getLogo('190x190');
    }

    @computed
    get smallLogoStyle(): CSSProperties {
        return this.getLogo('64x64');
    }

    @computed
    get largeLogoURL(): string {
        return this.getLogoURL('190x190');
    }

    @computed
    get smallLogoURL(): string {
        return this.getLogoURL('64x64');
    }

    getLogoURL(dimensions = '64x64'): string {
        const image = this.logo;
        if (!image) {
            return undefined;
        }

        return `${image}-/preview/${dimensions}/`;
    }

    getLogo(dimensions = '64x64'): CSSProperties {
        const url = this.getLogoURL(dimensions);
        if (!url) {
            return {};
        }

        return {
            backgroundImage: `url(${url})`,
        };
    }

    @computed get purifyWorkingTime(): string {
        const [timeStart, timeEnd] = this.workingTime.split('-');

        if (!timeStart || !timeEnd) {
            return this.workingTime;
        }

        return `c ${timeStart} до ${timeEnd}`;
    }
}
