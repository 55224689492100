import {observable, action} from 'mobx';
import moment from 'moment';

export const AccessTokenStorage = observable(
    {
        accessToken: localStorage.getItem('access_token'),
        expiresAt: moment(localStorage.getItem('access_token_exp')),

        setAccessToken(token) {
            this.accessToken = token;
            localStorage.setItem('access_token', token);
        },

        removeAccessToken() {
            this.accessToken = null;
            localStorage.removeItem('access_token');
        },
    },
    {
        setAccessToken: action,
        removeAccessToken: action,
    },
);
