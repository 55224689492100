import React from 'react';
import * as Sentry from '@sentry/react';
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from 'react-router-dom';

export const isSentrySetup = process.env['SENTRY_DSN'] && process.env['SENTRY_DSN'] !== 'null';

export default Sentry;

if (isSentrySetup) {
    const createUrlPattern = (url) => {
        const normalizedUrl = url.replace(/\/+$/, ''); // Remove any trailing slash
        const escapedUrl = normalizedUrl.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special characters
        return new RegExp(`^${escapedUrl}\\/`).toString(); // Create regex pattern
    };

    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT,
        integrations: [
            // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
            // The React Router instrumentation uses the React Router library to create pageload/navigation transactions to ensure you collect meaningful performance data about the health of your page loads and associated requests.
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),

            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/browserapierrors/
            // This integration wraps native time and event APIs (setTimeout, setInterval, requestAnimationFrame, addEventListener/removeEventListener) in try/catch blocks to handle async exceptions.
            Sentry.browserApiErrorsIntegration({
                setTimeout: true,
                setInterval: true,
                requestAnimationFrame: true,
                XMLHttpRequest: true,
                eventTarget: true,
            }),

            // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        // TODO Девопсы указывают через переменную окружения сколько транзакций отслеживаем, если ничего не передано, то отслеживаем 100%
        tracesSampleRate: process.env.SENTRY_RATE || 1.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            /^\//, // Match all relative URLs
            createUrlPattern(process.env.REACT_APP_ROOT_URL),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
