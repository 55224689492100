import {BaseApiService} from './base/BaseApiService';
import {IUser, IExtendedUser} from 'interfaces/models';

export class UserApiService extends BaseApiService {
    constructor() {
        super('user');
    }

    async getUser(params): Promise<IUser> {
        const {data} = await this.get(params);
        return data as IUser;
    }

    async getExtendedUser(params): Promise<IExtendedUser> {
        const {data} = await this.get(params, `/extended-info`);
        return data as IExtendedUser;
    }

    async postUser(payload): Promise<IUser> {
        const {data} = await this.put(payload);
        return data as IUser;
    }
}
