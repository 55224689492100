import {sendGtagEvent} from '../../services/Helpers/AnalyticsHelper/analyticsHelpers';
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-console */
import {action, observable} from 'mobx';
import TagManager from 'react-gtm-module';
import {AuthStore} from '../Auth';

import {IPaymentLink} from 'interfaces/models';
import {FormId, FormName} from './elementIds';
import {diContainer} from 'timepad-di';
import {EventStore} from 'stores/Events';
import {UserStore} from 'stores/User';

export interface IFormAnalytic {
    target: FormName;
    element_name: FormId;
    content?: string;
    organization?: string;
}

export class AnalyticsStore {
    @observable user_current_role: 'user' | 'org' | 'admin' = 'user';

    private readonly authStorage: AuthStore;

    private readonly userStorage: UserStore;

    private readonly eventStorage: EventStore;

    constructor(private readonly debugEnabled: boolean = process.env.ANALYTICS_DEBUG === 'true') {
        this.userStorage = diContainer.get(UserStore);
        this.eventStorage = diContainer.get(EventStore);
        this.authStorage = diContainer.get(AuthStore);
    }

    getCheckoutArgs({step, currencyCode}: {step: number; currencyCode: string}) {
        return {
            dataLayer: {
                ecommerce: {
                    currencyCode,
                    checkout: {
                        actionField: {
                            step,
                        },
                        products: this.eventStorage.ticket.joinedTicketsByTicketId.map((ticket) => {
                            return {
                                name: this.eventStorage.currentEvent?.name,
                                id: this.eventStorage.currentEvent?.id,
                                price: ticket.price,
                                brand: this.eventStorage.currentEvent?.organization?.name,
                                category: this.eventStorage.currentEvent?.categories[0],
                                variant: ticket.ticketId,
                                quantity: ticket.count,
                            };
                        }),
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Ecommerce',
                'gtm-ee-event-action': `Checkout Step ${step}`,
                'gtm-ee-event-non-interaction': 'False',

                // # hit scope user dimensions
                ...this.getUserArg(['user_logIn_status']),
                ...this.getEventArg(),
            },
        };
    }

    getEventArg() {
        if (!this.eventStorage.currentEvent) {
            return {};
        }
        return {
            // # hit scope timepad_event dimensions - only event pages
            timepad_event_id: this.eventStorage.currentEvent.id,
            timepad_event_min_cost: this.eventStorage.currentEvent.computed.minPrice,
            timepad_event_max_cost: this.eventStorage.currentEvent.computed.maxPrice,
            timepad_event_city: this.eventStorage.currentEvent.address?.city,
            timepad_event_name: this.eventStorage.currentEvent.name,
            timepad_event_brand_id: this.eventStorage.currentEvent.organization?.id,
            timepad_event_brand_name: this.eventStorage.currentEvent.organization?.name,
            timepad_event_category: this.eventStorage.currentEvent.categories[0],
            timepad_event_pagetype: 'afisha_new',
        };
    }

    getUserArg(
        filter?: (
            | 'user_id'
            | 'user_reg_date'
            | 'user_activation_date'
            | 'user_first_pay_date'
            | 'user_role'
            | 'user_logIn_status'
        )[],
    ) {
        const tagManagerArgs = {
            // # user scope user dimensions
            user_id: this.userStorage.user?.id,
            user_reg_date: this.userStorage.user?.createdDate,
            user_activation_date: this.userStorage.extendedInfo?.firstRegisterDate,
            user_first_pay_date: this.userStorage.extendedInfo?.firstPaymentDate,
            user_role: this.user_current_role,
            user_logIn_status: this.authStorage.isAuthorized,
        };

        if (!filter) {
            return tagManagerArgs;
        } else {
            const tagManagerOutput = {};
            filter.forEach((name) => (tagManagerOutput[name] = tagManagerArgs[name]));

            return tagManagerOutput;
        }
    }

    getClickArgs(args: {
        clickType: 'Download' | 'Open' | 'Form_init' | 'Send';
        form_id?: number | string;
        element_id: number | string;
        click_text: string;
        click_classes?: string;
        eventPage?: boolean;
    }) {
        const {clickType, form_id, element_id, click_text, click_classes = '', eventPage = false} = args;

        const tagManagerArgs = {
            dataLayer: {
                event: 'Clicks',
                action: clickType,

                // # event dimensions
                form_id,
                element_id,
                click_text,
                click_classes,
                list_name: 'none',

                // # hit scope user dimensions
                ...this.getUserArg(['user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        return tagManagerArgs;
    }

    @action.bound
    pageView({eventPage = false}: {eventPage?: boolean} = {}) {
        const tagManagerArgs = {
            dataLayer: {
                event: 'Pageview',
                action: 'View',
                ...this.getUserArg(['user_id', 'user_role', 'user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {
                ...tagManagerArgs.dataLayer,
                ...this.getEventArg(),
            };
        }

        if (this.debugEnabled) {
            console.log('page view', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    checkoutStep({currencyCode = 'RUB'}: {currencyCode?: string} = {}) {
        const tagManagerArgs = this.getCheckoutArgs({step: 1, currencyCode});

        if (this.debugEnabled) {
            console.log('checkoutStep', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    buyStep({option, currencyCode = 'RUB'}: {option?: IPaymentLink['methodKey']; currencyCode?: string}) {
        const tagManagerArgs = this.getCheckoutArgs({step: 3, currencyCode});

        tagManagerArgs.dataLayer.ecommerce.checkout.actionField['option'] = option;
        tagManagerArgs.dataLayer['payment_method'] = option;

        if (this.debugEnabled) {
            console.log('buyStep', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    popupsOpen({form_id, eventPage = false}: {form_id: number | string; eventPage?: boolean}) {
        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'Popups',
                action: 'Open',

                // # event dimensions
                form_id,

                // # hit scope user dimensions
                ...this.getUserArg(['user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        if (this.debugEnabled) {
            console.log('popupsOpen', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    formView({form_id, eventPage = false, name}: {form_id: number | string; eventPage?: boolean; name?: string}) {
        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'forms',
                target: name,
                element_action: 'form_init',
                element_name: form_id,

                // # hit scope user dimensions
                ...this.getUserArg(['user_id', 'user_role', 'user_reg_date', 'user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        if (this.debugEnabled) {
            console.log('formInit', tagManagerArgs);
        }
        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    formFill(args: {
        form_id: number | string;
        element_id: number | string;
        eventPage?: boolean;
        name?: string;
        price?: number | string;
    }) {
        const {form_id, element_id, eventPage = false, name, price} = args;

        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'forms',
                element_action: 'fill',
                name,
                price,

                // # event dimensions
                form_id,
                element_id,

                // # hit scope user dimensions
                ...this.getUserArg(['user_id', 'user_role', 'user_reg_date', 'user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        if (this.debugEnabled) {
            console.log('formFill', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    formSuccessSend({
        form_id,
        eventPage = false,
        name,
        price,
    }: {
        form_id: number | string;
        eventPage?: boolean;
        name?: string;
        price?: number | string;
    }) {
        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'forms',
                target: name,
                element_action: 'success_send',
                element_name: form_id,
                price,

                // # hit scope user dimensions
                ...this.getUserArg(['user_id', 'user_role', 'user_reg_date', 'user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        if (this.debugEnabled) {
            console.log('formSuccessSend', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    orderSuccessPaid({
        eventPage = false,
        price,
        name,
    }: {
        eventPage?: boolean;
        price?: number | string;
        name?: FormName;
    }) {
        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'purchase',
                target: name,
                element_action: 'success_buy',
                price,

                // # hit scope user dimensions
                ...this.getUserArg(['user_id', 'user_role', 'user_reg_date', 'user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        if (this.debugEnabled) {
            console.log('orderSuccessPaid', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    formError(args: {
        form_id: number | string;
        err_id: number | string;
        eventPage?: boolean;
        name?: string;
        price?: number | string;
    }) {
        const {form_id, err_id, eventPage = false, name, price} = args;
        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'forms',
                target: name,
                element_action: 'error',
                element_name: form_id,
                price,

                // # event dimensions
                err_id,

                // # hit scope user dimensions
                ...this.getUserArg(['user_id', 'user_role', 'user_reg_date', 'user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        if (this.debugEnabled) {
            console.log('formError', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    clickOpen(args: {
        form_id?: number | string;
        element_id: number | string;
        click_text: string;
        click_classes?: string;
        eventPage?: boolean;
    }) {
        const {form_id = undefined, element_id, click_text, click_classes = '', eventPage = false} = args;
        const tagManagerArgs = this.getClickArgs({
            clickType: 'Open',
            form_id,
            element_id,
            click_text,
            click_classes,
            eventPage,
        });

        if (this.debugEnabled) {
            console.log('clickOpen', tagManagerArgs);
        }
        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    selectOptions(args: {
        form_id: number | string;
        element_id: number | string;
        click_text: string;
        option_value: unknown;
        eventPage: boolean;
    }) {
        const {form_id, element_id, click_text, option_value, eventPage} = args;

        //Important: выбрать правильный тип при использовании
        const tagManagerArgs = {
            dataLayer: {
                event: 'Options',
                action: 'Select',

                // # event dimensions
                form_id,
                element_id,
                click_text,
                option_value,

                // # hit scope user dimensions
                ...this.getUserArg(['user_logIn_status']),
            },
        };

        if (eventPage) {
            tagManagerArgs.dataLayer = {...tagManagerArgs.dataLayer, ...this.getEventArg()};
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    eventPageView(args: {list_name?: string; list_position?: number} = {}) {
        const tagManagerArgs = this.getEventPageViewEcommercePayload({action: 'Event page View', ...args});
        if (this.debugEnabled) {
            console.log('eventPageView', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    @action.bound
    eventTicketsPageView(args: {list_name?: string; list_position?: number} = {}) {
        const tagManagerArgs = this.getEventPageViewEcommercePayload({action: 'Event Tickets Page View', ...args});

        if (this.debugEnabled) {
            console.log('eventTicketsPageView', tagManagerArgs);
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    private getEventPageViewEcommercePayload(args: {action: string; list_name?: string; list_position?: number}) {
        const {action, list_name = 'none', list_position = 0} = args;
        return {
            dataLayer: {
                event: 'gtm-ee-event',
                action,

                'gtm-ee-event-category': 'Ecommerce',
                'gtm-ee-event-action': action,
                'gtm-ee-event-non-interaction': 'True',

                // # hit scope user dimensions
                ...this.getUserArg(['user_logIn_status']),

                // # event dimensions
                list_position,
                list_name,

                ecommerce: {
                    currencyCode: 'RUB',
                    detail: {
                        actionField: {
                            list: list_name,
                        },
                        products: this.eventStorage.ticket.tickets.map((ticket) => {
                            return {
                                name: this.eventStorage.currentEvent?.name,
                                id: this.eventStorage.currentEvent?.id,
                                price: ticket.price,
                                brand: this.eventStorage.currentEvent?.organization?.name,
                                category: this.eventStorage.currentEvent?.categories[0],
                                variant: ticket.id,
                            };
                        }),
                    },
                },
                ...this.getEventArg(),
            },
        };
    }

    // gtag.js actions

    @action.bound
    gtagViewItem(targetId: string) {
        const payload = {
            items: [this.gtagEventArg()],
        };

        sendGtagEvent('View_item', targetId, payload);

        if (this.debugEnabled) {
            console.log('gtag::viewItem', targetId, payload);
        }
    }

    @action.bound
    gtagBeginCheckout(targetId: string) {
        const payload = this.gtagCheckoutArgs();

        sendGtagEvent('Begin_checkout', targetId, payload);

        if (this.debugEnabled) {
            console.log('gtag::beginCheckout', targetId, payload);
        }
    }

    @action.bound
    gtagSetCheckoutOption(
        targetId: string,
        {checkoutOption = 'unknown', value = 'unknown'}: {checkoutOption?: string; value?: string} = {},
    ) {
        const payload = {
            ['checkout_step']: 3,
            ['checkout_option']: checkoutOption,
            ['value']: value,
        };
        sendGtagEvent('set_checkout_option', targetId, payload);

        if (this.debugEnabled) {
            console.log('gtag::setCheckoutOption', targetId, payload);
        }
    }

    private gtagEventArg() {
        const {
            timepad_event_id,
            timepad_event_name,
            timepad_event_brand_name,
            timepad_event_category,
        } = this.getEventArg();

        return {
            ['id']: timepad_event_id,
            ['name']: timepad_event_name,
            ['list_name']: 'none',
            ['brand']: timepad_event_brand_name,
            ['category']: timepad_event_category,
            ['list_position']: 0,
        };
    }

    private gtagCheckoutArgs() {
        const eventArgs = this.gtagEventArg();

        return {
            items: this.eventStorage.ticket.joinedTicketsByTicketId.map((ticket) => {
                return {
                    ...eventArgs,
                    price: ticket.price,
                    variant: ticket.ticketId,
                    quantity: ticket.count,
                };
            }),
        };
    }

    @action.bound
    formOpen(args: IFormAnalytic) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'forms',
                element_action: 'form_init',
                ...args,
            },
        });
    }

    @action.bound
    formSend(args: IFormAnalytic) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'forms',
                element_action: 'success_send',
                ...args,
            },
        });
    }

    @action.bound
    formCancel(args: IFormAnalytic) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'forms',
                element_action: 'error',
                ...args,
            },
        });
    }
}
