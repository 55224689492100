import {observable, action, computed} from 'mobx';

import {ITicket, SeatmapSeatTypes} from 'interfaces/models';
import {truncateNegative} from 'services/Helpers/PriceHelper';

export class TicketEntity implements ITicket {
    @observable id: string = null; // id может быть как категории билета, так и id seatmap места/секции
    @observable price: number = null;
    @observable name: string = null;
    @observable paymentPeriod: number = null;
    @observable payLater = false;
    @observable currency: string = null;
    @observable startDate: string = null;
    @observable endDate: string = null;
    @observable minOrder: number = null;
    @observable maxOrder: number = null;
    @observable description: string = null;
    @observable isPromocodeTicket = false;

    @observable eventId?: number = null;
    @observable count?: number = null;

    @observable discountAmount?: number = null;

    @observable policiesPrice?: number = null;

    @observable ticketId?: string = null; // здесь хранится id категории билета

    // seatmap info
    @observable hallScheme: {
        sectionId: number | null;
    } = null;

    @observable seatKey?: string = null;
    @observable seatType?: SeatmapSeatTypes = null;
    @observable seatName?: string = null;
    @observable seatDescription?: string = null;

    constructor(data: ITicket, eventId: number, count = 0) {
        // TODO с бэка приходит activePackage который мы не используем на фронте, надеюсь бэк в будущем это удалит
        if ('activePackage' in data) {
            delete data['activePackage'];
        }
        Object.assign(this, {...data, eventId, count});
        this.setTicketId(data.id);
    }

    @action.bound
    setTicketId(id: string): void {
        this.ticketId = id;
    }

    @action.bound
    setCount(amount: number): void {
        this.count = amount;
    }

    @action.bound
    setPoliciesPrice(amount: number): void {
        this.policiesPrice = amount;
    }

    @action.bound
    setDiscountAmount(amount: number): void {
        this.discountAmount = amount;
    }

    @computed
    get actualPrice(): number {
        const hasPoliciesPrice = this.policiesPrice?.toString(); // значение может быть 0, но это цена
        return truncateNegative(hasPoliciesPrice ? this.policiesPrice : this.price);
    }

    @computed
    get hasDiscount(): boolean {
        return !!this.discountAmount;
    }

    @computed
    get possibleDiscountPrice(): number {
        return truncateNegative(this.hasDiscount ? this.actualPrice - this.discountAmount : this.actualPrice);
    }

    @computed
    get hasMinThershold(): boolean {
        return this.minOrder > 1;
    }

    @computed
    get hasSelected(): boolean {
        return this.count > 0;
    }

    @computed
    get isMinSelected(): boolean {
        return this.count === this.minOrder;
    }

    @computed
    get isMaxSelected(): boolean {
        return this.count === this.maxOrder;
    }

    @computed
    get subtractAllowed(): boolean {
        return !!this.count;
    }

    @computed
    get addAllowed(): boolean {
        return !this.isMaxSelected || this.maxOrder === 0;
    }

    // seatmap methods
    @action.bound
    setSeatKey(key: string): void {
        this.seatKey = key;
    }

    @action.bound
    setSeatType(type: SeatmapSeatTypes): void {
        this.seatType = type;
    }

    @action.bound
    setSeatName(name: string): void {
        this.seatName = name;
    }

    @action.bound
    setSeatDescription(description: string): void {
        this.seatDescription = description;
    }

    @computed
    get isSeatmapTicket(): boolean {
        return !!this.seatKey || !!this.seatType || !!this.hallScheme;
    }
}
