import {IAddress} from './Address';
import {IOrganization} from './Organization';
import {IOrder} from './Order';

export enum EventStatus {
    inactive = 'inactive',
    ok = 'ok',
    noreg = 'noreg',
    deleted = 'deleted',
    canceled = 'canceled',
}

export enum EventRating {
    LIKE = 10,
    DISLIKE = 0,
}

export interface IEventCategory {
    id: number;
    name: string;
}

export interface IEventComputedInfo {
    startDate: string;
    endDate: string;
    minPrice: number;
    maxPrice: number;
    isRegistrationOpened: boolean;
}

export interface IEventSession extends IEventComputedInfo {
    id: number;
    orders: IOrder[];
}

export interface ISeatmapSchemeData {
    publicKey: string;
    eventId: string;
    host: string;
}

export interface IEvent {
    id: number;
    name: string;
    description: string;
    shortDescription: string;
    online: boolean;
    address: IAddress;
    organization: IOrganization;
    categories: IEventCategory[];
    sessions: IEventSession[];
    googleAnalyticsId: string;
    status: EventStatus;
    startDateUnknown: boolean;
    computed: IEventComputedInfo;
    isFavorite: boolean;
    ageLimit: number;
    ticketsNumber: number;
    ordersNumber: number;
    poster: string;
    rating: EventRating | null;
    ofertaLink: string;
    shareLink: string;
    hallScheme: ISeatmapSchemeData | null;
}

export interface IEventCheckout
    extends Pick<IEvent, 'id' | 'ofertaLink' | 'googleAnalyticsId' | 'categories'>,
        Pick<IEventSession, 'id' | 'startDate' | 'endDate'> {
    personalDataPolicyLink: string;
}
