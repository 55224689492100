import {matchPath} from 'react-router';

type IRouteParameters = {
    [key in TPRoutes]: {
        [key: string]: string | number;
    };
};

export enum TPRoutes {
    FEED = '/',
    CITY_FEED = '/:city',
    EVENT = '/event/:id',
    EVENT_SESSION = '/event/:id/sessions/:sessionId',
    EVENT_SESSION_PREFILL = '/event/:id/sessions/:sessionId/prefill',
    EVENT_PREFILL = '/event/:id/prefill',
    ORGANIZATION = '/organizations/:id',
    ORGANIZATION_EVENTS = '/organizations/:id/events',
    ORGANIZATION_VIDEO = '/organizations/:id/video',
    CATEGORY = '/category/:id',
    CITY_CATEGORY = '/:city/category/:id',
    CITY_COLLECTIONS = '/:city/collections',
    CITY_COLLECTION = '/:city/collection/:id',
    SEARCH = '/search',
    CITY_SEARCH = '/:city/search',
    NOT_FOUND = '/404',
    CATCH_ALL = '*',
    CATEGORIES = '/categories',

    VIDEO = '/video/:id',
    ACCESS_VIDEO = '/access/video/:token',
    ACCESS_DENIED = '/access/video/denied',

    AUTH = '/auth',

    // Auth requried routes
    PURCHASES = '/my/purchases',
    PURCHASED_EVENT = '/my/purchases/event/:id',
    PURCHASED_VIDEO = '/my/purchases/video',

    FAVORITES_EVENTS = '/my/favorites',
    FAVORITES_VIDEO = '/my/favorites/video',

    EVENT_SUBSCRIPTIONS = '/my/subscriptions',
    VIDEO_SUBSCRIPTIONS = '/my/subscriptions/video',
    ORGANIZATION_SUBSCRIPTIONS = '/my/subscriptions/settings',
}

export interface IRouteConfigs extends IRouteParameters {
    [TPRoutes.PURCHASED_EVENT]: {
        id: string;
    };
    [TPRoutes.EVENT_PREFILL]: {
        id: string;
    };
    [TPRoutes.EVENT]: {
        id: string;
    };
    [TPRoutes.EVENT_SESSION]: {
        id: string;
        sessionId: string;
    };
    [TPRoutes.EVENT_SESSION_PREFILL]: {
        id: string;
        sessionId: string;
    };
    [TPRoutes.ORGANIZATION]: {
        id: string;
    };
    [TPRoutes.ORGANIZATION_EVENTS]: {
        id: string;
    };
    [TPRoutes.ORGANIZATION_VIDEO]: {
        id: string;
    };
    [TPRoutes.CATEGORY]: {
        id: string;
    };
    [TPRoutes.CITY_COLLECTIONS]: {
        city: string;
    };
    [TPRoutes.CITY_COLLECTION]: {
        city: string;
        id: string;
    };
    [TPRoutes.VIDEO]: {
        id: string;
    };
    [TPRoutes.ACCESS_VIDEO]: {
        token: string;
    };
}

export const getRoute = <T extends TPRoutes>(route: T, params: IRouteConfigs[T]) => {
    let fullfilledPath: string = route;

    /* Не очень нравится использование такого подхода,
       т.к. функция используется только  для путей, у которых есть параметр.
       Все это делается вручную и есть вероятность получить ошибки.
       Нужно либо расширить функцию, либо придумать другой метод, и использовать
       для всех адресов */
    Object.entries(params).forEach(([key, value = '']) => {
        fullfilledPath = fullfilledPath.replace(':' + key, String(value));
    });

    return fullfilledPath;
};

export const isEventPage = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.EVENT, pathname) || !!matchPath(TPRoutes.EVENT_PREFILL, pathname);
};

export const isFeedPage = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.FEED, pathname) || !!matchPath(TPRoutes.CITY_FEED, pathname);
};

//TODO: Нужен рефакторинг для города на всех страницах
export const isCollection = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.CITY_COLLECTION, pathname);
};

export const isCollectionsPage = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.CITY_COLLECTIONS, pathname);
};

export const checkIsVideo = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.VIDEO, pathname);
};

export const isCategoryOrSearchPage = (pathname: string): boolean => {
    const testUrl = new RegExp('(category)|(search)');
    return testUrl.test(pathname);
};

export const isAuthPage = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.AUTH, pathname);
};

export const isMySubscriptionsPage = (pathname: string): boolean => {
    return !!matchPath(TPRoutes.EVENT_SUBSCRIPTIONS, pathname);
};
