import {useParams} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router';
import {getRoute, isFeedPage, TPRoutes} from '../../routing/config';
import {useEffect} from 'react';
import {useContainer} from 'timepad-di';
import {SearchStore} from 'stores/Search';

export const useRedirectToCity = (): void => {
    // TODO Доработать для остальных страниц
    const [{filters, city}] = useContainer(SearchStore);

    const location = useLocation();
    const navigate = useNavigate();
    const isMain = isFeedPage(location.pathname);
    const params = useParams<{city?: string; id?: string}>();

    useEffect(() => {
        if (filters && city && isMain && !params?.city) {
            navigate(getRoute(TPRoutes.CITY_FEED, {city: city.alias}), {replace: true});
        }
    }, [filters, city, isMain, params]);
};
