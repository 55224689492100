export enum DataAnalytics {
    enter = 'afisha_enter_001',
    goAfisha = 'afisha_go_afisha_003',
    getPasscode = 'afisha_get_passcode_004',
    search = 'afisha_search_005',
    clickThemes = 'afisha_click_themes_006',
    clickSearchThemes = 'afisha_search_click_themes_007',
    clickDropdownThemes = 'afisha_user_place_eventype_101',
    backInSearch = 'afisha_back_in_search_007',
    userEnterAccount = 'afisha_user_enter_account_105',
    userProfile = 'afisha_user_profile_008',
    userEdit = 'afisha_user_edit_009',
    userPurchases = 'afisha_user_purchases_010',
    userSubscribes = 'afisha_user_subscribes_011',
    userFavorites = 'afisha_user_favorites_012',
    userExit = 'afisha_user_exit_013',
    userBecomeOrg = 'afisha_user_become_org_014',
    userFeedGoEvent = 'afisha_user_feed_go_event_015',
    userEventOrginfo = 'afisha_user_event_orginfo_018',
    userAddFav = 'afisha_user_add_fav_019',
    userFeedDate = 'afisha_user_feed_date_020',
    userDateToday = 'afisha_user_date_today_021',
    userDateTomorrow = 'afisha_user_date_tomorrow_022',
    userDateDayoff = 'afisha_user_date_dayoff_023',
    userPlace = 'afisha_user_place_024',
    userPlaceCity = 'afisha_user_place_city_025',
    userPrice = 'afisha_user_price_026',
    userPriceChoose = 'afisha_user_price_choose_027',
    userFavGoEvent = 'afisha_user_fav_go_event_028',
    userFavMore = 'afisha_user_fav_more_029',
    userCompilation = 'afisha_user_compilation_030',
    userCompilationGoEvent = 'afisha_user_compilation_go_event_031',
    userCompilationLeft = 'afisha_user_compilation_left_032',
    userCompilationRight = 'afisha_user_compilation_right_033',
    userChoseTopic = 'afisha_user_chose_topic_034',
    userMySubscribes = 'afisha_user_subscribes_035',
    userSubscribesVideos = 'afisha_user_subscribes_videos_036',
    userSubscribesGoEvent = 'afisha_user_subscribes_go_event_037',
    userSubscribesRecSub = 'afisha_user_subscribes_rec_sub_038',
    userSubscribesRecUnsub = 'afisha_user_subscribes_rec_unsub_039',
    userSubscribesRecGoEvent = 'afisha_user_subscribes_rec_go_event_039',
    userSubscribesRecLeft = 'afisha_user_subscribes_rec_left_040',
    userSubscribesRecRight = 'afisha_user_subscribes_rec_right_041',
    userSubscribesIntSub = 'afisha_user_subscribes_int_sub_042',
    userSubscribesIntUnsub = 'afisha_user_subscribes_int_unsub_043',
    userSubscribesMyGoOrg = 'afisha_user_subscribes_my_go_org_044',
    userSubscribesMyUnsub = 'afisha_user_subscribes_my_unsub_045',
    userEventsMyShowTickets = 'afisha_user_events_my_show_tickets_047',
    userEventsMyPastLike = 'afisha_user_events_my_past_like_050',
    userEventsMyPastDislike = 'afisha_user_events_my_past_dislike_051',
    userEventsFavGoEvent = 'afisha_user_events_fav_go_event_053',
    userUnfav = 'afisha_user_events_fav_unfav_054',
    userOrgsUnsub = 'afisha_user_orgs_unsub_055',
    userOrgsSub = 'afisha_user_orgs_sub_056',
    userOrgsShare = 'afisha_user_orgs_share_057',
    userOrgsSocial = 'afisha_user_orgs_social_058',
    userOrgsEvents = 'afisha_user_orgs_events_059',
    userOrgsPrice = 'afisha_user_orgs_price_061',
    userOrgsPacks = 'afisha_user_orgs_packs_063',
    userOrgsVideo = 'afisha_user_orgs_video_064',
    userEventDatatime = 'afisha_user_event_datatime_065',
    userEventPrice = 'afisha_user_event_price_066',
    userEventChoose = 'afisha_user_event_choose_067',
    userEventMore = 'afisha_user_event_more_068',
    userEventAbout = 'afisha_user_event_about_069',
    userEventShare = 'afisha_user_event_share_070',
    userEventAddfav = 'afisha_user_event_addfav_071',
    userEventTicketPlus = 'afisha_user_event_ticket_plus_072',
    userEventTicketMinus = 'afisha_user_event_ticket_minus_073',
    userEventTicketBuy = 'afisha_user_event_ticket_buy_074',
    userEventTicketPayment = 'afisha_user_event_ticket_payment_075',
    userEventTicketPay = 'afisha_user_event_ticket_pay_076',
    userEventTicketFreeReg = 'afisha_user_event_ticket_free_reg_077',
    userEventTicketFreeMinus = 'afisha_user_event_ticket_free_minus_078',
    userEventTicketFreePlus = 'afisha_user_event_ticket_free_plus_079',
    userEventTicketReturn = 'afisha_user_event_ticket_return_080',
    userFavourLeft = 'afisha_user_favour_left_081',
    userFavourRight = 'afisha_user_favour_right_082',
    userFeedTabRecom = 'afisha_user_feed_tab_recom_085',
    userFeedTabSub = 'afisha_user_feed_tab_sub_086',
    userWaitpayDel = 'afisha_user_waitpay_del_087',
    menuForOrgs = 'afisha_menu_for_orgs_088',
    menuForOrgsBecomeorg = 'afisha_menu_for_orgs_becomeorg_089',
    menuForOrgsLogin = 'afisha_menu_for_orgs_login_090',
    userSoonEventsRight = 'afisha_user_soon_events_right_091',
    userSoonEventsLeft = 'afisha_user_soon_events_left_092',
    userSubscribesNewGoEvent = 'afisha_user_subscribes_new_go_event_095',
    userSubscribesNewMore = 'afisha_user_subscribes_new_more_096',
    videoUserPlay = 'video_user_play_b016',
    brandCollectionOpen = 'afisha_user_brand_collection_open_099',
    brandCollectionLogoBig = 'afisha_user_brand_collection_logo_100',
    brandCollectionGoEvent = 'afisha_user_brand_collection_go_event_101',
    brandCollectionBuy = 'afisha_user_brand_collection_buy_102',
    brandCollectionAll = 'afisha_user_brand_collection_all_103',
    brandCollectionLogoSmall = 'afisha_user_brand_collection_logo2_104',
    brandCollectionGoEventInside = 'afisha_user_brand_collection_go_event_inside_105',
    blockRecommendedGoEvent = 'afisha_user_recommended_go_event_106',
    blockSeeAlsoGoEvent = 'afisha_user_see_also_go_event_107',
    blockWaitpayGoEvent = 'afisha_user_waitpay_go_event_108',
    blockMyGoEvent = 'afisha_user_my_go_event_109',
    blockOrgGoEvent = 'afisha_user_org_go_event_110',
    blockSearchGoEvent = 'afisha_user_search_go_event_111',
    blockCategoryGoEvent = 'afisha_user_category_go_event_112',
    goCategory = 'afisha_user_go_category_113',
    selectEventSession = 'afisha_user_select_event_session_114',
    userWaitpayReg = 'afisha_user_waitpay_reg_115',
    userWaitpayBuy = 'afisha_user_waitpay_buy_116',
    goOrg = 'afisha_user_event_go_org_117',
    sendEventFeedback = 'afisha_user_send_event_feedback_118',
    userSubscribesEvents = 'afisha_user_subscribes_events_119',
    userMyEvents = 'afisha_user_my_events_120',
    userMyVideos = 'afisha_user_my_videos_121',
}
