import {useLocation} from 'react-router';
import {useLayoutEffect, useEffect} from 'react';
import {useEnkod} from './useEnkod';
import {useContainer} from 'timepad-di';
import {AuthStore} from 'stores/Auth';
import {UserStore} from 'stores/User';
import {SearchStore} from 'stores/Search';
import {observer} from 'mobx-react';

export const EnkodRouterComponent = observer((): null => {
    const location = useLocation();
    const enkod = useEnkod();
    const [authStorage] = useContainer(AuthStore);
    const [userStorage] = useContainer(UserStore);
    const [searchStorage] = useContainer(SearchStore);

    useLayoutEffect(() => {
        enkod.pageOpen();
        enkod.unauthAddExtraFields({
            city: searchStorage?.city?.title,
        });
    }, [location]);

    useEffect(() => {
        if (authStorage?.isAuthorized && userStorage?.user?.email) {
            const {email, name: firstName, surname: lastName, phone} = userStorage.user;
            enkod.subscribe({
                fields: {
                    email,
                    firstName,
                    lastName,
                    phone,
                },
                extraFields: {
                    city: searchStorage?.city?.title,
                },
            });
        }
    }, [location, authStorage, userStorage?.user]);

    return null;
});
