import {useCallback, useEffect, useState} from 'react';

export const useMatchMedia = (mediaPoints: string[]): number => {
    const getMatchMedia = useCallback(() => {
        const mediaIndex = mediaPoints.findIndex((resolution) => window.matchMedia(resolution).matches);
        setMedia(mediaIndex);
    }, [mediaPoints]);

    const [media, setMedia] = useState<number>(0);

    useEffect(() => {
        getMatchMedia();
        window.addEventListener('resize', getMatchMedia);
        return () => window.removeEventListener('resize', getMatchMedia);
    }, [getMatchMedia]);

    return media;
};
