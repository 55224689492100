import {BaseApiService} from './base/BaseApiService';

export interface IGetOtpUserResponse {
    editLinks: {
        description: string;
        form: string;
        ticketTypes: string;
        settingsPublic: string;
        sales: string;
        orders: string;
        promotions: string;
        checkin: string;
    } | null;
    adminLinks: {
        admin: string;
        orgAdmin: string;
        eventAdmin: string;
    } | null;
}

export class AdminApiService extends BaseApiService {
    constructor() {
        super('otp-user');
    }

    async getOtpUser(eventId: number): Promise<IGetOtpUserResponse> {
        const {data} = await this.get({}, `/${eventId}`, null, {Authorization: ''}, true);

        const result: IGetOtpUserResponse = {
            editLinks: null,
            adminLinks: null,
        };

        if (!Array.isArray(data.editLinks)) result.editLinks = data.editLinks;
        if (!Array.isArray(data.adminLinks)) result.adminLinks = data.adminLinks;

        return result;
    }
}
