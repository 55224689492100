import {BaseApiService, IResponseBase, IPagingRequest} from './base/BaseApiService';
import {ICollection} from 'interfaces/models';
import {getLocation} from './DictionariesApiService';
import {IEventQueryParams, IEventsResponse} from './EventsApiService';

export interface ICollectionListParams extends Pick<IEventQueryParams, 'city' | 'offset' | 'limit'> {
    carousel?: number;
}

export interface ICollectionEventsQueryParams extends IPagingRequest {
    id: number;
}

export interface ICollectionsResponse {
    eventCollections: ICollection[];
    total: number;
    offset: IPagingRequest['offset'];
}

export interface ICollectionEventsResponse extends IEventsResponse {}

export class CollectionApiService extends BaseApiService {
    constructor() {
        super('collections');
    }

    async getCollectionsList(params: ICollectionListParams): Promise<ICollectionsResponse> {
        const {city, ...restParams} = params;
        if (!city) return; // true когда нет ни одного параметра

        const collectionPostfix = '';
        const collectionParams = {...getLocation(city), ...restParams};

        const {data}: {data: IResponseBase<ICollectionsResponse>} = await this.get(
            {...collectionParams},
            collectionPostfix,
        );

        return data.data;
    }

    async getCollection(id: number): Promise<ICollection> {
        const collectionPostfix = `/${id}`;
        const collectionParams = {};

        const {data}: {data: IResponseBase<ICollection>} = await this.get({...collectionParams}, collectionPostfix);

        return data.data;
    }

    async getCollectionEventsList(params: ICollectionEventsQueryParams): Promise<ICollectionEventsResponse> {
        const {id, limit, offset} = params;
        const collectionPostfix = `/${id}/events`;
        const collectionParams = {limit, offset};

        const {data}: {data: IResponseBase<ICollectionEventsResponse>} = await this.get(
            {...collectionParams},
            collectionPostfix,
        );

        return data.data;
    }
}
