import {TicketEntity} from 'stores/Ticket';

export interface IOrderInfo {
    totalNum: number;
    totalSum: number;
    discountedSum: number;
    numOfSelectedTypes: number;
    numOfTypes: number;
}

const totalSum = (tickets: TicketEntity[]) => {
    return tickets.reduce((acc: number, curr: TicketEntity) => acc + curr.count * curr.actualPrice, 0);
};

const possibleDiscountedSum = (tickets: TicketEntity[]) => {
    return tickets.reduce((acc: number, curr: TicketEntity) => acc + curr.count * curr.possibleDiscountPrice, 0);
};

const totalNum = (tickets: TicketEntity[]) => {
    return tickets.reduce((acc: number, curr: TicketEntity) => acc + curr.count, 0);
};

const totalSelectedTypesNum = (tickets: TicketEntity[]) => {
    return tickets.reduce((selectedTypes, ticket) => (ticket.count ? selectedTypes + 1 : selectedTypes), 0);
};

// TODO: иногда процесс подсчета может занимать до нескольких секунд и пользователь видит некорретную информацию все это время.
//  Нужно обернуть это все в промисы, что бы блокировать кнопку/скрывать инфу пока идет посдчет.
export const getOrderInfo = (tickets: TicketEntity[]): IOrderInfo => {
    return {
        totalNum: totalNum(tickets),
        totalSum: totalSum(tickets),
        discountedSum: possibleDiscountedSum(tickets),
        numOfSelectedTypes: totalSelectedTypesNum(tickets),
        numOfTypes: tickets.length,
    };
};
