import {CSSProperties} from 'react';
import {action, computed, observable} from 'mobx';
import moment, {Moment} from 'moment';

import {
    IAddress,
    IEvent,
    EventStatus,
    IEventCategory,
    IEventComputedInfo,
    IOrder,
    ISeatmapSchemeData,
    EventRating,
} from 'interfaces/models';
import {getDuration, getDateRangeDescription, getTimer} from 'services/Helpers/DateHelper';
import {IOrderInfo, getOrderInfo} from 'services/Helpers/OrderHelper';
import {OrganizationEntity} from 'stores/Organization';
import {TicketEntity} from 'stores/Ticket';
import {SessionEntity} from './SessionEntity';

export class EventEntity implements IEvent {
    // Данные приходящие с backend
    @observable id: number;
    @observable name: string;
    @observable description: string;
    @observable shortDescription: string;
    @observable online: boolean;
    @observable address: IAddress;
    @observable organization: OrganizationEntity;
    @observable categories: IEventCategory[];
    @observable sessions: SessionEntity[];
    @observable googleAnalyticsId: string;
    @observable status: EventStatus;
    @observable startDateUnknown: boolean;
    @observable computed: IEventComputedInfo;
    @observable isFavorite: boolean;
    @observable ageLimit: number;
    @observable ticketsNumber: number;
    @observable ordersNumber: number;
    @observable poster: string;
    @observable rating: EventRating | null;
    @observable ofertaLink: string;
    @observable shareLink: string;
    @observable hallScheme: ISeatmapSchemeData = null;

    // Доп поля
    @observable tickets: TicketEntity[] = [];

    constructor({sessions, ...event}: IEvent) {
        Object.assign(this, event);
        this.sessions = sessions.map((session) => new SessionEntity(session));
    }

    @action.bound
    setTickets(tickets: TicketEntity[]): void {
        this.tickets = tickets;
    }

    @action.bound
    setIsFavorite(isFavorite: boolean): void {
        this.isFavorite = isFavorite;
    }

    @action.bound
    setRating(rating: EventRating): void {
        this.rating = rating;
    }

    @computed
    get orderInfo(): IOrderInfo {
        return getOrderInfo(this.tickets);
    }

    get photo(): string {
        return this.poster || this.organization?.poster || null;
    }

    // инлайн-стиль для постера события в карточке, вешается на div.сeventcard__image,
    // см. src/components/common/EventCard/EventCard.tsx внутри ceventcard__image
    @computed
    get thumbnailStyle(): CSSProperties {
        if (!this.photo) {
            return {};
        }

        return {
            backgroundImage: this.isDirectPhotoUrl
                ? `url(${this.photo})`
                : `url(${this.photo}-/format/jpeg/-/quality/smart/-/preview/640x360/)`,
        };
    }

    @computed
    get posterStyle(): CSSProperties {
        if (!this.photo) {
            return {};
        }

        return {
            backgroundImage: this.isDirectPhotoUrl
                ? `url(${this.photo})`
                : `url(${this.photo}-/format/jpeg/-/quality/smart/-/preview/1360x765/`,
        };
    }

    @computed
    get minimapStyle(): CSSProperties {
        if ((!this.address?.lat && !this.address?.lon) || !this.address?.street) {
            return {};
        }
        return {
            backgroundImage: `url(https://static-maps.yandex.ru/1.x/?ll=${this.address.lon},${this.address.lat}&size=120,120&z=15&l=map)`,
        };
    }
    @computed
    get start(): Moment {
        return moment(this.computed.startDate);
    }

    @computed
    get end(): Moment {
        return moment(this.computed.endDate);
    }

    @computed
    get readableDateRange(): string {
        return getDateRangeDescription(this.start, this.end, this.isRepeated);
    }

    @computed
    get duration(): string {
        return getDuration(this.start, this.end, '');
    }

    @computed
    get isRepeated(): boolean {
        return this.sessions.length > 1;
    }

    @computed
    get dateHint(): string {
        return this.isRepeated ? 'Повторяется' : getTimer(this.start, this.end);
    }

    @computed
    get isCanceled(): boolean {
        return this.status === EventStatus.canceled;
    }

    @computed
    private get isDirectPhotoUrl(): boolean {
        return this.photo.match(/\.([a-z]{1,4})$/) !== null;
    }

    @computed
    get orders(): IOrder[] {
        return this.sessions.flatMap((session) => session.orders);
    }
}
