import React from 'react';
import {component, Row} from 'front-components';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {RecommendedBlockQaAttrs} from 'interfaces/models';
import {IEventPriceFilter} from 'services/Api/DictionariesApiService';
import {FilterInput} from '../../common/FilterInput';
import cx from 'classnames';

interface IPriceFilterProps {
    onPriceClickHandler: (price: IEventPriceFilter) => void;
    onResetPriceClickHandler: () => void;
    price: IEventPriceFilter;
    priceFilters: IEventPriceFilter[];
}
export const PriceFilter: React.FC<IPriceFilterProps> = ({
    onPriceClickHandler,
    onResetPriceClickHandler,
    price,
    priceFilters,
}) => {
    return (
        <FilterInput
            label="Цена"
            value={price?.title}
            onReset={onResetPriceClickHandler}
            data-analytics={DataAnalytics.userPrice}
            data-qa={RecommendedBlockQaAttrs.FilterPrice}
        >
            {(close) => (
                <div className={cx(component('theme--darkpic-bg')(), component('theme--darkpic')())}>
                    {priceFilters?.map((price: IEventPriceFilter) => (
                        <Row
                            data-analytics={DataAnalytics.userPriceChoose}
                            key={price.id}
                            onClick={() => {
                                close();
                                onPriceClickHandler(price);
                            }}
                            hoverable
                        >
                            <Row.Body>
                                <Row.Text>{price.title}</Row.Text>
                            </Row.Body>
                        </Row>
                    ))}
                </div>
            )}
        </FilterInput>
    );
};
