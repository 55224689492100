import {observable, flow, action, computed} from 'mobx';
import {diContainer} from 'timepad-di';

import {IEvent, IFavoriteOrganization, IRecommendedOrganization} from 'interfaces/models';
import {FavoritesApiService, OrganizationsApiService} from 'services/Api';
import {EventEntity} from 'stores/Events';

interface IOrganizationWithEvents extends IFavoriteOrganization {
    events?: EventEntity[];
}

interface IMemorizedFavoriteOrgs extends IFavoriteOrganization {
    isFavorite: boolean;
}

export class OrganizationsListStore {
    @observable recommendedOrgsList: IOrganizationWithEvents[] = [];
    @observable isRecommendedOrgsLoading = false;

    @observable favoriteOrgsList: IFavoriteOrganization[] = [];
    //memorizedFavoriteOrgs нужен для страницы мои подписки
    @observable memorizedFavoriteOrgsList: IMemorizedFavoriteOrgs[] = [];
    @observable isFavoriteOrgsLoading = false;

    @observable error: Error = null;

    private readonly organizationsApiService: OrganizationsApiService;

    private readonly favoritesApiService: FavoritesApiService;

    constructor() {
        this.organizationsApiService = diContainer.get(OrganizationsApiService);
        this.favoritesApiService = diContainer.get(FavoritesApiService);
    }

    createEventModels(data: IEvent[]): EventEntity[] {
        return data.map((v) => new EventEntity(v));
    }

    @computed
    get recommendedOrgsListWithEvents(): IOrganizationWithEvents[] {
        return this.recommendedOrgsList.length && this.recommendedOrgsList.slice(0, 3);
    }

    @computed
    get recommendedOrgsListWithoutEvents(): IFavoriteOrganization[] {
        return this.recommendedOrgsList.length && this.recommendedOrgsList.slice(3, 8);
    }

    @computed
    get isFavoriteOrgsListEmpty(): boolean {
        return !this.isFavoriteOrgsLoading && !this.favoriteOrgsList.length;
    }

    @action.bound
    isFavoriteOrg(id: number): boolean {
        return !!this.favoriteOrgsList.find((item) => item.id === id);
    }

    @action.bound
    getRecommendedOrgsList = flow(function*(this: OrganizationsListStore) {
        this.isRecommendedOrgsLoading = true;
        this.error = null;

        try {
            const recommendedOrgs: IRecommendedOrganization[] = yield this.organizationsApiService.getRecommendedOrganizations();
            if (recommendedOrgs) {
                this.recommendedOrgsList = recommendedOrgs.map(({events, ...org}) => ({
                    ...org,
                    events: this.createEventModels(events),
                }));
            }
        } catch (err) {
            this.error = err;
        } finally {
            this.isRecommendedOrgsLoading = false;
        }
    });

    @action.bound
    getFavoriteOrgsList = flow(function*(this: OrganizationsListStore, withMemoized = false) {
        this.isFavoriteOrgsLoading = true;
        this.error = null;
        this.favoriteOrgsList = [];
        this.memorizedFavoriteOrgsList = [];

        try {
            const favoriteOrgs: IFavoriteOrganization[] = yield this.favoritesApiService.getFavoriteOrganizations();
            if (favoriteOrgs) {
                this.favoriteOrgsList = favoriteOrgs;
                if (withMemoized) {
                    this.memorizedFavoriteOrgsList = favoriteOrgs.map((favoriteOrg) => ({
                        isFavorite: true,
                        ...favoriteOrg,
                    }));
                }
            }
        } catch (err) {
            this.error = err;
        } finally {
            this.isFavoriteOrgsLoading = false;
        }
    });

    @action.bound
    updateMemorizedFavoriteOrgs(orgId: number): void {
        if (this.memorizedFavoriteOrgsList.length) {
            this.memorizedFavoriteOrgsList = this.memorizedFavoriteOrgsList.map((org) =>
                org.id === orgId ? {...org, isFavorite: !org.isFavorite} : org,
            );
        }
    }
}
