import React, {PropsWithChildren} from 'react';
import {Button, component} from 'front-components';

interface IControllerProps {
    buttonRef?: React.MutableRefObject<HTMLButtonElement>;
    active?: boolean;
    filled?: boolean;
    black?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}

export function Controller({
    buttonRef,
    active,
    filled,
    black,
    children,
    onClick,
    ...props
}: PropsWithChildren<IControllerProps>) {
    const buttonClasses = component('btn-controller')({
        init: !active && !filled,
        active: active,
        black: black && !filled,
        filled: filled,
        narrow: true,
    });

    return (
        <Button
            className={buttonClasses}
            buttonRef={buttonRef}
            variant={Button.variant.controller}
            onClick={onClick}
            {...props}
        >
            {children}
        </Button>
    );
}
