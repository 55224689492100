import {BaseApiService} from './base/BaseApiService';
import {ISuccessResponse} from 'interfaces/models/Response';

export class RefundApiService extends BaseApiService {
    constructor() {
        super('event');
    }

    async unregister(registrationId: number): Promise<ISuccessResponse> {
        const {data} = await this.post({}, `/unregister/${registrationId}`);
        return data as ISuccessResponse;
    }

    async refund(registrationId: number): Promise<ISuccessResponse> {
        const {data} = await this.post({}, `/return_ticket/${registrationId}`);
        return data as ISuccessResponse;
    }
}

export const refundApi = new RefundApiService();
