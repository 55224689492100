import {Outlet, useLocation, useNavigate} from 'react-router';
import React, {FC, Suspense, useEffect} from 'react';
import {useContainer} from 'timepad-di';
import {checkIsVideo, isCollection, isEventPage, TPRoutes, isAuthPage} from '../config';
import {Fallback} from '../utils';
import {Footer} from '../../components/Footer';
import {Banner} from '../../components/Banners';
import {HeaderBar} from '../../components/HeaderBar';
import {AuthStore} from 'stores/Auth';
import {useStores} from 'services/hooks';

export const GlobalHeaderLayout: FC = () => {
    const location = useLocation();
    const isContentPage = isEventPage(location.pathname) || checkIsVideo(location.pathname);
    const excludedPages = isContentPage || isCollection(location.pathname);

    const [authStorage] = useContainer(AuthStore);
    const {modalStorage} = useStores();
    const navigate = useNavigate();
    const authPage = isAuthPage(location.pathname);

    useEffect(() => {
        if (authPage) {
            if (authStorage.isAuthorized) {
                return navigate(TPRoutes.PURCHASES);
            }
            modalStorage.modals.AUTH_MODAL.open({}).then(() => {
                modalStorage.modals.AUTH_MODAL.close();
            });
        }
    }, [authPage, modalStorage.modals.AUTH_MODAL, authStorage.isAuthorized]);

    return (
        <>
            <HeaderBar isContentPage={isContentPage} />
            {!excludedPages && <Banner.TopLine />}
            <Suspense fallback={<Fallback />}>
                <Outlet />
            </Suspense>
            <Footer />
        </>
    );
};
