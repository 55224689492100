import {OrganizationEntity} from 'stores/Organization';
import {IOldOrganization, IOrganization, IVideo, IVideoResponse} from '../../../interfaces/models';

const frameParams = 'width="100%" height="340" frameborder="0" allowfullscreen';

export const replaceYoutubeTextLinks = (content: string) => {
    return content.replace(
        /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?/gm,
        '<iframe src="https://www.youtube.com/embed/$5?rel=0" ' + frameParams + '></iframe>',
    );
};

export const replaceYoutubeLinks = (content: string) => {
    return content.replace(
        /(<a[^>]+href=\")((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)?(\"[^>]*>.*?<\/a>)/gm,
        '<iframe src="https://www.youtube.com/embed/$6?rel=0" ' + frameParams + '></iframe>',
    );
};

export const replaceYoutubeParagraph = (content: string) => {
    return content.replace(
        /(<p[^>]*?>)((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(<\/p>)?/gm,
        '<iframe src="https://www.youtube.com/embed/$6?rel=0" ' + frameParams + '></iframe>',
    );
};

export const replaceVimeoTextLinks = (content: string) => {
    return content.replace(
        /(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)(\S+)?/gm,
        '<iframe src="https://player.vimeo.com/video/$2" ' + frameParams + '></iframe>',
    );
};

export const replaceVimeoLinks = (content: string) => {
    return content.replace(
        /(<a[^>]+href=\")(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)(\"[^>]*>.*?<\/a>)/gm,
        '<iframe src="https://www.youtube.com/embed/$3?rel=0" ' + frameParams + '></iframe>',
    );
};

export const replaceVimeoParagraph = (content: string) => {
    return content.replace(
        /(<p[^>]*?>)(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)(<\/p>)?/gm,
        '<iframe src="https://www.youtube.com/embed/$6?rel=0" ' + frameParams + '></iframe>',
    );
};

export const replaceVideoLinks = (content: string) => {
    // TODO: Из-за последовательного вызова контент со ссылками ютуба превнащается в кашу.
    // Нужно предусматривать, что если ссылка уже поменялась, не применять к ней последующую логику
    let out = replaceYoutubeTextLinks(content);
    out = replaceYoutubeParagraph(out);
    out = replaceYoutubeLinks(out);

    out = replaceVimeoTextLinks(out);
    out = replaceVimeoLinks(out);
    out = replaceVimeoParagraph(out);
    return out;
};

export const helperInitVarRelationType = (variable, type: string, defaultVal) => {
    return typeof variable === type ? variable : defaultVal;
};

export const sanitizeVideoData = (data: IVideoResponse): IVideo => {
    return {
        id: data.id,
        title: data.title,
        description: helperInitVarRelationType(data.description, 'string', ''),
        type: data.type,
        minPrice: helperInitVarRelationType(data.min_price, 'number', 0),
        ageLimit: helperInitVarRelationType(data.age_limit, 'string', ''),
        languageName: helperInitVarRelationType(data.language_name, 'string', ''),
        img: data.img,
        categoryId: data.category_id,
        categoryName: helperInitVarRelationType(data.category_name, 'string', ''),
        duration: data.duration,
        organization: new OrganizationEntity(normalizeOldOrganization(data?.organization)),
        isSoftPublished: helperInitVarRelationType(data?.is_soft_published, 'boolean', false),
        isAvailableForUser: helperInitVarRelationType(data?.is_available_for_user, 'boolean', false),
        isFavorite: helperInitVarRelationType(data?.is_favorite, 'boolean', false),
        isBlocked: helperInitVarRelationType(data?.is_blocked, 'boolean', false),
        src: helperInitVarRelationType(data.src, 'string', ''),
        ofertaLink: helperInitVarRelationType(data.oferta_link, 'string', ''),
        private: helperInitVarRelationType(data.private, 'boolean', false),
    };
};

//TODO удалить после рефакторинга контента
export const normalizeOldOrganization = (org: IOldOrganization): IOrganization => {
    return {
        id: Number(org.id),
        name: org.name,
        description: org.description || '',
        poster: org?.['poster'] || '',
        logo: org.logo || '',
        pdAddress: org.pdAddress || '',
        pdEmail: org.pdEmail || '',
        inn: org?.inn || '',
        ogrn: org?.ogrn || '',
        workingTime: org.workingTime || '',
        contactPhone: org?.['contact_phone'] || '',
        shareLink: org?.['shareLink'] || '',
        personalDataPolicyLink: org.personalDataPolicyLink || '',
        socials: org?.['socials'] || {},
        //@ts-ignore
        subscribersCount: org.subscribers || 0,
        usesTickets: false,
        usesCustoms: false,
        afishaForbidden: true,
        //@ts-ignore
        eventsCount: org.eventCount || 0,
        isFavorite: org?.['isFavorite'] || false,
        contentCount: org?.['contentCount'] || 0,
        analytics: {
            googleAnalytics: org?.analytics?.google_analytics || '',
            yandexMetric: org?.analytics?.yandex_metrika || '',
        },
    };
};
