import React from 'react';
import {component, Row} from 'front-components';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {IEventCityFilter} from 'services/Api/DictionariesApiService';
import {FilterInput} from '../../common/FilterInput';
import cx from 'classnames';

interface ICityFilterProps {
    onCityClickHandler: (city: IEventCityFilter) => void;
    city: IEventCityFilter;
    cityFilters: IEventCityFilter[];
}
export const CityFilter: React.FC<ICityFilterProps> = ({onCityClickHandler, city, cityFilters}) => {
    return (
        <FilterInput label={city?.title} value={city?.title}>
            {(close) => (
                <div className={cx(component('theme--darkpic-bg')(), component('theme--darkpic')())}>
                    {cityFilters?.map((city: IEventCityFilter) => (
                        <Row
                            data-analytics={DataAnalytics.userPriceChoose}
                            key={city.id}
                            onClick={() => {
                                close();
                                onCityClickHandler(city);
                            }}
                            hoverable
                        >
                            <Row.Body>
                                <Row.Text>{city.title}</Row.Text>
                            </Row.Body>
                        </Row>
                    ))}
                </div>
            )}
        </FilterInput>
    );
};
