import {useEffect} from 'react';

export const useOnScreen = (
    intersectTarget: React.MutableRefObject<HTMLElement>,
    callback: () => void,
    isOneTimeExecute = true,
): void => {
    useEffect(() => {
        if (intersectTarget.current) {
            const observerScroll = new IntersectionObserver(callbackHandler, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            });
            function callbackHandler(entries) {
                const [target] = entries;
                if (target.isIntersecting) {
                    callback();
                    //отписываемся чтобы колбэк вызвался только один раз
                    if (isOneTimeExecute) {
                        observerScroll.unobserve(intersectTarget.current);
                    }
                }
            }
            observerScroll.observe(intersectTarget.current);
            return () => {
                observerScroll.disconnect();
            };
        }
    }, [callback, intersectTarget, isOneTimeExecute]);
};
