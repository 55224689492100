import {useEffect} from 'react';

const setBodyCssProperties = (props) =>
    Object.keys(props).map((key) => window.document.body.style.setProperty(key, props[key]));

export function useModalWithUnscrolledBody() {
    useEffect(() => {
        const {position, top, left, right} = window.document.body.style;
        setBodyCssProperties({left: 0, right: 0, top: `-${window.scrollY}px`, position: 'fixed'});

        return () => {
            const scrollY = window.document.body.style.top || '0';
            setBodyCssProperties({left, right, top, position});
            window.scrollTo(0, parseInt(scrollY) * -1);
        };
    }, []);
}
