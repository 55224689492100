import {BaseApiService} from './base/BaseApiService';
import {
    IVideoResponse,
    IVideoComplaintPayload,
    IVideoComplaintResponse,
    IRegistrationToContentResponse,
    IVideoRegistrationRequest,
    IPaymentLink,
    IVideoComplaintType,
} from 'interfaces/models';
import {IVideoOrders, IVideoTicketResponse} from 'stores/Ticket';
import {IAccessPrivateVideoResponse} from '../../interfaces/models/Content';

export interface IContentListResponse {
    list: IVideoResponse[];
    total_count: number;
}

// API-сервис для работы с контентом (начиналось это всё с видео)
export class ContentApiService extends BaseApiService {
    constructor() {
        // API контента лежит в другом контроллере с другим урлом
        // обычно ссылки были бы вида api/content/{id}/registration,
        // а тут api_content/content/{id}/registration
        // для этого переопределяем baseUrl конкатенацией коренного урла +  api_content:
        super('content', `${process.env.REACT_APP_ROOT_URL}/api_content/`);
    }

    async getContent(id: string): Promise<IVideoResponse> {
        let {data} = await this.get({}, `/${id}`);
        data = data.data;
        return data as IVideoResponse;
    }

    async getContentTickets(id: string): Promise<IVideoTicketResponse> {
        const {data} = await this.get({}, `/${id}/tickets`);
        return data as IVideoTicketResponse;
    }

    async accessToContent(payload: IVideoRegistrationRequest, videoId): Promise<IRegistrationToContentResponse> {
        const {data} = await this.post(payload, `/${videoId}/purchase`);
        return data as IRegistrationToContentResponse;
    }

    async getContentPaymentMethods(videoId: string): Promise<IPaymentLink[]> {
        const {data} = await this.get({}, `/${videoId}/payment_methods`);
        return data as IPaymentLink[];
    }

    async getContentOrders(): Promise<IVideoOrders> {
        const {data} = await this.get({}, `/my`);
        return data as IVideoOrders;
    }

    async getMyVideoList(params: {limit: number; offset: number; isBlocked?: boolean}): Promise<IContentListResponse> {
        const {limit, offset} = params;
        const {data} = await this.get({limit, offset, 'filters[is_blocked]': params.isBlocked ? '1' : '0'}, `/my`);
        return data as IContentListResponse;
    }

    async getSubscriptionsVideoList(params: {limit: number; offset: number}): Promise<IContentListResponse> {
        const {data} = await this.get(params, `/subscription/feed`);
        return data as IContentListResponse;
    }

    async getMyFavoriteVideosList(params: {limit: number; offset: number}): Promise<IContentListResponse> {
        const {data} = await this.get(params, 'favorite');
        return data as IContentListResponse;
    }

    async addContentToMyFavoriteList(request: {id: string}): Promise<IVideoResponse> {
        const {data} = await this.post({}, `/${request.id}/favorite`);
        return data as IVideoResponse;
    }

    async getComplaintTypes(): Promise<IVideoComplaintType[]> {
        let {data} = await this.get({}, `/complaint_type`);
        data = data.data;
        return data as IVideoComplaintType[];
    }

    async sendComplaint(payload: IVideoComplaintPayload, videoId: string): Promise<IVideoComplaintResponse> {
        const {data} = await this.post(payload, `/${videoId}/complaint`);
        return data as IVideoComplaintResponse;
    }
}

export class PrivateVideoApiService extends BaseApiService {
    constructor() {
        super('content_id', `${process.env.REACT_APP_ROOT_URL}/api_content/`);
    }

    async getAccessMyPrivateVideo(payload: {token: string}): Promise<IAccessPrivateVideoResponse> {
        const {data} = await this.get({}, `/${payload.token}`);
        return data as IAccessPrivateVideoResponse;
    }
}

export const contentApi = new ContentApiService();
