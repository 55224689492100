export enum NavHeaderQaAttrs {
    NavHeader = 'nav-header',
    BtnGeolocation = 'btn-geolocation',
    BtnRecommended = 'btn-recommended',
    BtnCollections = 'btn-collections',
    BtnCategories = 'btn-categories',
    BtnSearch = 'btn-search',
    InputSearch = 'input-search',
    BtnOrganizers = 'btn-organizers',
    BtnJournal = 'btn-journal',
    BtnCreateEvent = 'btn-create-event',
    BtnLogin = 'btn-login',
    IconTimepad = 'icon-timepad',
    BurgerMenu = 'burger-menu',
    BtnSubscription = 'btn-subscription',
    BtnProfile = 'btn-profile',
}

export enum WindowProfileQaAttrs {
    WindowProfile = 'window-profile',
    BtnMyPurchases = 'btn-my-purchases',
    BtnFavorites = 'btn-favorites',
    BtnLogout = 'btn-logout',
    BtnBecomeOrg = 'btn-become-organizer',
    BtnEditProfile = 'btn-edit-profile',
}

export enum WindowLoginQaAttrs {
    WindowLogin = 'window-login',
    BtnCloseLogin = 'btn-close-login',
    BtnGetCode = 'btn-get-code',
    InputCode = 'input-code',
    BtnCloseCode = 'btn-close-code',
    BtnResendCode = 'btn-resend-code',
}

export enum WindowEditProfileQaAttrs {
    WindowEditProfile = 'window-edit-profile',
    BtnCloseEditProfile = 'btn-close-profile',
    IconUserImg = 'icon-user-image',
    BtnSave = 'btn-save',
}

export enum RecommendedBlockQaAttrs {
    BlockRecommendedEvents = 'block-recommended-events',
    FilterTheme = 'filter-theme',
    FilterDate = 'filter-date',
    FilterPrice = 'filter-price',
    BtnResetFilters = 'btn-reset-filters',
}

export enum CollectionQaAttrs {
    TitleCollections = 'title-collections',
    CardCollection = 'card-collection',
    BannerCollection = 'banner-collection',
    BtnShareCollection = 'btn-share-collection',
    BtnGoToPartner = 'btn-go-to-partner',
    BtnShowMore = 'btn-show-more',
}

export enum SubscriptionsQaAttrs {
    VideoCard = 'video-card',
    BtnOrganizationPage = 'btn-organization-page',
    BtnUnsubscribe = 'btn-unsubscribe',
    BtnRecommendedSubscribe = 'btn–recommended-subscribe',
}

export enum EventCardQaAttrs {
    EventCard = 'event-card',
    EventImg = 'event-image',
}

export enum EventPageQaAttrs {
    EventDate = 'bloick-date-time',
    EventAdress = 'block-address',
    BtnCloseEventAdress = 'btn-close-address',
    BtnChooseTickets = 'btn-choose-tickets',
    BtnChooseSession = 'btn-choose-session',
    EventBanner = 'event-banner',
    BtnThreeDots = 'btn-three-dots',
    BtnInfoOrganizer = 'btn-info-organizer',
    BtnCloseInfoOrganizer = 'btn-close-info-organizer',
    BlockChooseTickets = 'block-choose-tickets',
}

export enum WindowEventRegistrationQaAttrs {
    WindowEventRegistrationOrder = 'window-event-registration',
    BtnCloseOrder = 'btn-close-order',
    BtnUpArrow = 'btn-up-arrow',
    CheckboxEventAnnouncements = 'checkbox-event-announcements',
    CheckboxRecommendations = 'checkbox-recommendations',
    BtnGoToPayment = 'btn-go-to-payment',
    BtnRegister = 'btn-register',
}

export enum TicketQaAttrs {
    TicketCard = 'ticket-card',
    TicketAmount = 'ticket-amount',
    TicketAmountPrice = 'ticket-amount-price',
    TicketTitle = 'ticket-title',
    TicketPrice = 'ticket-price',
    CardTicketSeat = 'card-seat-ticket',
    EventDate = 'event-date',
}

export enum SessionQaAttrs {
    SessionCard = 'session-card',
    BtnSessionTime = 'btn-session-time',
    SessionPrice = 'session-price',
    SessionDate = 'session-date',
}

export enum WindowOrderDetailsQaAttrs {
    WindowOrderDetails = 'window-order-details',
    TicketInfo = 'ticket-info',
    ArrowBtn = 'ticket-arrow',
    DropdownBtn = 'ticket-dropdown',
    TicketLink = 'ticket-link',
    TicketsLink = 'tickets-link',
    TicketStatus = 'ticket-status',
    EventFormat = 'event-format',
}

export enum BtnsQaAttrs {
    BtnVkontakte = 'btn-vkontakte',
    BtnCopyLink = 'btn-copy-link',
    BtnAddToFavorites = 'btn-add-to-favorites',
    BtnBuyTicket = 'btn-buy-ticket',
    BtnGetTicket = 'btn-get-ticket',
    BtnOrganizerPage = 'btn-organizer-page',
    BtnPlus = 'btn-plus',
    BtnMinus = 'btn-minus',
    BtnScrollLeft = 'btn-scroll-left',
    BtnScrollRight = 'btn-scroll-right',
    BtnShare = 'btn-share',
    BtnSubscribe = 'btn-subscribe',
    BtnEvents = 'btn-events',
    BtnVideos = 'btn-videos',
    BtnMySubscriptions = 'btn-my-subscriptions',
    BtnPay = 'btn-pay',
    BtnDelete = 'btn-delete',
}

export enum InputsQaAttrs {
    InputFirstName = 'input-first-name',
    InputLastName = 'input-last-name',
    InputPhone = 'input-phone-number',
    InputEmail = 'input-email',
}
