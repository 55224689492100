import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {BtnsQaAttrs, NavHeaderQaAttrs, WindowProfileQaAttrs} from 'interfaces/models';
import {IRowDropdownOption} from './types';
import {RichLink} from '../utility/RichLink';
import {getRoute, TPRoutes} from '../../routing/config';

export const headerLinks = {
    welcome: '/',
    registration: process.env.ORG_REGISTER_URL,
    business: process.env.TIMEPAD_BUSINESS_URL,
    login: process.env.ORG_LOGIN_URL,
    journal: 'https://journal.timepad.ru',
};

export const mediaPoints = [
    '(max-width: 735.6px)', // res-0
    '(max-width: 1023.6px)', // res-1
    '(min-width: 1024px)', // res-2
];

export const internalLinks = (city: string): {id: string; label: string; route: string; dataQaAttr: string}[] => [
    {
        id: 'main',
        label: 'Главная',
        route: getRoute(TPRoutes.CITY_FEED, {city}),
        dataQaAttr: NavHeaderQaAttrs.BtnRecommended,
    },
    {
        id: 'subscriptions',
        label: 'Подписки',
        route: TPRoutes.EVENT_SUBSCRIPTIONS,
        dataQaAttr: NavHeaderQaAttrs.BtnSubscription,
    },
    {
        id: 'collections',
        label: 'Подборки',
        route: getRoute(TPRoutes.CITY_COLLECTIONS, {city}),
        dataQaAttr: NavHeaderQaAttrs.BtnCollections,
    },
    {
        id: 'categories',
        label: 'Категории',
        route: TPRoutes.CATEGORIES,
        dataQaAttr: NavHeaderQaAttrs.BtnCategories,
    },
];

export const profileList: (logout: () => void) => IRowDropdownOption[] = (logout) => [
    {
        label: 'Мои покупки',
        as: RichLink,
        analytics: DataAnalytics.userPurchases,
        href: TPRoutes.PURCHASES,
        dataQaAttr: WindowProfileQaAttrs.BtnMyPurchases,
    },
    {
        label: 'Мои подписки',
        as: RichLink,
        analytics: DataAnalytics.userSubscribes,
        href: TPRoutes.ORGANIZATION_SUBSCRIPTIONS,
        dataQaAttr: BtnsQaAttrs.BtnMySubscriptions,
    },
    {
        label: 'Избранное',
        as: RichLink,
        analytics: DataAnalytics.userFavorites,
        href: TPRoutes.FAVORITES_EVENTS,
        dataQaAttr: WindowProfileQaAttrs.BtnFavorites,
    },
    {
        label: 'Выход',
        analytics: DataAnalytics.userExit,
        onClick: logout,
        dataQaAttr: WindowProfileQaAttrs.BtnLogout,
    },
];

export const externalLinks: IRowDropdownOption[] = [
    {
        label: 'Организаторам',
        as: RichLink,
        href: headerLinks.business,
        analytics: DataAnalytics.menuForOrgsBecomeorg,
        external: true,
        dataQaAttr: NavHeaderQaAttrs.BtnOrganizers,
        target: '_blank',
    },
    {
        label: 'Журнал',
        as: RichLink,
        href: headerLinks.journal,
        external: true,
        dataQaAttr: NavHeaderQaAttrs.BtnJournal,
        target: '_blank',
    },
];
