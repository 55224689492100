import {BaseApiService} from './base/BaseApiService';

interface IGetCodeResponse {
    token: string;
    isNewUser: boolean;
    expiresAt: Date;
    codeIndex: number;
    renewAfter: Date;
}

export interface ILoginResponse {
    token: string;
}

export interface IUpdateAuthResponse extends ILoginResponse {}

export class AuthApiService extends BaseApiService {
    constructor() {
        super('auth');
    }

    async getCode(phone: string): Promise<IGetCodeResponse> {
        const {data} = await this.post({phone}, 'get-code');
        return data as IGetCodeResponse;
    }

    async login(code: string, token: string) {
        const {data} = await this.post({code: String(code), token}, 'login');
        return data as ILoginResponse;
    }

    async updateAuth() {
        const {data} = await this.get({}, 'update-auth');
        return data as IUpdateAuthResponse;
    }
}
