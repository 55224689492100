import {IPluralizerException, pluralizeHelper, IPluralFormConfig} from './base';

const exceptions: IPluralizerException[] = [
    {
        amount: 0.5,
        handler: () => 'полчаса',
    },
    {
        amount: 1.5,
        handler: () => 'полтора часа',
    },
];

const config: IPluralFormConfig = ['час', 'часа', 'часов'];

export const hourPluralize = (
    amount: number,
    withAmount = false,
    floorAmount = true,
    allowExceptions = true,
): string => {
    if (allowExceptions) {
        const exception = exceptions.find((exception) => exception.amount === amount);
        if (exception) return exception.handler(exception.amount);
    }

    const flooredAmount = floorAmount ? Math.floor(amount) : amount;
    return pluralizeHelper(flooredAmount, config, withAmount);
};
