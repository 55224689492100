import {observable, computed} from 'mobx';
import moment, {Moment} from 'moment';

import {IEventSession, IOrder} from 'interfaces/models';
import {getTimer, getDateRangeDescription} from 'services/Helpers/DateHelper/DateHelper';

export class SessionEntity implements IEventSession {
    @observable id: number;
    @observable maxPrice: number;
    @observable minPrice: number;
    @observable startDate: string;
    @observable endDate: string;
    @observable isRegistrationOpened: boolean;
    @observable orders: IOrder[];

    constructor(data: IEventSession) {
        Object.assign(this, data);
    }

    @computed
    get start(): Moment {
        return moment(this.startDate);
    }

    @computed
    get end(): Moment {
        return moment(this.endDate);
    }

    @computed
    get readableDateRange(): string {
        return getDateRangeDescription(this.start, this.end);
    }

    @computed
    get dateHint(): string {
        return getTimer(this.start, this.end);
    }
}
