import {BaseApiService} from './base/BaseApiService';

export interface IBannersResponse {
    result: 'ok' | 'error';
    data: TypeBannerList;
    error?: string;
}

export interface IBanner {
    placeId: number;
    placeCode: string;
    placeName: string;
}

export type TypeBannerList = Record<string, IBanner>;

export class BannersApiService extends BaseApiService {
    constructor() {
        super('banners');
    }

    async getBannersList(): Promise<IBannersResponse> {
        const {data}: {data: IBannersResponse} = await this.get(null);

        return data;
    }
}
