import React, {useCallback} from 'react';
import {observer} from 'mobx-react';
import {useScript} from 'front-components';
import {useContainer} from 'timepad-di';
import {Role} from './footer.types';
import {UserStore} from 'stores/User';
import {useTimeout} from 'services/hooks';

const footerBlockId = 'tp_footer_block';

export const Footer = observer(() => {
    const [userStorage] = useContainer(UserStore);

    const [isLoading] = useScript({
        //?update=1 нужен чтобы каждый раз загружлось модальное окно, а не бралось из кэша
        src: `${process.env.TIMEPAD_BASE_URL}js/packages/front/tpfooter_script.js?update=1`,
        'data-timepad-footer': true,
        innerText: FooterConfigs,
    });

    const setUserData = useCallback(() => {
        const feedbackModal = window.Timepad?.feedbackModal;
        if (!!feedbackModal && !isLoading) {
            return feedbackModal.setData({
                role: Role.Member,
                name: userStorage.fullName || '',
                email: userStorage.user?.email || '',
                phone: userStorage.user?.phone || '',
            });
        }
    }, [isLoading, userStorage.fullName, userStorage.user]);

    //setTimeout необходим для вставки данных залогиненного пользователя в форму
    useTimeout(setUserData);

    return <div id={footerBlockId} />;
});

const FooterConfigs = `
    conf = {
        "id": "${footerBlockId}",
        "currentUserData": {
            "role": "Участник",
            "name": "",
            "email": "",
            "phone": ""
        },
        "useFrontComponentsStyles": "false"
    }`;
