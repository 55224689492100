export enum SeatmapSeatTypes {
    Table = 'TABLE',
    Section = 'SECTION',
    Seat = 'SEAT',
}
export interface ISeatmapSeat {
    id: number;
    key: string; // формата Партер;;115;;21
    price: number;
    rowNumber: string;
    seatName: string;
    sectionId: number;
    sectionName: string;
    sectorId: number;
    x: number;
    y: number;
}

export interface ISeatmapPrice {
    id: number;
    name: string;
    color: string;
}

export interface ISeatmapSection {
    ga: boolean;
    id: number;
    name: string;
    price: number;
    type: SeatmapSeatTypes.Section | SeatmapSeatTypes.Table;
    rect: {left: number; top: number; height: number; width: number};
}

interface ISeatCart extends Pick<ISeatmapSeat, 'id' | 'key' | 'price'> {}

interface IGaCart extends Pick<ISeatmapSeat, 'key' | 'price'> {
    sectorId: number;
    count?: number;
}

export interface ISeatmapCart {
    seats: ISeatCart[];
    ga: IGaCart[];
}

export interface ISeatmapInstance {
    getPrices: () => ISeatmapPrice[];
    loadEvent: (eventId: string, sectionId?: number) => Promise<void>;
    zoomIn: () => void;
    zoomOut: () => void;
    zoomToFit: () => void;
    initCart: (cart: ISeatmapCart) => void;
    clearCart: () => void;
    destroy: () => void;
    setHeight: (value: number) => void;
    addSeatsToCart: (seats: ISeatCart[]) => void;
    addGaToCart: (ga: IGaCart) => void;
    removeSeatsFromCartByIds: (ids: number[]) => void;
    removeGaFromCart: (args: Pick<IGaCart, 'price' | 'sectorId'>) => void;
}
