import {IEventCheckout} from 'interfaces/models';
import {FormName, FormId} from 'stores/Analytics/elementIds';
import {useContainer} from 'timepad-di';
import {AnalyticsStore} from 'stores/Analytics';

interface IUseCheckoutAnalyticsResults {
    analyticBuyStep: (methodKey: string, methodValue: string) => void;
    analyticSuccessSend: (name: FormName, price: string | number) => void;
    analyticFormError: (errorId: string | number) => void;
    analyticsFormFill: (elementId: string | number) => void;
    analyticFormInit: (formId: FormId, formName: FormName) => void;
}

interface IUseCheckoutAnalyticsArgs {
    event: IEventCheckout;
    formId?: FormId;
}

export const useCheckoutAnalytics = ({event, formId}: IUseCheckoutAnalyticsArgs): IUseCheckoutAnalyticsResults => {
    const [analyticsStorage] = useContainer(AnalyticsStore);

    const analyticBuyStep = (methodKey: string, methodValue: string) => {
        analyticsStorage.buyStep({option: methodKey});
        if (event.googleAnalyticsId) {
            analyticsStorage.gtagSetCheckoutOption(event.googleAnalyticsId, {
                checkoutOption: methodKey,
                value: methodValue,
            });
        }
    };

    const analyticSuccessSend = (name: FormName, price: string | number) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        analyticsStorage.formSuccessSend({form_id: formId, eventPage: true, name: name, price: price});
    };

    const analyticFormError = (errorId: string | number) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        analyticsStorage.formError({form_id: formId, err_id: errorId, eventPage: true});
    };

    const analyticsFormFill = (elementId: string | number) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        analyticsStorage.formFill({form_id: formId, element_id: elementId, eventPage: true});
    };

    const analyticFormInit = (formId: FormId, formName: FormName) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        analyticsStorage.formView({form_id: formId, eventPage: true, name: formName});
    };

    return {analyticBuyStep, analyticSuccessSend, analyticFormError, analyticsFormFill, analyticFormInit};
};
