import {PluralForm} from './PluralFormEnum';

export type IPluralFormConfig = {
    [PluralForm.genitiveSingular]: string;
    [PluralForm.nominativeSingular]: string;
    [PluralForm.genitivePlural]: string;
    length: 3;
};

/** Вспомогательная функция, для выбора формы склонения */
export const getPluralForm = (amount: number): PluralForm => {
    const lastTwoDigits = amount % 100;
    if (!(lastTwoDigits >= 11 && lastTwoDigits <= 19)) {
        const lastDigit = amount % 10;
        switch (lastDigit) {
            case 1:
                return PluralForm.genitiveSingular;
            case 2:
                return PluralForm.nominativeSingular;
            case 3:
                return PluralForm.nominativeSingular;
            case 4:
                return PluralForm.nominativeSingular;
            default:
                return PluralForm.genitivePlural;
        }
    } else {
        return PluralForm.genitivePlural;
    }
};

/**
 * Принимает число и массив слов для склонения
 * @param amount чило на вход
 * @param config склонения для следующих форм: `['одна шт', '2 - 4 шт', 'пять шт']`
 */
export const pluralizeHelper = (amount: number, config: IPluralFormConfig, withAmount = false): string => {
    const pluralForm = getPluralForm(amount);
    return withAmount ? amount + ' ' + config[pluralForm] : config[pluralForm];
};
