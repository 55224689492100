import React from 'react';

export const Content = () => {
    return (
        <p className="tbody tbody--block">
            Произошла неизвестная ошибка. Если это повторится —{' '}
            <a href={process.env.FEEDBACK_FORM_URL}>напишите в службу поддержки.</a>
        </p>
    );
};
