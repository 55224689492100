export enum FormId {
    authPhoneStep = 'auth_telephone_form',
    authCodeStep = 'auth_code_form',
    authProfileStep = 'auth_personalinfo_form',
    profileForm = 'profile_edit_form',
    checkoutForm = 'registration_event_form',
    paymentForm = 'payment_event_form',
    complaintForm = 'user_video_complaint_f004',
    accessToFreeContentForm = 'user_get_free_video_f005',
    buyVideoContentForm = 'user_buy_video_f006',
    afishaGetPasscodeTel = 'afisha_get_passcode_tel_f001',
    afishaEnterPasscodeTel = 'afisha_enter_passcode_tel_f002',
    userEventTicketBuyForm = 'user_event_ticket_buy_form_f001',
    userEventTicketFreeForm = 'user_event_ticket_free_form_f002',
}

export enum ButtonId {
    eventCard = 'event_button - list_type',
    eventCardCollection = 'event_button - list_collection',
    eventCardFavorite = 'event_button - favorite - list_type',
    eventCardCollectionFavorite = 'event_button - favorite - list_collection',
    eventPageFavorite = 'event_button - favorite - event_page',
    eventCardOrg = 'card_org_button',
    eventPageOrg = 'event_page_org_button',
    carouselArrow = 'carusel_arrow_button - depth',
    eventCategory = 'event_category_button',
    orgPageShowMore = 'show_more_org_events_button',
    categoryPageShowMore = 'show_more_theme_events_button',
    chooseTicketsFloatingButton = 'select_tickets_top_button',
    buyTicketsFloatingButton = 'buy_n_tickets_top_button',
    mapBlock = 'map_event_button',
    sessionTimespan = 'session_event_button',
    buyTickets = 'buy_n_tickets_button',
    chooseTickets = 'select_tickets_button',
}

export enum EventOrigin {
    myEvents = 'go_events_list',
    upcoming = 'recommended_today_events_list',
    future = 'recommended_future_events_list',
    categoryEvents = 'theme_events_list',
    organizationEvents = 'org_events_list',
    unknown = 'not_set',
}

export enum ClickClasses {
    eventCardFavorite = 'meventcard_favorite',
    eventPageFavorite = 'eventpage_favorite',
}

export enum FormName {
    complaintFormVideoPage = 'videocontent-info',
    accessToFreeVideoContent = 'access_to_free_video_content',
    buyVideoContent = 'buy_video_content',
    afishaGetPasscodeTel = 'afisha_get_passcode_tel_f001',
    afishaEnterPasscodeTel = 'afisha_enter_passcode_tel_f002',
    afishaGetPasscodeTelForm = 'afisha_get_passcode_tel_form',
    afishaEnterPasscodeTelForm = 'afisha_enter_passcode_tel_form',
    userEventTicketBuyForm = 'user_event_ticket_buy_form',
    userEventTicketFreeForm = 'user_event_ticket_free_form',
    userEventTicketBuyForPrice = 'user_event_ticket_buy_for_price',
}
