import {IPaymentLink} from './Registration';
import {FieldTag} from './../forms/forms';
import {IFieldConfig} from 'interfaces/forms/forms';

export interface IRegistrationInfo {
    form: IFormField[];
    paymentMethods: IPaymentLink[];
    subscribes: ISubscribeStatus;
}

export interface IFormField extends IFieldConfig {
    name: string;

    tag: FieldTag;

    isRequired: boolean;

    status: string;

    description?: string;

    type: FieldType;

    variants?: string[];

    sort: number;

    meta: {};

    id: string;
}

export interface IRadioGroupConfig {
    inputs: IRadioConfig[];
}

export interface IRadioConfig {
    title: string;
    value: string;
}

export interface ICheckboxConfig {
    title: string;
    value: string;
}

export enum FieldType {
    text = 'text',
    bigtext = 'bigtext',
    phone = 'phone',
    radio = 'radio',
    multivar = 'multivar',
    upload = 'upload',
}

export interface IFormFields {
    [key: string]: string;
}

export interface ICheckoutTicket {
    id?: string;
    values: IFormFields[];
}

export interface ICheckoutTicketsFormValues {
    tickets: ICheckoutTicket[];
    masterQuestions: IFormFields;
}

export interface ICheckoutTicketsData extends IFormFields {
    id: string;
    name: string;
    surname: string;
    mail: string;
    phone: string;
}

export interface ISubscribeStatus {
    uses_subscribe: boolean | null;
    subscribe_digest: boolean | null;
}
