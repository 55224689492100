import React from 'react';
import {Row} from 'front-components';

import {IRowDropdownOption} from '../types';

interface IRowDropdownOptionProps extends IRowDropdownOption {}

export const RowDropdownOption: React.FC<IRowDropdownOptionProps> = ({
    as = 'div',
    href,
    analytics,
    label,
    onClick,
    icon,
    dataQaAttr,
    ...props
}) => {
    const Tag = as;
    return (
        <Tag to={href} data-analytics={analytics} data-qa={dataQaAttr} {...props}>
            <Row hoverable onClick={onClick}>
                <Row.Body>
                    <Row.Text>{label}</Row.Text>
                </Row.Body>
                {icon && <Row.Icon>{icon}</Row.Icon>}
            </Row>
        </Tag>
    );
};
