import {observable, computed, flow, action, reaction} from 'mobx';
import {AuthApiService} from 'services/Api';
import {AccessTokenStorage} from 'services/TokenStorage';
import {isAuthCodeValid} from 'services/Validation/FormValidation';
import {ILoginResponse} from 'services/Api/AuthApiService';
import {diContainer} from 'timepad-di';

interface IGetCodeResponse {
    token: string;
    isNewUser: boolean;
    expiresAt: Date;
    codeIndex: number;
    renewAfter: Date;
}
export class AuthStore {
    @observable isLoading = false;

    @observable error: Error;

    @observable code: string;

    @observable authToken: string;

    @observable isNewUser: boolean;

    @observable isUnlogin: boolean; // Позволяет определить что пользователь разлогинился (был ранее залогинен)

    // Значение необходимо только для корретного отображения onboadring. Для решения этого бага https://timepad.myjetbrains.com/youtrack/issue/RET-176/Otkryt-Afishu-2-Pokupka-biletaregistratsiya-na-sobytie-neavtorizovannym-polzovatelem#focus=Comments-92-234869.0-0
    // TODO: удалить при выпиливании Onboarding
    @observable isAuthThroughPayment = false;

    @observable authPhone: string;

    @observable codeRetryAttempts = 3;

    @observable renewAfter: Date;

    @observable expiresAt: Date;

    @observable codeIndex;

    private readonly apiService: AuthApiService;

    constructor() {
        this.apiService = diContainer.get(AuthApiService);

        this.reset();

        reaction(
            () => this.code,
            () => {
                isAuthCodeValid(this.code) && this.confirmCode(this.code);
            },
        );
    }

    reset(): void {
        this.authToken = null;
        this.isNewUser = null;
        this.authPhone = null;
        this.renewAfter = null;
        this.expiresAt = null;
        this.codeIndex = null;
    }

    @action.bound
    resetAuthToken = (): void => {
        this.authToken = null;
    };

    @action.bound
    setCode = (code: string): void => {
        this.code = code;
    };

    @action.bound
    registerPhone = flow(function*(this: AuthStore, phone: string) {
        this.isLoading = true;
        this.error = null;
        this.authPhone = phone;
        this.codeRetryAttempts = 3;

        try {
            const {
                token,
                renewAfter,
                expiresAt,
                codeIndex,
                isNewUser,
            }: IGetCodeResponse = yield this.apiService.getCode(phone);

            this.authToken = token;
            this.renewAfter = renewAfter;
            this.isNewUser = isNewUser;
            this.expiresAt = expiresAt;
            this.codeIndex = codeIndex;
        } catch (err) {
            this.error = err;
        } finally {
            this.isLoading = false;
        }
    });

    @action.bound
    registerCurrentPhone = (): Promise<void> => this.registerPhone(this.authPhone);

    @action.bound
    confirmCode = flow(function*(this: AuthStore, code: string) {
        this.error = null;
        this.isLoading = true;

        try {
            const {token}: ILoginResponse = yield this.apiService.login(code, this.authToken);
            AccessTokenStorage.setAccessToken(token);
            this.code = ''; // remove old data
        } catch (err) {
            this.error = err;
            err.data.codeRetryAttempts
                ? (this.codeRetryAttempts = err.data.codeRetryAttempts)
                : (this.codeRetryAttempts = 0);
        } finally {
            this.isLoading = false;
            this.isUnlogin = false;
        }
    });

    @action.bound
    refreshAuthToken = flow(function*(this: AuthStore) {
        try {
            const {token} = yield this.apiService.updateAuth();
            AccessTokenStorage.setAccessToken(token);
        } catch (err) {
            this.error = err;
            this.logout();
        }
    });

    @action.bound
    logout = (): void => {
        AccessTokenStorage.removeAccessToken();
        this.reset();
        sessionStorage.clear();
        this.isUnlogin = true;
        // Перезагрузка страницы чтобы не сохранялись пользовательские сторы
        setTimeout(() => location.reload(), 0);
    };

    @computed
    get isAuthorized(): boolean {
        return !!AccessTokenStorage.accessToken;
    }

    @computed
    get isPhoneSent(): boolean {
        return !!this.authToken;
    }
}
