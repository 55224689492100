import {diContainer} from 'timepad-di';
import {observable, flow, runInAction, action, computed} from 'mobx';

import {ContentApiService} from 'services/Api';
import {getOrderInfo} from 'services/Helpers/OrderHelper';
import {ITicket, IVideo} from 'interfaces/models';
import {TicketEntity} from './TicketEntity';

enum VideoReg {
    'singlereg' = 'singlereg',
}

export interface IVideoTicketResponse {
    videoRegType: VideoReg;
    additionalComission: number;
    tickets: ITicket[];
}

interface IMappedVideoOrder {
    id: string;
    total: number;
}

interface IMappedVideoOrders {
    orders: IMappedVideoOrder[];
}

export interface IVideoOrders {
    list: IVideo[];
    totalCount: number;
}

export class VideoTicket {
    @observable isLoading = false;

    @observable ordersLoading = false;

    @observable videoRegType: VideoReg = null;

    @observable videoTickets: TicketEntity[] = [];

    @observable videoOrders: IMappedVideoOrders = null;

    @observable error: Error = null;

    @observable ratioFee = 0;

    // order
    @observable orderTickets: {[key: string]: TicketEntity} = {};

    private readonly contentApiService: ContentApiService;

    constructor() {
        this.contentApiService = diContainer.get(ContentApiService);
    }

    createModels(data: ITicket[], eventId: string): TicketEntity[] {
        return data.map((v) => {
            return new TicketEntity(v, Number(eventId));
        });
    }

    //Оставил flow из-за типизации в переиспользуемых компонентах
    getVideoTickets = flow(function*(this: VideoTicket, id: string) {
        this.isLoading = true;
        this.error = null;

        try {
            const {
                tickets,
                videoRegType = VideoReg.singlereg,
                additionalComission = 0,
            }: IVideoTicketResponse = yield this.contentApiService.getContentTickets(id);

            this.videoTickets = this.createModels(tickets, id);
            this.videoRegType = videoRegType;
            this.videoTickets.forEach((videoTicket) => (videoTicket.maxOrder = 1));
            this.ratioFee = additionalComission;

            const {numOfTypes} = getOrderInfo(this.videoTickets);

            if (numOfTypes === 1 && this.orderIsEmpty) {
                this.selectTicket(this.videoTickets[0], 1);
            }
        } catch (err) {
            this.error = err;
        } finally {
            this.isLoading = false;
        }
    });

    @action.bound
    async getVideoOrders(): Promise<void> {
        this.ordersLoading = true;
        this.error = null;
        this.videoOrders = null;

        try {
            const data: IVideoOrders = await this.contentApiService.getContentOrders();
            const totalCount = data['total_count'];

            runInAction(() => {
                this.videoOrders = {
                    orders: data.list?.map<IMappedVideoOrder>((order) => ({id: order.id, total: totalCount})),
                };
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.ordersLoading = false;
            });
        }
    }

    @action.bound
    selectTicket(ticket: TicketEntity, count: number): void {
        ticket.setCount(count);
        this.updateTicket(ticket, count);
    }

    // order
    @action.bound
    updateTicket(ticket: TicketEntity, count: number): void {
        if (!this.isTicketForSameOrderEvent(ticket) || this.isOneVideoTicketPerPerson) {
            this.wipeVideoOrder();
        }

        if (!this.orderTickets[ticket.id]) {
            this.orderTickets[ticket.id] = ticket;
        }

        this.orderTickets[ticket.id].setCount(count);
    }

    @computed
    get tickets(): Record<string, number> {
        return Object.entries(this.orderTickets).reduce((acc: Record<string, number>, [key, val]) => {
            acc[key] = val.count;
            return acc;
        }, {});
    }

    @computed
    get orderIsEmpty(): boolean {
        return Object.keys(this.orderTickets).length === 0;
    }

    @computed
    get isOneVideoTicketPerPerson(): boolean {
        return this.videoRegType === VideoReg.singlereg;
    }

    wipeVideoOrder(): void {
        this.orderTickets = {};
        this.videoTickets?.forEach((ticket) => ticket.setCount(0));
    }

    private isTicketForSameOrderEvent(ticket: TicketEntity) {
        return Object.values(this.orderTickets).every((orderTicket) => orderTicket.eventId === ticket.eventId);
    }
}
