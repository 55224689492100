// return decimal separator of a digit accordint to locale settings
const decimalSeparator = (): string => {
    const n = 1.1;
    const separator = n.toFixed(1).substring(1, 2);
    return separator;
};

export const THOUSANDS_SEPARATOR_NARROW_NO_BREAK_SPACE = '\u202f';
export const THOUSANDS_SEPARATOR_NON_BREAKING_SPACE = '\u00A0';

export const addThousandsSeparator = (value: string | number, separator: string): string => {
    if (value === undefined || value === null) {
        return undefined;
    }
    const reverse = (s: string): string => {
        return s
            .split('')
            .reverse()
            .join('');
    };
    const splitByThousands = (num: string): string[] => {
        return num.match(/.{1,3}/g) || [];
    };

    const valueString = value + '';
    const dSep = decimalSeparator();
    const [integer, fractional] = valueString.split(dSep);
    const fractionalWithSep = fractional ? dSep + fractional : '';
    const intergerWithSep = reverse(splitByThousands(reverse(integer)).join(separator));
    return intergerWithSep + fractionalWithSep;
};

// Important: Использовать аккуратно!!!
export const truncateNegative = (price: number): number => {
    return price > 0 ? price : 0;
};

// Возвращает:
// 1. цену, если вилки по ценам нет
// 2. минимальную цену, если есть вилка
// 3. "Бесплатно", если нет вилки и обе цены 0
export const getPrice = (
    min: number,
    max?: number,
    explicitPrice = false,
    thousandsSeparatorChar: string = THOUSANDS_SEPARATOR_NON_BREAKING_SPACE,
): string => {
    const safeMin = truncateNegative(min);
    const safeMax = truncateNegative(max);
    const minWithSeparator = addThousandsSeparator(roundNumber(safeMin), thousandsSeparatorChar);
    const finalPrice = safeMin === safeMax || !safeMax ? minWithSeparator : `от ${minWithSeparator}`;

    return finalPrice === '0' && !explicitPrice ? 'Бесплатно' : finalPrice + ' ₽';
};

const roundNumber = (initValue: number) => {
    if (initValue % 1 > 0) {
        return (Math.round(initValue * 100) / 100).toFixed(2);
    }
    return initValue;
};
