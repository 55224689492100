import {observable, reaction, action, runInAction, computed} from 'mobx';
import {VideoEntity} from './VideoEntity';
import {ContentApiService, PrivateVideoApiService} from 'services/Api';
import {logger} from '../../services/Logger';
import {IContentListResponse} from '../../services/Api/ContentApiService';
import {IVideoResponse, IVideoComplaintPayload, IVideoComplaintResponse, IVideoComplaintType} from 'interfaces/models';
import {sanitizeVideoData} from 'services/Helpers/VideoHelper';
import {diContainer} from 'timepad-di';
import {VideoTicket} from '../Ticket';
import {getOrderInfo} from '../../services/Helpers/OrderHelper';

export class VideoStore {
    @observable currentVideo: VideoEntity = null;

    @observable myFavoriteVideosList: VideoEntity[] = [];

    @observable totalCountMyFavoriteVideo = 0;

    @observable loading = false;

    @observable myVideosList: VideoEntity[] = [];

    @observable myVideosListBlocked: VideoEntity[] = [];

    @observable totalCountMyVideos = 0;

    @observable totalCountMyVideosBlocked = 0;

    @observable loadingMyVideo = false;

    @observable loadingFavoriteVideo = false;

    @observable isPrivateVideo = false;

    @observable error?: Error;

    @observable complaintResponse?: IVideoComplaintResponse;

    @observable complaintTypes: IVideoComplaintType[] = [];

    @observable videoTicket: VideoTicket;

    private readonly contentApiService: ContentApiService;
    private readonly privetVideoApiService: PrivateVideoApiService;

    constructor() {
        this.videoTicket = new VideoTicket();

        this.contentApiService = diContainer.get(ContentApiService);
        this.privetVideoApiService = diContainer.get(PrivateVideoApiService);

        reaction(
            () => this.currentVideo,
            () => {
                if (this.currentVideo && !this.currentVideo?.isAvailableForUser) {
                    this.videoTicket.getVideoTickets(this.currentVideo.id);
                }
            },
        );

        reaction(
            () => this.currentVideo,
            () => this.videoTicket.wipeVideoOrder(),
        );
    }

    @action.bound
    async getVideo(id: string): Promise<void> {
        this.loading = true;
        this.currentVideo = null;
        this.error = null;
        this.isPrivateVideo = false;

        try {
            const data: IVideoResponse = await this.contentApiService.getContent(id);

            runInAction(() => {
                this.currentVideo = new VideoEntity(sanitizeVideoData(data));
            });
        } catch (err) {
            if (err.status === 404 && err?.data?.redirect_link) {
                location.replace(err?.data?.redirect_link);
            } else if (err.status === 403) {
                runInAction(() => {
                    this.isPrivateVideo = true;
                });
            } else {
                runInAction(() => {
                    this.error = err;
                });
            }
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    createModels(data: IVideoResponse[]): VideoEntity[] {
        return data.map((v) => new VideoEntity(sanitizeVideoData(v)));
    }

    @action.bound
    getAccessMyPrivateVideo = async (token: string): Promise<string> => {
        this.error = null;

        try {
            const data = await this.privetVideoApiService.getAccessMyPrivateVideo({token});
            if (data?.content_id !== null) {
                return data.content_id; // Доступ предоставлен
            }
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    };

    @action.bound
    getMyVideoList = async (
        params?: {limit: number; offset: number; isBlocked?: boolean},
        append = false,
    ): Promise<void> => {
        this.loadingMyVideo = true;
        this.error = null;

        try {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const {list, total_count} = await this.contentApiService.getMyVideoList({...params});

            runInAction(() => {
                if (params.isBlocked) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    this.totalCountMyVideosBlocked = total_count;

                    this.myVideosListBlocked = append
                        ? [...this.myVideosListBlocked, ...this.createModels(list)]
                        : this.createModels(list);
                } else {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    this.totalCountMyVideos = total_count;

                    this.myVideosList = append
                        ? [...this.myVideosList, ...this.createModels(list)]
                        : this.createModels(list);
                }
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.loadingMyVideo = false;
            });
        }
    };

    @action.bound
    getMyFavoriteVideoList = async (params?: {limit: number; offset: number}, append = false): Promise<void> => {
        this.loadingFavoriteVideo = true;
        this.error = null;

        try {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const {list, total_count}: IContentListResponse = await this.contentApiService.getMyFavoriteVideosList({
                ...params,
            });

            runInAction(() => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                this.totalCountMyFavoriteVideo = total_count;

                this.myFavoriteVideosList = append
                    ? [...this.myFavoriteVideosList, ...this.createModels(list)]
                    : this.createModels(list);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.loadingFavoriteVideo = false;
            });
        }
    };

    @action.bound
    addContentToFavorite = async (videoId: string): Promise<void> => {
        try {
            await this.contentApiService.addContentToMyFavoriteList({id: videoId});
        } catch (err) {
            runInAction(() => {
                logger.error(err);
                throw err.data;
            });
        }
    };

    @action.bound
    getComplaintTypes = async (): Promise<void> => {
        this.error = null;

        try {
            const data: IVideoComplaintType[] = await this.contentApiService.getComplaintTypes();

            const stringedData: IVideoComplaintType[] = data.map((item) => {
                return {value: String(item.value), name: item.name, label: item.label};
            });

            runInAction(() => {
                this.complaintTypes = stringedData;
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    };

    @computed
    get selectedOrderInfo() {
        const info = getOrderInfo(Object.values(this.videoTicket.orderTickets));
        info.totalSum = Math.round(info.totalSum * 100) / 100;
        return info;
    }

    @computed
    get serviceFee(): number {
        const totalSum = this.selectedOrderInfo.totalSum;
        return Math.round(totalSum * this.videoTicket.ratioFee * 100) / 100;
    }

    async sendComplaint(payload: IVideoComplaintPayload): Promise<void> {
        runInAction(() => {
            this.loadingFavoriteVideo = true;
            this.error = null;
        });

        try {
            const data: IVideoComplaintResponse = await this.contentApiService.sendComplaint(
                payload,
                this.currentVideo.id,
            );

            runInAction(() => {
                this.complaintResponse = data;
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    }

    @computed
    get currentVideoHasOrders(): boolean {
        return (
            this.videoTicket.videoOrders?.orders?.some((order) => order.id === this.currentVideo?.id) &&
            !!this.videoTicket.videoOrders?.orders?.length
        );
    }
}
