import {useScript} from 'front-components';
import {ReactElement, ReactNode, Children} from 'react';

declare global {
    interface Window {
        yaContextCb: any;
        Ya: any;
    }
}

export const useBanners = (): void => {
    const runCondition = !(window.yaContextCb && window.Ya);

    useScript({
        src: 'https://yandex.ru/ads/system/context.js',
        stopCondition: !runCondition,
    });

    window.yaContextCb = window.yaContextCb || [];
};

export const injectBanner = (events: ReactNode) => (
    banners: {position: number; banner: ReactElement}[],
): ReactNode[] => {
    const eventsArray = Children.toArray(events);
    return banners.reduce(
        (acc, el) => {
            acc.splice(el.position, 0, el.banner);
            return acc;
        },
        [...eventsArray],
    );
};
