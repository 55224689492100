import {observable, flow, reaction, autorun, action, computed} from 'mobx';
import {IUser, IExtendedUser} from 'interfaces/models';
import {UserApiService} from 'services/Api';
import {AccessTokenStorage} from 'services/TokenStorage';
import {diContainer} from 'timepad-di';
import {AuthStore} from 'stores/Auth';

export class UserStore {
    @observable isLoading = false;

    @observable isLoadingExtended = false;

    @observable user?: IUser = null;

    @observable extendedInfo?: IExtendedUser = null;

    @observable error?: Error = null;

    @observable errorExtended?: Error = null;

    private readonly authStorage: AuthStore;

    private readonly apiService: UserApiService;

    constructor() {
        this.authStorage = diContainer.get(AuthStore);

        this.apiService = diContainer.get(UserApiService);

        autorun(() => {
            if (!this.authStorage?.isAuthorized) {
                this.clearState();
            }
        });

        reaction(
            () => AccessTokenStorage.accessToken,
            async () => {
                if (AccessTokenStorage.accessToken) {
                    await this.getUserProfile();
                    await this.getExtendedUserProfile();
                }
            },
            {fireImmediately: true},
        );
    }

    @action.bound
    getUserProfile = flow(function*(this: UserStore, params?) {
        this.isLoading = true;
        this.error = null;

        try {
            const user: IUser = yield this.apiService.getUser(params);

            this.user = user;
        } catch (err) {
            this.error = err;
        } finally {
            this.isLoading = false;
        }
    });

    @action.bound
    getExtendedUserProfile = flow(function*(this: UserStore, params?) {
        this.isLoadingExtended = true;
        this.errorExtended = null;

        try {
            const extendedInfo: IExtendedUser = yield this.apiService.getExtendedUser(params);

            this.extendedInfo = extendedInfo;
        } catch (err) {
            this.errorExtended = err;
        } finally {
            this.isLoadingExtended = false;
        }
    });

    @action.bound
    postUserProfile = flow(function*(this: UserStore, params?) {
        this.isLoading = true;
        this.error = null;

        try {
            const user: IUser = yield this.apiService.postUser(params);

            this.user = user;
        } catch (err) {
            this.error = err;
        } finally {
            this.isLoading = false;
        }
    });

    @action.bound
    clearState() {
        this.user = null;
        this.extendedInfo = null;
    }

    @computed
    get fullName() {
        if (this.user?.name && this.user?.surname) {
            return this.user?.surname + ' ' + this.user?.name;
        } else {
            return null;
        }
    }
}
