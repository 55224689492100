import React, {useEffect, useRef} from 'react';
import {component} from 'front-components';
import cx from 'classnames';

interface IBackgroundProps {
    className?: string;
    isContentPage: boolean;
    fullHeaderRef: React.RefObject<HTMLDivElement>;
}
export const FadeInElement: React.FC<React.PropsWithChildren<IBackgroundProps>> = ({
    children,
    className = '',
    fullHeaderRef,
    isContentPage,
}) => {
    const classNames = cx(component('fade-in-element')(), className);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const parentElement = ref.current;
        const background = parentElement.firstElementChild as HTMLDivElement;
        const setOpacity = (opacity: number) => {
            parentElement.style.opacity = `${opacity}`;
            background.style.opacity = `${opacity}`;
            if (opacity > 0) {
                parentElement.classList.add('clickable-element');
            } else {
                parentElement.classList.remove('clickable-element');
            }
        };

        if (isContentPage) {
            setOpacity(1);
            return () => setOpacity(0);
        }

        const headerHeight = fullHeaderRef.current?.getBoundingClientRect()?.height;
        const onScroll = () => {
            const scrollPosition = window.scrollY;
            const opacity = 1 - ((headerHeight - scrollPosition) * 2) / headerHeight;
            setOpacity(Math.max(0, Math.min(1, opacity)));
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [isContentPage, fullHeaderRef]);

    return (
        <div className={classNames} ref={ref}>
            <div className={component('fade-in-element', 'background')()} />
            {children}
        </div>
    );
};
