import {BaseApiService} from './base/BaseApiService';

export interface IEventCityFilter {
    id: number;
    title: string;
    alias: string;
    type: 'country' | 'city';
}

export interface IEventPriceFilter {
    id: string;
    title: string;
    sortOrder: number;
    min: number;
    max: number;
}

export interface IEventFilters {
    cityFilters: IEventCityFilter[];
    priceFilters: IEventPriceFilter[];
}

export const getLocation = ({
    id,
    type,
}: Pick<IEventCityFilter, 'id' | 'type'>): {cityId: number} | {countryId: number} =>
    type === 'city' ? {cityId: id} : {countryId: id};

export class DictionariesApiService extends BaseApiService {
    constructor() {
        super('dictionaries');
    }

    async getEventFilters(): Promise<IEventFilters> {
        const {data}: {data: {cities: IEventCityFilter[]; prices: IEventPriceFilter[]}} = await this.get(
            null,
            '/event-filters',
        );
        return {priceFilters: data.prices, cityFilters: data.cities};
    }

    async getCityFiltersByName(params: {countryId?: number; name: string}): Promise<IEventCityFilter[]> {
        const {data}: {data: {cities: IEventCityFilter[]}} = await this.get(
            {countryId: 3159, ...params},
            '/filters-cities',
        );
        return data.cities;
    }

    async getCityFiltersByTagName(params: {countryId?: number; tagName: string}): Promise<IEventCityFilter[]> {
        const {data}: {data: {cities: IEventCityFilter[]}} = await this.get(
            {countryId: 3159, ...params},
            '/filters-cities-by-tag',
        );
        return data.cities;
    }
}
