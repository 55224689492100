import {observable, flow, action, runInAction} from 'mobx';
import {diContainer} from 'timepad-di';

import {IEvent} from 'interfaces/models';
import {
    EventsApiService,
    FavoritesApiService,
    IEventsResponse,
    IFavoriteOrgsEventsParams,
    IPagingRequest,
    OrdersApiService,
} from 'services/Api';
import {EventEntity} from './EventEntity';

export class EventsListStore {
    @observable myEventsList: EventEntity[] = [];
    @observable myEventsOffset: IPagingRequest['offset'] = null;
    @observable myEventsCount = 0;
    @observable isMyEventsListLoading = false;

    @observable myPastEventsList: EventEntity[] = [];
    @observable myPastEventsOffset: IPagingRequest['offset'] = null;
    @observable myPastEventsCount = 0;
    @observable isMyPastEventsListLoading = false;

    readonly myEventsLimit = 6;

    @observable recommendedEventsList: EventEntity[] = [];
    @observable recommendedEventsCount = 0;
    @observable recommendedEventsOffset: IPagingRequest['offset'] = null;
    @observable isRecommendedEventsLoading = false;
    @observable isRecommendedEventsLoadingMore = false;

    @observable favoriteEventsList: EventEntity[] = [];
    @observable favoritesEventsCount = 0;
    @observable isFavoriteEventsLoading = false;
    @observable isFavoriteEventsLoadingMore = false;
    @observable favoriteEventsOffset: IPagingRequest['offset'] = null;

    @observable orgEventsList: EventEntity[] = [];
    @observable orgEventsCount = 0;
    @observable isOrgEventsLoading = false;
    @observable isOrgEventsLoadingMore = false;
    @observable orgEventsOffset: IPagingRequest['offset'] = null;

    @observable seeAlsoEventsList: EventEntity[] = [];

    @observable favoriteOrgsUpcomingEventsList: EventEntity[] = [];
    @observable isFavoriteOrgsUpcomingEventsLoading = false;

    @observable favoriteOrgsEventsList: EventEntity[] = [];
    @observable favoriteOrgsEventsCount = 0;
    @observable isFavoriteOrgsEventsLoading = false;
    @observable isFavoriteOrgsEventsLoadingMore = false;
    @observable favoriteOrgsEventsOffset: IPagingRequest['offset'] = null;

    @observable error: Error = null;

    private readonly apiService: EventsApiService;

    private readonly favoritesApiService: FavoritesApiService;

    private readonly ordersApiService: OrdersApiService;

    constructor() {
        this.apiService = diContainer.get(EventsApiService);
        this.favoritesApiService = diContainer.get(FavoritesApiService);
        this.ordersApiService = diContainer.get(OrdersApiService);
    }

    createModels(data: IEvent[]): EventEntity[] {
        return data.map((v) => new EventEntity(v));
    }

    @action.bound
    resetMyEvents(): void {
        this.myEventsList = [];
        this.myEventsCount = 0;
        this.myPastEventsList = [];
        this.myPastEventsCount = 0;
        this.favoriteEventsList = [];
        this.favoritesEventsCount = 0;
    }

    @action.bound
    getRecommendedEventsList = flow(function*(this: EventsListStore, params?: IPagingRequest, append = false) {
        this.toggleRecommendedEventsLoading(append, true);
        this.error = null;

        try {
            const {total, events, offset}: IEventsResponse = yield this.apiService.getEvents({...params, limit: 24});

            if (!append) {
                this.recommendedEventsCount = total;
            }
            this.recommendedEventsOffset = offset;
            this.recommendedEventsList = append
                ? [...this.recommendedEventsList, ...this.createModels(events)]
                : this.createModels(events);
        } catch (err) {
            this.error = err;
        } finally {
            this.toggleRecommendedEventsLoading(append, false);
        }
    });

    @action.bound
    getMyEventsList = flow(function*(this: EventsListStore, paramOffset?: IPagingRequest['offset'], append = false) {
        this.isMyEventsListLoading = true;
        this.error = null;

        try {
            const {total, events, offset}: IEventsResponse = yield this.ordersApiService.getMyEventList({
                upcoming: 1,
                offset: paramOffset,
                limit: this.myEventsLimit,
            });

            if (!append) {
                this.myEventsCount = total;
            }
            this.myEventsOffset = offset;
            this.myEventsList = append
                ? [...this.myEventsList, ...this.createModels(events)]
                : this.createModels(events);
        } catch (err) {
            this.error = err;
        } finally {
            this.isMyEventsListLoading = false;
        }
    });

    @action.bound
    getMyPastEventsList = flow(function*(
        this: EventsListStore,
        paramOffset?: IPagingRequest['offset'],
        append = false,
    ) {
        this.isMyPastEventsListLoading = true;
        this.error = null;

        try {
            const {total, events, offset}: IEventsResponse = yield this.ordersApiService.getMyEventList({
                passed: 1,
                offset: paramOffset,
                limit: this.myEventsLimit,
            });

            if (!append) {
                this.myPastEventsCount = total;
            }
            this.myPastEventsOffset = offset;
            this.myPastEventsList = append
                ? [...this.myPastEventsList, ...this.createModels(events)]
                : this.createModels(events);
        } catch (err) {
            this.error = err;
        } finally {
            this.isMyPastEventsListLoading = false;
        }
    });

    @action.bound
    async getFavoriteEventsList(params?: IPagingRequest, append = false): Promise<void> {
        this.toggleFavoriteEventsLoading(append, true);
        this.error = null;

        try {
            const {events, total, offset} = await this.favoritesApiService.getFavoriteEvents(params);

            runInAction(() => {
                if (!append) {
                    this.favoritesEventsCount = total;
                }
                this.favoriteEventsOffset = offset;
                this.favoriteEventsList = append
                    ? [...this.favoriteEventsList, ...this.createModels(events)]
                    : this.createModels(events);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            this.toggleFavoriteEventsLoading(append, false);
        }
    }

    @action.bound
    fetchSeeAlso = flow(function*(this: EventsListStore, id: number) {
        this.error = null;

        try {
            const {events}: IEventsResponse = yield this.apiService.getSeeAlsoEvents(id);
            this.seeAlsoEventsList = this.createModels(events);
        } catch (err) {
            if (err?.status === 307 && err?.data?.location) {
                return window.location.replace(err.data.location);
            }
            this.error = err;
        }
    });

    @action.bound
    getOrgEventsList = flow(function*(
        this: EventsListStore,
        orgId: number,
        paramOffset?: IPagingRequest['offset'],
        append = false,
    ) {
        this.toggleOrgEventsLoading(append, true);
        this.error = null;

        try {
            const {total, events, offset}: IEventsResponse = yield this.apiService.getEvents({
                offset: paramOffset,
                orgIds: [orgId],
                limit: 24,
            });

            if (!append) {
                this.orgEventsCount = total;
            }
            this.orgEventsOffset = offset;
            this.orgEventsList = append
                ? [...this.orgEventsList, ...this.createModels(events)]
                : this.createModels(events);
        } catch (err) {
            this.error = err;
        } finally {
            this.toggleOrgEventsLoading(append, false);
        }
    });

    @action.bound
    getFavoriteOrgsEventsList = flow(function*(
        this: EventsListStore,
        params: IFavoriteOrgsEventsParams,
        append = false,
    ) {
        this.toggleFavoriteOrgsEventsLoading(append, true);
        this.error = null;

        try {
            const {events, total, offset}: IEventsResponse = yield this.favoritesApiService.getFavoriteOrgsEvents(
                params,
            );

            if (!append) {
                this.favoriteOrgsEventsCount = total;
            }
            this.favoriteOrgsEventsOffset = offset;
            this.favoriteOrgsEventsList = append
                ? [...this.favoriteOrgsEventsList, ...this.createModels(events)]
                : this.createModels(events);
        } catch (err) {
            this.error = err;
        } finally {
            this.toggleFavoriteOrgsEventsLoading(append, false);
        }
    });

    @action.bound
    getFavoriteOrgsUpcomingEventsList = flow(function*(this: EventsListStore) {
        this.error = null;
        this.isFavoriteOrgsUpcomingEventsLoading = true;

        try {
            const {events}: IEventsResponse = yield this.favoritesApiService.getFavoriteOrgsEvents({
                upcoming: 1,
            });
            this.favoriteOrgsUpcomingEventsList = this.createModels(events);
        } catch (err) {
            this.error = err;
        } finally {
            runInAction(() => {
                this.isFavoriteOrgsUpcomingEventsLoading = false;
            });
        }
    });

    @action.bound
    private toggleFavoriteEventsLoading(append: boolean, state: boolean) {
        if (append) {
            this.isFavoriteEventsLoadingMore = state;
        } else {
            this.isFavoriteEventsLoading = state;
        }
    }

    @action.bound
    private toggleRecommendedEventsLoading(append: boolean, state: boolean) {
        if (append) {
            this.isRecommendedEventsLoadingMore = state;
        } else {
            this.isRecommendedEventsLoading = state;
        }
    }

    @action.bound
    private toggleOrgEventsLoading(append: boolean, state: boolean) {
        if (append) {
            this.isOrgEventsLoadingMore = state;
        } else {
            this.isOrgEventsLoading = state;
        }
    }

    @action.bound
    private toggleFavoriteOrgsEventsLoading(append: boolean, state: boolean) {
        if (append) {
            this.isFavoriteOrgsEventsLoadingMore = state;
        } else {
            this.isFavoriteOrgsEventsLoading = state;
        }
    }
}
