import {action, computed, observable, runInAction} from 'mobx';
import {diContainer} from 'timepad-di';

import {CategoriesApiService} from 'services/Api';
import {ICategory} from 'interfaces/models';

export class CategoriesStore {
    @observable isLoading = false;

    @observable loadingEvents = false;

    @observable error: Error = null;

    @observable categories: ICategory[] = [];

    private readonly apiService: CategoriesApiService;

    constructor() {
        this.apiService = diContainer.get(CategoriesApiService);

        this.getCategories();
    }

    @action.bound
    async getCategories(this: CategoriesStore): Promise<void> {
        this.isLoading = true;
        this.categories = [];
        this.error = null;

        try {
            const categories = await this.apiService.getCategories();
            runInAction(() => {
                this.categories = categories;
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @computed
    get categoriesForFeedPage(): ICategory[] {
        return this.categories.slice(0, 9);
    }
}
