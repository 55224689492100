import React, {FC, useEffect, useState} from 'react';
import {Button, Typography, Brick, layout} from 'front-components';
import {component} from 'front-components/src/services/helpers/classHelpers';
import Cookies from 'js-cookie';

const CookieConsentText = () => (
    <>
        На нашем сайте используются cookie–файлы, в том числе сервис веб–аналитики Яндекс.Метрика.
        <b> Используя сайт, вы соглашаетесь на обработку персональных данных при помощи cookie–файлов</b>. Подробнее об
        обработке персональных данных вы можете узнать в{' '}
        <a href="http://timepad.ru/upload/docs/TimePad_PD_Polices.pdf" target="_blank" rel="noreferrer">
            Политике конфиденциальности
        </a>
        .
    </>
);

const cookieName = 'gdprConsentCookieAccepted';
const domainNameExtractor = (hostname: string): string =>
    hostname
        .split('.')
        .slice(-2)
        .join('.');

export const CookieAccept: FC = () => {
    const cookieAccepted = Cookies.get(cookieName);
    if (cookieAccepted) return null;

    return <CookieAcceptPanel />;
};

const CookieAcceptPanel: FC = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => setShow(true), 1000);
    }, []);

    const CoockieAcceptHandle = () => {
        setShow(false);
        const date = new Date(),
            currentDomain = document.location.hostname,
            domain = currentDomain !== 'localhost' && '.' + domainNameExtractor(currentDomain);

        date.setFullYear(date.getFullYear() + 1);
        Cookies.set(cookieName, '1', {expires: date, domain});
    };

    return (
        <div
            className={component('cookie-consent')({
                open: show,
            })}
        >
            <div className={layout('page')()}>
                <Typography.Small>
                    <CookieConsentText />
                </Typography.Small>
                <Button onClick={CoockieAcceptHandle}>Хорошо</Button>
                <Brick />
            </div>
        </div>
    );
};
