import {IEventsResponse} from './EventsApiService';
import {BaseApiService, IResponseBase, IPagingRequest} from './base/BaseApiService';

export interface IOrdersParams extends IPagingRequest {
    upcoming?: number;
    passed?: number;
}

export class OrdersApiService extends BaseApiService {
    constructor() {
        super('orders');
    }

    async getMyEventList(params: IOrdersParams): Promise<IEventsResponse> {
        const {data}: {data: IResponseBase<IEventsResponse>} = await this.get(params);
        return data.data;
    }
}
