import React from 'react';
import {component, Row} from 'front-components';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {ICategory, RecommendedBlockQaAttrs} from 'interfaces/models';
import {FilterInput} from '../../common/FilterInput';
import cx from 'classnames';

interface ICategoryFilterProps {
    onCategoryClickHandler: (category: ICategory) => void;
    onResetCategoryClickHandler: () => void;
    categories: ICategory[];
    category: ICategory;
}
export const CategoryFilter: React.FC<ICategoryFilterProps> = ({
    onCategoryClickHandler,
    onResetCategoryClickHandler,
    categories,
    category,
}) => {
    return (
        <FilterInput
            label="Категория"
            value={category?.title}
            onReset={onResetCategoryClickHandler}
            data-analytics={DataAnalytics.clickDropdownThemes}
            data-qa={RecommendedBlockQaAttrs.FilterTheme}
        >
            {(close) => (
                <div className={cx(component('theme--darkpic-bg')(), component('theme--darkpic')())}>
                    {categories?.map((category: ICategory) => (
                        <Row
                            data-analytics={DataAnalytics.userChoseTopic}
                            key={category.id}
                            onClick={() => {
                                close();
                                onCategoryClickHandler(category);
                            }}
                            hoverable
                        >
                            <Row.Body>
                                <Row.Text>{category.title}</Row.Text>
                            </Row.Body>
                        </Row>
                    ))}
                </div>
            )}
        </FilterInput>
    );
};
