import {useEffect} from 'react';
import {vkPixelToCookie} from 'services/Helpers/AnalyticsHelper/vkPixel';

export const VKPixelComponent = (): null => {
    // вызываем один раз при переходе по ссылке на сайт
    useEffect(() => {
        vkPixelToCookie();
    }, []);

    return null;
};
