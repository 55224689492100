import Transport from 'winston-transport';
import {BaseApiService} from '../Api/base/BaseApiService';
import {getSessionId} from '../Helpers/SessionHelper';
import {formatMessage} from './logger';

export class BackendTransport extends Transport {
    // Emergency, Alert, Critical, Error, Warning, Notice, Info, Debug
    private readonly levelDictionary = {
        error: 3,
        warn: 4,
        info: 6,
        verbose: 7,
        debug: 7,
        silly: 7,
    };

    private baseApiService: BaseApiService;

    constructor(opts) {
        super(opts);

        this.baseApiService = new BaseApiService('logger');
    }

    log(info, callback) {
        const level = this.levelDictionary[info.level] || this.levelDictionary.error;
        const message = formatMessage(info.message, info.level);

        //TODO нужно отправлять логи не через API-сервис а прямее и безоотказнее
        this.baseApiService
            .post({
                message,
                level,
                sessionId: getSessionId(),
            })
            .catch();

        setImmediate(() => {
            this.emit('logged', info);
        });
        callback();
    }
}
