import React from 'react';
import {matchPath} from 'react-router';
import {Brick, ButtonVariant, Button, Logo, layout, component} from 'front-components';
import Sentry, {isSentrySetup} from 'init-sentry';
import cx from 'classnames';

import {TPRoutes} from 'routing/config';
import {Content} from './DefaultContent';

//действие, которое может сделать пользователь, столкнувшийся с ошибкой
interface IAction {
    caption: string; //текст на кнопке
    callback: () => void; //действие
}

interface IProps {}
interface IErrorBoundaryState {
    hasError: boolean;
}

const NERP_IMG = '/static/images/nerp.jpg';

const DEFAULT_PAGE_TITLE = 'Что-то сломалось.';

const DEFAULT_ACTION: IAction = {
    caption: 'Перезагрузить страницу',
    callback: () => window.location.reload(),
};

export class ErrorBoundary extends React.Component<React.PropsWithChildren<IProps>, IErrorBoundaryState> {
    constructor(props: IProps) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        if (isSentrySetup) {
            Sentry.withScope((scope) => {
                scope.setExtras((errorInfo as unknown) as Record<string, unknown>);
                Sentry.captureException(error);
            });
        }
    }

    render() {
        const {hasError} = this.state;
        let action: IAction = DEFAULT_ACTION;

        if (hasError) {
            this.setTitle();
            const eventPage = matchPath(TPRoutes.EVENT, window.location.pathname);
            if (eventPage) {
                action = {
                    caption: 'Открыть старую страницу события',
                    callback: () => {
                        window.location.href = process.env.TIMEPAD_EVENT_URL_TEMPLATE.replace(
                            ':id',
                            eventPage.params.id,
                        );
                    },
                };
            }
        }

        return hasError ? (
            <div
                className={cx(layout('page')(), layout('flex')({'align-centered': true}), component('errorboundary')())}
            >
                <div>
                    <div className={layout('page')()}>
                        <a className={component('errorboundary', 'logo')()} href={TPRoutes.FEED}>
                            <Logo />
                        </a>
                    </div>
                    <div className={layout('flex')({'y-axis': true})}>
                        <div className={layout('modules')()}>
                            <div className={layout('modules', '9')()}>
                                <Content />
                            </div>
                        </div>
                        <div className={layout('modules')()}>
                            <div className={layout('modules', '9')()}>
                                <div className={layout('flex')({'y-axis': true})}>
                                    <div>
                                        <Button
                                            variant={ButtonVariant.primary}
                                            label={action.caption}
                                            onClick={action.callback}
                                            large
                                        />
                                    </div>
                                    <Brick size={1} />
                                </div>
                            </div>
                        </div>
                        <div className={layout('modules')()}>
                            <div className={cx(layout('modules', '4')(), 'nerp', 'nerp0')}>
                                <img src={NERP_IMG} width="100%" />
                            </div>
                            <div className={cx(layout('modules', '4')(), 'nerp', 'nerp1')}>
                                <img src={NERP_IMG} width="100%" />
                            </div>
                            <div className={cx(layout('modules', '4')(), 'nerp', 'nerp2')}>
                                <img src={NERP_IMG} width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            this.props.children
        );
    }

    private setTitle() {
        document.title = DEFAULT_PAGE_TITLE;
    }
}
