import {useCallback} from 'react';
import {useStores} from './stores';

export const useAuth = ({eventPage = false}: {eventPage?: boolean} = {}): (() => Promise<boolean>) => {
    const {modalStorage} = useStores();

    return useCallback(async () => {
        const result = await modalStorage.modals.AUTH_MODAL.open<boolean>({eventPage});

        if (result.result) {
            modalStorage.modals.AUTH_MODAL.close();
            return true;
        }

        return false;
    }, [eventPage, modalStorage.modals.AUTH_MODAL]);
};
