import {action, observable, runInAction} from 'mobx';
import {diContainer} from 'timepad-di';

import {ContentApiService} from 'services/Api';
import {IContentListResponse} from 'services/Api/ContentApiService';
import {sanitizeVideoData} from 'services/Helpers/VideoHelper';
import {IVideoResponse} from 'interfaces/models';
import {VideoEntity} from '../Video';

export class SubscribeStore {
    @observable subscriptionsVideos: VideoEntity[] = [];
    @observable subscriptionsVideosIsLoading = false;
    @observable subscriptionsVideosTotalCount = 0;

    @observable error?: Error = null;

    private readonly contentApiService: ContentApiService;

    constructor() {
        this.contentApiService = diContainer.get(ContentApiService);
    }

    createVideoModels(data: IVideoResponse[]): VideoEntity[] {
        return data.map((v) => new VideoEntity(sanitizeVideoData(v)));
    }

    @action.bound
    getVideoSubscriptions = async (params?: {limit: number; offset: number}, append = false) => {
        this.subscriptionsVideosIsLoading = true;
        this.error = null;

        try {
            const data: IContentListResponse = await this.contentApiService.getSubscriptionsVideoList(params);

            runInAction(() => {
                this.subscriptionsVideosTotalCount = data['total_count'];

                this.subscriptionsVideos = append
                    ? [...this.subscriptionsVideos, ...this.createVideoModels(data.list)]
                    : this.createVideoModels(data.list);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.subscriptionsVideosIsLoading = false;
            });
        }
    };
}
