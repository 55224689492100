import React, {PropsWithChildren} from 'react';
import {RequireOnlyOne} from 'interfaces/misc';
import {component} from 'front-components';

export interface IThemeProps {
    light: boolean;
    dark: boolean;
    default: boolean;
}

export function Theme({light, dark, children}: PropsWithChildren<RequireOnlyOne<IThemeProps>>) {
    const classes = component('theme')({lightpic: light, darkpic: dark});
    return <div className={classes}>{children}</div>;
}
