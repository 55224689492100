import {observable, flow, action, reaction, computed, runInAction, comparer} from 'mobx';
import {diContainer} from 'timepad-di';
import groupBy from 'lodash.groupby';

import {sendGoals} from 'services/Helpers/AnalyticsHelper';
import {getOrderInfo, IOrderInfo} from 'services/Helpers/OrderHelper';
import {logger} from 'services/Logger';
import {EventsApiService, TrackApiService, FavoritesApiService} from 'services/Api';
import {EventGoals, IEventCheckout, IEvent, IOrganizationAnalyticServices} from 'interfaces/models';
import {EventEntity} from './EventEntity';
import {EventsListStore} from './EventsListStore';
import {SessionEntity} from './SessionEntity';
import {Ticket} from '../Ticket';

export class EventStore {
    @observable currentEvent: EventEntity = null;

    @observable eventCheckout: IEventCheckout = null;

    @observable isLoading = false;

    @observable error: Error = null;

    @observable ticket: Ticket;

    @observable currentSession: SessionEntity = null;

    private readonly eventsListStorage: EventsListStore;

    private readonly apiService: EventsApiService;

    private readonly trackApiService: TrackApiService;

    private readonly favoritesApiService: FavoritesApiService;

    constructor() {
        this.eventsListStorage = diContainer.get(EventsListStore);

        this.apiService = diContainer.get(EventsApiService);
        this.trackApiService = diContainer.get(TrackApiService);
        this.favoritesApiService = diContainer.get(FavoritesApiService);

        // extend classes

        this.ticket = new Ticket();
        reaction(
            () => this.currentEvent,
            () => {
                this.ticket.code = null;
                this.ticket.policies = null;
                this.ticket.wipeOrder();
                this.ticket.tickets = [];
                this.clearCurrentSession();

                if (this.currentEvent) {
                    if (!this.currentEvent.isRepeated) {
                        this.ticket.getEventTickets(this.currentEvent.id);
                    }

                    this.eventCheckout = {
                        ofertaLink: this.currentEvent.ofertaLink,
                        personalDataPolicyLink: this.currentEvent.organization.personalDataPolicyLink,
                        googleAnalyticsId: this.currentEvent.googleAnalyticsId,
                        categories: this.currentEvent.categories,
                        id: this.currentEvent.id,
                        startDate: this.currentEvent.computed.startDate,
                        endDate: this.currentEvent.computed.endDate,
                    };
                }
            },
        );

        // ticket
        reaction(
            () => this.ticket.discountApplied,
            () => {
                if (!this.ticket.discountApplied) {
                    this.ticket.orderTickets.forEach((orderTicket) => {
                        if (orderTicket.isPromocodeTicket) {
                            orderTicket.setCount(0);
                        }
                    });
                }
            },
        );

        // discount
        reaction(
            () => this.selectedOrderInfo,
            () => {
                if (this.ticket.hasPolicyDiscount || this.ticket.hasPolicyCode) {
                    this.ticket.refreshCurrentPolicies();
                }
            },
            {
                equals: (newValue: IOrderInfo, oldValue: IOrderInfo) => {
                    return comparer.shallow(newValue, oldValue);
                },
            },
        );

        // session
        reaction(
            () => this.currentSession,
            () => {
                if (this.currentSession) {
                    this.ticket.wipeOrder();
                    const {startDate, endDate, id} = this.currentSession;
                    this.ticket.getEventTickets(id);
                    this.eventCheckout = {
                        ...this.eventCheckout,
                        startDate,
                        endDate,
                        id,
                    };
                }
            },
        );
    }

    createModels(data: IEvent[]): EventEntity[] {
        return data.map((v) => new EventEntity(v));
    }

    @action.bound
    async getEvent(id: number): Promise<EventEntity> {
        this.isLoading = true;
        this.error = null;
        this.clearCurrentEvent();
        this.clearCurrentSession();

        try {
            const event: IEvent = await this.apiService.getEvent(id);
            runInAction(() => {
                this.currentEvent = new EventEntity(event);
            });
            return this.currentEvent;
        } catch (err) {
            if (err?.status === 307 && err?.data?.location) {
                window.location.replace(err.data.location);
            } else {
                runInAction(() => {
                    this.error = err;
                });
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action.bound
    sendEventHit = flow(function*(this: EventStore, id: number) {
        try {
            yield this.trackApiService.sendEventHit(id);
        } catch (err) {
            logger.error(err); // TODO: подумать, как это лучше хендлить. на проде это не нужно
        }
    });

    @action.bound
    async addEventToFavorites(event: EventEntity): Promise<void> {
        try {
            const result = await this.favoritesApiService.addEventToFavorites(event.id);
            if (result === 'ok') {
                await this.eventsListStorage.getFavoriteEventsList();
                event.setIsFavorite(true);
            }
        } catch (error) {
            logger.error(error);
        }
    }

    @action.bound
    async removeEventFromFavorites(event: EventEntity): Promise<void> {
        try {
            const result = await this.favoritesApiService.removeEventFromFavorites(event.id);
            if (result === 'ok') {
                await this.eventsListStorage.getFavoriteEventsList();
                event.setIsFavorite(false);
            }
        } catch (error) {
            logger.error(error);
        }
    }

    @computed
    get currentEventHasOrders(): boolean {
        return !!this.currentEvent?.sessions?.some((session) => !!session?.orders?.length);
    }

    @computed
    get eventWebinarLink(): string {
        if (this.currentEventHasOrders) {
            const sessionWithOrders = this.currentEvent.sessions.find((session) => !!session.orders.length);
            return sessionWithOrders.orders[0].tickets.find((ticket) => !!ticket.webinarLink)?.webinarLink;
        }
    }

    @action.bound
    applyCode(code: string): Promise<void> {
        return this.ticket.getDiscountPolicies(this.eventCheckout.id, code);
    }

    @computed
    get orgAnalytics(): IOrganizationAnalyticServices {
        return this.currentEvent?.organization?.analytics || ({} as IOrganizationAnalyticServices);
    }

    @computed
    get appliedCode(): string {
        return (this.ticket.policies?.applied_promocodes?.length && this.ticket.policies.applied_promocodes[0]) || null;
    }

    @computed
    get selectedOrderInfo(): IOrderInfo {
        const info = getOrderInfo(this.ticket.orderTickets);
        const totalDiscount = this.ticket.policies?.pricing?.total;
        const orderDiscount = this.ticket.policies?.pricing?.order_discount;

        // условие для ситуации если один тип билета и выбран 1 билет
        if (info.totalNum <= 1 && this.ticket.tickets?.length === 1) {
            if (this.currentSession) {
                info.discountedSum = this.currentSession.minPrice;
            } else {
                info.discountedSum = this.currentEvent?.computed.minPrice;
            }
        }

        if (this.isLoading) {
            if (orderDiscount) {
                info.discountedSum -= orderDiscount;
            }
        } else if (totalDiscount >= 0) {
            // >= фикс для 100% скидки
            info.discountedSum = totalDiscount;
        }
        info.discountedSum = Math.round(info.discountedSum * 100) / 100;
        info.totalSum = Math.round(info.totalSum * 100) / 100;
        return info;
    }

    @computed
    get serviceFee(): number {
        const discountedSum = this.selectedOrderInfo.discountedSum;
        return Math.round(discountedSum * this.ticket.ratioFee * 100) / 100;
    }

    @action.bound
    notifyTrackers(goal: EventGoals, value?: unknown): void {
        const organizationServices = this.orgAnalytics;
        sendGoals(organizationServices, goal);
        if (process.env.ANALYTICS_ENABLE) {
            sendGoals(
                {
                    googleAnalytics: process.env.GOOGLE_ANALYTICS_4,
                    yandexMetric: process.env.YANDEX_METRIKA_COUNTER,
                },
                goal,
                value,
            );
        }
    }

    @action.bound
    setCurrentEvent(event: EventEntity): void {
        this.currentEvent = event;
    }

    @action.bound
    clearCurrentEvent(): void {
        this.ticket.wipeOrder();
        this.currentEvent = null;
    }

    // Работа с сессиями события
    @action.bound
    selectSession(sessionId: number): void {
        this.currentSession = this.currentEvent?.sessions.find((session) => session.id === sessionId);
    }

    @action.bound
    clearCurrentSession(): void {
        this.ticket.wipeOrder();
        this.currentSession = null;
    }

    @computed
    get groupedByDateSessions(): {[index: string]: SessionEntity[]} {
        // не отображаем сеансы, если регистрация закрыта
        const openedSessions = this.currentEvent?.sessions?.filter((session) => session?.isRegistrationOpened);
        return groupBy(openedSessions, (el) => el.start.format('YYYY-MM-DD'));
    }

    @computed
    get isRegistrationOpened(): boolean {
        return this.currentEvent?.sessions?.some((session) => session.isRegistrationOpened);
    }

    @computed
    get lowestSessionPrice(): number {
        return this.currentEvent?.sessions?.length
            ? Math.min(...this.currentEvent.sessions.map((session) => session.minPrice))
            : null;
    }
}
