import {IOrderTicket} from './Ticket';

export enum OrderStatus {
    inactive = 'inactive',
    ok = 'ok',
    deleted = 'deleted',
    paid = 'paid',
    notpaid = 'notpaid',
    notpaidDelay = 'notpaid_delay',
    free = 'free',
    returned = 'returned',
    booked = 'booked',
    pending = 'pending',
    rejected = 'rejected',
    bookedOffline = 'booked_offline',
    paidOffline = 'paid_offline',
    returnOrg = 'return_org',
    returnTp = 'return_tp',
    paidUr = 'paid_ur',
    returnPaymentRequest = 'return_payment_request',
}

export interface IOrder {
    createdAt: string;
    id: number;
    orderLink: string;
    paymentLink: string;
    resendTicketsAvailable: boolean;
    tickets: IOrderTicket[];
    total: number;
}

export const isReturned = (status: OrderStatus): boolean => {
    return [OrderStatus.inactive, OrderStatus.returned, OrderStatus.returnOrg, OrderStatus.returnTp].includes(status);
};

export const isBooked = (status: OrderStatus): boolean => {
    return [OrderStatus.booked, OrderStatus.bookedOffline].includes(status);
};
