import React from 'react';
import {Route} from 'react-router';
import {RouteProps} from 'react-router/dist/lib/components';

type IMulitRoutesParams = {
    paths: RouteProps['path'][];
    path?: never;
} & RouteProps;

export const renderMultiRoutes = ({element: Element, paths, ...rest}: IMulitRoutesParams): React.ReactElement[] =>
    paths.map((path) => <Route key={path} path={path} {...rest} element={Element} />);
