import {ICategory} from 'interfaces/models';
import {BaseApiService, IResponseBase} from './base/BaseApiService';

export class CategoriesApiService extends BaseApiService {
    constructor() {
        super('categories');
    }

    async getCategories(): Promise<ICategory[]> {
        const {data}: {data: IResponseBase<ICategory[]>} = await this.get({});
        return data.data as ICategory[];
    }
}
