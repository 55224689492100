import moment from 'moment';
import {useState, useEffect} from 'react';

export function useTimer(interval: number) {
    const [now, setNow] = useState(moment());

    useEffect(() => {
        const timer = window.setInterval(() => setNow(moment()), interval);

        return () => clearInterval(timer);
    }, [interval]);

    return now;
}
