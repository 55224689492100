import React, {useEffect, useRef} from 'react';
import {component} from 'front-components';
import {observer} from 'mobx-react';
import {useContainer} from 'timepad-di';

import {TPRoutes} from 'routing/config';
import {ShortLogo} from './ShortLogo';
import {ProfileButton} from './ProfileButton';
import {BurgerMenu} from './BurgerMenu';
import {FadeInElement} from './FadeInElement';
import {AnimationHeaderBarStore} from '../AnimationHeaderBarStore';
import {RichLink} from '../../utility/RichLink';

interface IMiniHeaderBarProps {
    mediaPoint: number;
    isAuthorized: boolean;
    onAuthClick: () => void;
    isEventPage: boolean;
    isContentPage: boolean;
    fullHeaderRef: React.RefObject<HTMLDivElement>;
}
export const MiniHeaderBar: React.FC<IMiniHeaderBarProps> = observer(
    ({isAuthorized, mediaPoint, onAuthClick, isEventPage, isContentPage, fullHeaderRef}) => {
        const [{isMiniHeaderBarHidden}] = useContainer(AnimationHeaderBarStore);
        const miniHeaderbarRef = useRef<HTMLDivElement>();
        const isDesktop = mediaPoint > 1;

        useEffect(() => {
            const miniHeaderbar = miniHeaderbarRef.current;
            const hiddenClassName = 'hidden-mini-headerbar';
            if (isMiniHeaderBarHidden) {
                miniHeaderbar.classList.add(hiddenClassName);
            } else {
                miniHeaderbar.classList.remove(hiddenClassName);
            }
            return () => miniHeaderbar.classList.remove(hiddenClassName);
        }, [isMiniHeaderBarHidden]);

        const desktopProfileBtn = isDesktop ? (
            <div className={component('mini-headerbar', 'profile-button')()}>
                <ProfileButton mediaPoint={mediaPoint} isEventPage={isEventPage} />
            </div>
        ) : (
            <FadeInElement fullHeaderRef={fullHeaderRef} isContentPage={isContentPage}>
                <ProfileButton mediaPoint={mediaPoint} isEventPage={isEventPage} />
            </FadeInElement>
        );

        return (
            <div
                className={component('mini-headerbar')({
                    [`media-point-${mediaPoint}`]: true,
                })}
                ref={miniHeaderbarRef}
            >
                <FadeInElement fullHeaderRef={fullHeaderRef} isContentPage={isContentPage}>
                    <RichLink to={TPRoutes.FEED} className={component('header-logo', 'short-logo')()}>
                        <ShortLogo />
                    </RichLink>
                </FadeInElement>

                {isAuthorized ? (
                    desktopProfileBtn
                ) : (
                    <FadeInElement fullHeaderRef={fullHeaderRef} isContentPage={isContentPage}>
                        <BurgerMenu onAuthClick={onAuthClick} />
                    </FadeInElement>
                )}
            </div>
        );
    },
);
