import React, {useRef} from 'react';
import {observer} from 'mobx-react';
import {Button, component, useMedia} from 'front-components';

import {RecommendedBlockQaAttrs} from 'interfaces/models';
import {CategoryFilter, CityFilter, DateFilter, PriceFilter} from './components';
import {useFilters} from './useFilters';
import {useFiltersAnimation} from './useFiltersAnimation';

export const Filters: React.FC = observer(() => {
    const filtersRef = useRef<HTMLDivElement>();
    const intersectionRef = useRef<HTMLDivElement>();

    const {isMobilePortraitMax} = useMedia();

    const {
        handleCategoryClick,
        handleResetCategoryClick,
        handleDatePickerChange,
        handleResetDataPickerClick,
        handlePriceClick,
        handleResetPriceClick,
        handleCityClick,
        handleResetFiltersClick,
        filters,
        categories,
        category,
        city,
        price,
        dateRange,
        query,
    } = useFilters();

    // нужно для получения корректной высоты фильтров, т.к. городе приходит не сразу
    const isCityReady = city?.id;

    useFiltersAnimation(filtersRef, intersectionRef, isMobilePortraitMax, !!isCityReady);

    return (
        <>
            <div ref={intersectionRef} />
            <div ref={filtersRef} className={component('filters')()}>
                <div className={component('filters', 'container')()}>
                    <CategoryFilter
                        onCategoryClickHandler={handleCategoryClick}
                        onResetCategoryClickHandler={handleResetCategoryClick}
                        categories={categories}
                        category={category}
                    />
                    <DateFilter
                        onDatePickerChangeHandler={handleDatePickerChange}
                        onResetDataPickerClickHandler={handleResetDataPickerClick}
                        dateRange={dateRange}
                    />
                    <PriceFilter
                        onPriceClickHandler={handlePriceClick}
                        onResetPriceClickHandler={handleResetPriceClick}
                        price={price}
                        priceFilters={filters?.priceFilters}
                    />
                    <CityFilter onCityClickHandler={handleCityClick} city={city} cityFilters={filters?.cityFilters} />
                    {(dateRange || price || category || query) && (
                        <Button
                            variant={Button.variant.transparent}
                            label="Сбросить"
                            onClick={handleResetFiltersClick}
                            data-qa={RecommendedBlockQaAttrs.BtnResetFilters}
                        />
                    )}
                </div>
                {isMobilePortraitMax && (
                    <div className={component('filters', 'container')({hidden: true})}>
                        <CategoryFilter
                            onCategoryClickHandler={handleCategoryClick}
                            onResetCategoryClickHandler={handleResetCategoryClick}
                            categories={categories}
                            category={category}
                        />
                        <DateFilter
                            onDatePickerChangeHandler={handleDatePickerChange}
                            onResetDataPickerClickHandler={handleResetDataPickerClick}
                            dateRange={dateRange}
                        />
                        <PriceFilter
                            onPriceClickHandler={handlePriceClick}
                            onResetPriceClickHandler={handleResetPriceClick}
                            price={price}
                            priceFilters={filters?.priceFilters}
                        />
                        <CityFilter
                            onCityClickHandler={handleCityClick}
                            city={city}
                            cityFilters={filters?.cityFilters}
                        />
                        {(dateRange || price || category || query) && (
                            <Button
                                variant={Button.variant.transparent}
                                label="Сбросить"
                                onClick={handleResetFiltersClick}
                                data-qa={RecommendedBlockQaAttrs.BtnResetFilters}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
});
