import {FieldType, IFormField} from 'interfaces/models/Form';

export type FormValues<V extends string> = {
    [key in V]: string;
};

export enum FieldTag {
    name = 'name',
    surname = 'surname',
    mail = 'mail',
    phone = 'phone',
    'order_mail' = 'order_mail',
}

export interface IFieldConfig {
    label?: string;
    tag?: FieldTag;
    type?: FieldType;
}

export type IFields<V> = {
    [K in keyof V]: IFieldConfig;
};

export type IDynamicFields = {
    [key: string]: IFormField;
};

export interface IFormikCommonProps {
    value: string;
    touched: boolean;
    error: string;
    setValue: (name: string, value: string | string[]) => void;
    setTouched: (name: string, isTouched: boolean) => void;
    setStatus?: (status?: string) => void;
}
