import {EventEntity} from 'stores/Events';
import {action, observable, flow, reaction, computed} from 'mobx';
import {debounce} from 'debounce';
import {diContainer} from 'timepad-di';

import {IFilterDate} from 'interfaces/misc';
import {ICategory, IEvent} from 'interfaces/models';
import {isCategoryOrSearchPage} from 'routing/config';

import {
    DictionariesApiService,
    IEventCityFilter,
    IEventPriceFilter,
    IEventFilters,
    EventsApiService,
    IEventsResponse,
    IEventQueryParams,
    IPagingRequest,
} from 'services/Api';

export class SearchStore {
    @observable loading = true;
    @observable loadingAppend = false;

    @observable showRecommended = false;

    @observable error: Error = null;

    @observable query: string = null;

    @observable city: IEventCityFilter;

    @observable category: ICategory;

    @observable dateRange: IFilterDate;

    @observable price: IEventPriceFilter;

    @observable minPrice: number = null;

    @observable maxPrice: number = null;

    @observable resultEvents: EventEntity[] = [];

    @observable resultEventsCount: number = null;

    @observable resultEventsOffset: IPagingRequest['offset'] = null;

    @observable filters: IEventFilters;

    @observable cityFilters: IEventCityFilter[] = [];

    @observable cities: IEventCityFilter[] = [];

    @observable isCityFiltersLoading = false;

    private readonly eventsApiService: EventsApiService;

    private readonly dictionariesApiService: DictionariesApiService;

    constructor() {
        this.eventsApiService = diContainer.get(EventsApiService);

        this.dictionariesApiService = diContainer.get(DictionariesApiService);
        this.getFilters();

        // region Change filter reactions
        reaction(
            () => this.category,
            () => this.addLoadingBeforeSearch(this.searchEvents),
        );

        reaction(
            () => this.query,
            () => this.searchEvents(),
        );

        reaction(
            () => this.city,
            () => {
                this.searchEvents();
                this.setCities([this.city]);
            },
        );

        reaction(
            () => this.dateRange?.start && this.dateRange?.end,
            () => this.searchEvents(),
        );

        reaction(
            () => this.minPrice || this.maxPrice,
            () => this.searchEvents(),
        );
        // endregion
    }

    createModels(data: IEvent[]): EventEntity[] {
        return data.map((v) => new EventEntity(v));
    }

    addLoadingBeforeSearch(cb: () => void): void {
        this.setLoading(true);
        cb();
    }

    @action.bound
    actualDictionaries = flow(function*(this: SearchStore) {
        try {
            const {cityFilters, priceFilters}: IEventFilters = yield this.dictionariesApiService.getEventFilters();
            this.filters = {
                cityFilters,
                priceFilters,
            };
        } catch (err) {
            this.error = err;
            this.resultEvents = [];
        }
    });

    @action.bound
    getCityFiltersByName = debounce(
        flow(function*(this: SearchStore, name: string) {
            this.setCityFiltersLoading(true);
            try {
                if (name) {
                    const cities: IEventCityFilter[] = yield this.dictionariesApiService.getCityFiltersByName({name});
                    this.cityFilters = cities;
                }
            } catch (err) {
                this.error = err;
            } finally {
                this.setCityFiltersLoading(false);
            }
        }),
        500,
    );

    @action.bound
    getCityFiltersByTagName = flow(function*(tagName: string) {
        try {
            const cities: IEventCityFilter[] = yield this.dictionariesApiService.getCityFiltersByTagName({tagName});
            this.cityFilters = cities;
        } catch (err) {
            this.error = err;
        }
    });

    @action.bound
    searchEvents = debounce(
        flow(function*(this: SearchStore, append = false) {
            if (!isCategoryOrSearchPage(location.pathname)) return;

            this.showRecommended = false;
            this.setLoading(true, append);
            this.error = null;

            try {
                const params: Partial<IEventQueryParams> = {
                    searchQuery: this.query,
                    city: this.city,
                    startDate: this.dateRange?.start.format(),
                    endDate: this.dateRange?.end.format(),
                    minPrice: this.minPrice,
                    maxPrice: this.maxPrice,
                    limit: 24,
                };
                if (this.category?.id) {
                    params['categoryIds'] = [this.category?.id];
                }
                if (append) {
                    params['offset'] = this.resultEventsOffset;
                }
                const {events, total, offset}: IEventsResponse = yield this.eventsApiService.getEvents(params);

                if (!append) {
                    this.resultEventsCount = total;
                }
                this.resultEventsOffset = offset;

                const eventModels = this.createModels(events);
                this.resultEvents = append ? [...this.resultEvents, ...eventModels] : eventModels;
            } catch (err) {
                this.error = err;
                this.resultEvents = [];
            } finally {
                if (this.resultEvents.length === 0) this.showRecommended = true;
                this.setLoading(false, append);
            }
        }),
        500,
    );

    @action.bound
    setQuery(query: string): void {
        this.query = query ? query : null;
    }

    @action.bound
    setCity(city: IEventCityFilter): void {
        this.city = city;
    }

    @action.bound
    setDateRange(range: IFilterDate): void {
        this.dateRange = range;
    }

    @action.bound
    setPrice(price: IEventPriceFilter): void {
        if (price) {
            this.price = price;
            this.minPrice = price.min;
            this.maxPrice = price.max;
        } else {
            this.price = null;
            this.minPrice = null;
            this.maxPrice = null;
        }
    }

    @action.bound
    setCategory(category: ICategory): void {
        this.category = category;
    }

    @action.bound
    setResults(results: EventEntity[]): void {
        this.resultEvents = results;
    }

    @action.bound
    setLoading(val: boolean, append = false): void {
        if (append) {
            this.loadingAppend = val;
        } else {
            this.loading = val;
        }
    }

    @action.bound
    clearSearch(): void {
        this.showRecommended = false;
        this.setQuery(null);
        this.setPrice(null);
        this.setDateRange(null);
        this.setCategory(null);
        this.setResults([]);
    }

    @computed
    get filterValues(): Pick<IEventQueryParams, 'startDate' | 'endDate' | 'city' | 'minPrice' | 'maxPrice'> {
        return {
            startDate: this.dateRange?.start?.format(),
            endDate: this.dateRange?.end?.format(),
            city: this.city,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
        };
    }

    @action.bound
    setCityFiltersLoading(value: boolean): void {
        this.isCityFiltersLoading = value;
    }

    @action.bound
    getFilters(): void {
        this.actualDictionaries().then(() => {
            if (this.filters.cityFilters.find((city) => city.alias === this.cityTagName)) {
                this.setCities([...this.filters.cityFilters]);
            } else {
                this.getCityFiltersByTagName(this.cityTagName).then(() => {
                    this.setCities([...this.filters.cityFilters, ...this.cityFilters]);
                });
            }
        });
    }

    @action.bound
    setCities(cities: IEventCityFilter[]): void {
        function getUniqueListBy(arr: IEventCityFilter[], key: string) {
            return [...new Map(arr.map((item) => [item[key], item])).values()];
        }
        this.cities = getUniqueListBy([...this.cities, ...cities], 'id');
    }

    @computed
    get cityTagName(): string {
        return localStorage.getItem('userCity') || this.city?.alias || this.filters?.cityFilters?.[0]?.alias;
    }
}
