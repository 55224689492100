import Sentry, {isSentrySetup} from './init-sentry';
import React from 'react';

import whyDidYouRender from '@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js';

import App from 'app';

import 'scroll-behavior-polyfill';

import 'intersection-observer';

import moment from 'moment';
import 'moment/locale/ru';

import TagManager from 'react-gtm-module';

import {setUpGtag, loadGtag} from 'services/Helpers/AnalyticsHelper/analyticsHelpers';
import {browserSupportCheck} from 'services/Helpers/BrowserSupportHelper';

import bridge from '@vkontakte/vk-bridge';
import {createRoot} from 'react-dom/client';

moment.locale('ru');

if (process.env.NODE_ENV !== 'production') {
    whyDidYouRender(React);
}

const tagManagerArgs = {
    gtmId: process.env.GTM_ID,
};

TagManager.initialize(tagManagerArgs);

loadGtag();
setUpGtag();
browserSupportCheck();

const Application = isSentrySetup ? Sentry.withProfiler(App) : App;

bridge.send('VKWebAppInit', {});
bridge.send('VKWebAppEnableSwipeBack');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Application />);
