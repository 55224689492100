import {useEffect, useLayoutEffect} from 'react';
import {useLocation} from 'react-router';

export const ScrollToTop = (): null => {
    const location = useLocation();
    const scrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
        const state: {cancelScroll?: boolean} = location?.state || {};
        if (!state.cancelScroll) {
            history.scrollRestoration = 'manual';
            window.onbeforeunload = scrollToTop;
        }
        return () => {
            history.scrollRestoration = 'auto';
            window.onbeforeunload = undefined;
        };
    });

    useLayoutEffect(() => {
        const state: {cancelScroll?: boolean} = location?.state || {};
        if (!state.cancelScroll) {
            scrollToTop();
        }
    }, [location]);

    return null;
};
