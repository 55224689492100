import React, {useRef} from 'react';
import {useLocation} from 'react-router';
import {observer} from 'mobx-react';
import {useContainer} from 'timepad-di';

import {isEventPage} from 'routing/config';
import {AuthStore} from 'stores/Auth';
import {useAuth} from 'services/hooks';
import {useMatchMedia} from './useMatchMedia';
import {mediaPoints} from './const';
import {FullHeaderBar, MiniHeaderBar} from './components';

export interface IHeaderBarProps {
    isContentPage?: boolean;
}
export const HeaderBar: React.FC<IHeaderBarProps> = observer(({isContentPage}) => {
    const mediaPoint = useMatchMedia(mediaPoints);
    const [{isAuthorized}] = useContainer(AuthStore);

    const location = useLocation();

    const fullHeaderRef = useRef<HTMLDivElement>(null);

    const eventPage = isEventPage(location.pathname);

    const handleAuthClick = useAuth({eventPage}); // вызов окна авторизации

    return (
        <>
            {!isContentPage && (
                <FullHeaderBar
                    mediaPoint={mediaPoint}
                    location={location.pathname}
                    isAuthorized={isAuthorized}
                    fullHeaderRef={fullHeaderRef}
                    onAuthClick={handleAuthClick}
                    isEventPage={eventPage}
                />
            )}
            <MiniHeaderBar
                isEventPage={eventPage}
                mediaPoint={mediaPoint}
                isAuthorized={isAuthorized}
                onAuthClick={handleAuthClick}
                isContentPage={isContentPage}
                fullHeaderRef={fullHeaderRef}
            />
        </>
    );
});
