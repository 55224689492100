import {diContainer} from 'timepad-di';
import {BannersApiService} from '../../services/Api';
import {action, observable, runInAction} from 'mobx';
import {TypeBannerList} from '../../services/Api/BannersApiService';

export class BannersStore {
    private readonly apiService: BannersApiService;
    @observable bannersList: TypeBannerList = {};
    @observable error: Error = null;

    constructor() {
        this.apiService = diContainer.get(BannersApiService);
        this.fetchBannersList();
    }

    @action.bound
    fetchBannersList = async (): Promise<void> => {
        try {
            if (process.env.ADFOX_BANNERS_ENABLE === 'true') {
                const {data = {}, result} = await this.apiService.getBannersList();
                if (result === 'ok' && data !== null) {
                    runInAction(() => {
                        this.bannersList = data;
                    });
                }
            }
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    };
}
