import {IFavoriteOrganization} from 'interfaces/models';
import {BaseApiService, IPagingRequest, IResponseBase} from './base/BaseApiService';
import {IEventQueryParams, IEventsResponse} from './EventsApiService';
import {IEventCityFilter, getLocation} from './DictionariesApiService';

export interface IFavoriteOrgsEventsParams
    extends Partial<Pick<IEventQueryParams, 'startDate' | 'endDate' | 'city' | 'minPrice' | 'maxPrice'>> {
    upcoming?: number;
}

export class FavoritesApiService extends BaseApiService {
    constructor() {
        super('favorites');
    }

    async getFavoriteOrganizations(): Promise<IFavoriteOrganization[]> {
        const {data}: {data: IResponseBase<IFavoriteOrganization[]>} = await this.get({}, 'organizations');
        return data.data;
    }

    async addOrganizationToFavorites(orgId: number): Promise<IResponseBase['result']> {
        const {data}: {data: IResponseBase} = await this.post({orgId}, `organizations`);
        return data.result;
    }

    async removeOrganizationFromFavorites(orgId: number): Promise<IResponseBase['result']> {
        const {data}: {data: IResponseBase} = await this.delete(`organizations/${orgId}`);
        return data.result;
    }

    async getFavoriteEvents(params: IPagingRequest): Promise<IEventsResponse> {
        const {data}: {data: IResponseBase<IEventsResponse>} = await this.get(params, 'events');
        return data.data;
    }

    async addEventToFavorites(eventId: number): Promise<IResponseBase['result']> {
        const {data}: {data: IResponseBase} = await this.post({eventId}, 'events');
        return data.result;
    }

    async removeEventFromFavorites(eventId: number): Promise<IResponseBase['result']> {
        const {data}: {data: IResponseBase} = await this.delete(`events/${eventId}`);
        return data.result;
    }

    async getFavoriteOrgsEvents(params: IFavoriteOrgsEventsParams): Promise<IEventsResponse> {
        const {city = {} as Pick<IEventCityFilter, 'id' | 'type'>, ...rest} = params;
        const location = getLocation(city);
        const {data}: {data: IResponseBase<IEventsResponse>} = await this.get(
            {...location, ...rest},
            'organizations/events',
        );
        return data.data;
    }
}
