import React, {useEffect, useState} from 'react';
import {Provider} from 'mobx-react';
import 'focus-visible';
import {stores} from 'stores';
import {Routing} from 'routing/Routing';
import './styles.less';
import {Theme} from 'components/utility/Modifiers';
import bridge from '@vkontakte/vk-bridge';
import {FacebookPixel} from 'services/Helpers/AnalyticsHelper/facebookPixel';

FacebookPixel.init('639173500082282');
FacebookPixel.pageView();

const App = () => {
    const [theme, setTheme] = useState('bright_light');

    useEffect(() => {
        bridge.subscribe(({detail: {type, data}}) => {
            if (type === 'VKWebAppUpdateConfig') {
                setTheme(data['scheme']);
            }
        });
    }, []);

    const themeMode = theme === 'space_gray' && {dark: true};

    return (
        <Theme {...themeMode}>
            <Provider {...stores}>
                <Routing />
            </Provider>
        </Theme>
    );
};

export default React.memo(App);
