import React, {FC} from 'react';
import {observer} from 'mobx-react';
import {Navigate, Outlet, useNavigate} from 'react-router';
import {TPRoutes} from '../config';
import {useContainer} from 'timepad-di';
import {AuthStore} from 'stores/Auth';
import {useAuth} from 'services/hooks/useAuth';

export const AuthLayout: FC = observer(() => {
    const [authStorage] = useContainer(AuthStore);
    const runAuth = useAuth();
    const navigate = useNavigate();

    if (authStorage.isUnlogin) {
        return <Navigate to={TPRoutes.FEED} replace />;
    }

    if (authStorage.isAuthorized) {
        return <Outlet />;
    }

    if (!authStorage.isAuthorized) {
        runAuth().then((result) => {
            if (!result) return navigate(TPRoutes.FEED);
        });
        return;
    }
});
