import {BaseApiService} from './base/BaseApiService';

interface IDiscountPoliciesRequest {
    situation: IDiscountPoliciesSituation;
}

export interface IDiscountPoliciesSituation {
    promocodes: string[];
    selectedTicketCategories: ISelectedTicketCategory[];
    selectedTickets: ISelectedTicket[];
}

interface ISelectedTicketCategory {
    price: number;
    category_id: number;
    category_name: string;
    tickets_count: number;
}

interface ISelectedTicket {
    price: number;
    category_id: number;
    category_name: string;
    meta: string;
}

export interface IDiscountPoliciesResponse {
    applied_policies: null[];
    applied_promocodes: string[];
    final_price: number;
    effects: IDiscountEffect[];
    is_discount_summation_allowed: boolean;
    pricing: IDiscountPricing;
}

interface IDiscountEffect extends Array<PolicyType | IDiscountEffectTicket> {
    0: PolicyType;
    1: IDiscountEffectTicket;
}

interface IDiscountEffectTicket {
    type: DiscountType;
    amount: number;
    cat_id: string;
    cat_ids: null;
    cat_marked: string;
    cat_marked_not: null;
    cat_name_matches: null;
    cat_name_matches_not: null;
}

export enum PolicyType {
    alterCategoryPrice = 'AlterCategoryPrice',
    discount = 'Discount',
}

enum DiscountType {
    staticDiscount = 'static_discount',
    percentDiscount = 'percent_discount',
}

interface IDiscountPricing {
    nominal: number;
    total: number;
    total_discount: number;
    order_discount: number;
    ticket_types: IDiscountTicketType[];
    discount_message?: string;
    discount_error?: string;
}

interface IDiscountTicketType {
    cat_id: number;
    nominal: number;
    price: number;
    discount: number;
}

export class DiscountApiService extends BaseApiService {
    constructor() {
        super('event');
    }

    async getDiscountPolicies(
        eventId: number,
        situation: IDiscountPoliciesSituation,
    ): Promise<IDiscountPoliciesResponse> {
        const {data} = await this.post({situation} as IDiscountPoliciesRequest, `widget_run_policies/${eventId}`);
        return data as IDiscountPoliciesResponse;
    }
}
