import {useEffect} from 'react';

const enkodeConfig = {
    token: 'timepad',
    // token: 'Test',
    subscribeGroups: [2, 'Посетители событий'],
};

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ekEvents: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        enKodBox: any;
    }
}

export const useEnkod = (): typeof useEnkod => {
    // Чтобы трекинг определил емейл-адрес контакта,
    // попавшего на сайт из емейл-сообщения, в параметры ссылки при отправке нужно прописать URL-параметр
    // eksubsemail = 'email'

    useEffect(() => {
        if (!window.enKodBox) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '//cdn.enkod.ru/script/enpop.min.js';
            script.async = true;
            const first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);
            const enKodBox = (window.enKodBox = window.enKodBox || {});
            enKodBox['token'] = enkodeConfig.token;
        }
    }, []);

    const ekEvents = (window.ekEvents = window.ekEvents || []);

    return {
        // выполняем на каждом новом адресе
        pageOpen: () => ekEvents.push('pageOpen'),

        // открытие страницы события
        productOpen: ({productId, groupId}: IProductData) =>
            ekEvents.push('productOpen', {
                productId, // идентификатор события
                groupId: groupId[0], // идентификатор категории
                categoryId: groupId[0], // идентификатор категории еще раз
            }),
        // Добавление билета
        productAdd: ({productId, groupId, count = 1}: IProductData) =>
            ekEvents.push('productAdd', {
                productId,
                groupId: groupId[0],
                count,
            }),
        // Удаление билета
        productRemove: ({productId, groupId, count = 1}: IProductData) =>
            ekEvents.push('productRemove', {
                productId,
                groupId: groupId[0],
                count,
            }),
        // Покупка
        productBuy: ({orderId, groupId, items, price = 0, count = 1, sum = 0}: IProductData & {items: IOrderItem[]}) =>
            ekEvents.push('productBuy', {
                orderId,
                groupId: groupId[0],
                items,
                price,
                count,
                sum,
            }),
        // Вызов на формах или страницах
        subscribe: ({fields, extraFields}: {fields: IFields; extraFields: IExtraFields}) => {
            ekEvents.push('subscribe', {
                groups: enkodeConfig.subscribeGroups,
                fields,
                extraFields,
                mainChannel: 'email',
            });
        },
        // Позволяет собрать дополнительную информацию, например с неавторизованных пользователей
        unauthAddExtraFields: ({city}: IExtraFields) => {
            ekEvents.push('addExtraFields', {
                extraFields: {
                    city,
                },
            });
        },
    };
};

interface IOrderItem {
    productId: string;
    count: number;
    [key: string]: string | number;
}

interface IProductData {
    productId: string;
    groupId: string[]; // у нас всегда одна категория, но приходит массив. Возможно это когда-то пофиксится
    count?: number;
}

interface IProductData {
    orderId: string;
    groupId: string[]; // у нас всегда одна категория, но приходит массив. Возможно это когда-то пофиксится
    count?: number;
    price?: number;
    sum?: number;
}

interface IFields {
    email: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
}

interface IExtraFields {
    city?: string;
}
