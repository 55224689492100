import React from 'react';

import {Brick, Button, layout, Logo, Typography} from 'front-components';
import {RichLink} from 'components/utility/RichLink';
import {TPRoutes} from 'routing/config';
import cx from 'classnames';

export function NotFoundPage() {
    return (
        <div
            className={layout('flex')({'y-axis': true, 'align-centered': true, 'justify-centered': true})}
            style={{height: '100vh'}}
        >
            <div className={cx(layout('flex')({'justify-centered': true}), layout('page')(), layout('modules')())}>
                <div
                    className={cx(
                        layout('flex')({'y-axis': true, 'spaced-between': true}),
                        layout('modules', '6--desktop')(),
                        layout('modules', '10--tablet')(),
                        layout('modules', '12--portrait')(),
                    )}
                    style={{margin: '12px 0'}}
                >
                    <Logo />
                    <Brick size={2} />
                    <img
                        className="hidden-desktop"
                        style={{width: '320px', height: '320px', alignSelf: 'center'}}
                        src="/static/images/not_found.png"
                    />
                    <div className={layout('flex')({'y-axis': true, 'align-start': true})}>
                        <Typography.Caption className="t-color-gray-50">Ошибка 404</Typography.Caption>
                        <Typography.Subheader responsive style={{marginTop: '-16px'}}>
                            Страница не найдена
                        </Typography.Subheader>
                        <Typography.Body size={16} responsive>
                            Возможно, страницу удалили. Проверьте, правильно ли вы ввели адрес, или перейдите
                            на главную.
                        </Typography.Body>
                        <Brick size={1.5} />
                        <RichLink to={TPRoutes.FEED} style={{width: '100%'}}>
                            <Button variant={Button.variant.primary} label="Перейти на главную" large fixed />
                        </RichLink>
                    </div>
                </div>
                <img
                    className="hidden-mobile"
                    style={{width: '320px', height: '320px'}}
                    src="/static/images/not_found.png"
                />
            </div>
        </div>
    );
}
