import React, {FC} from 'react';
import {DataAnalytics} from '../../../stores/Analytics/dataAnalitycs';
import {headerLinks} from '../const';
import {Button, layout, Row} from 'front-components';
import {RichLink} from '../../utility/RichLink';

export const CreateEventButton: FC = () => {
    return (
        <RichLink data-analytics={DataAnalytics.menuForOrgsLogin} external to={headerLinks.login} target="_blank">
            <Row horizontalPadding={8}>
                <Row.Body>
                    <Button variant={Button.variant.secondary} className={layout('flex--justify-centered')()}>
                        Создать событие
                    </Button>
                </Row.Body>
            </Row>
        </RichLink>
    );
};
