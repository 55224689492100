import {matchRoutes, useLocation} from 'react-router';
import {TPRoutes} from 'routing/config';

export const useCurrentPath = (arr: TPRoutes[]): TPRoutes | undefined => {
    const location = useLocation();
    const allMatchedPaths = matchRoutes(
        arr.map((path) => ({path})),
        location,
    );
    // Return first match
    return allMatchedPaths ? allMatchedPaths[0].route.path : undefined;
};
