import React, {useEffect} from 'react';
import {useContainer} from 'timepad-di';

import {AnimationHeaderBarStore} from 'components/HeaderBar';

export const useFiltersAnimation = (
    filtersRef: React.RefObject<HTMLDivElement>,
    intersectionRef: React.RefObject<HTMLDivElement>,
    isMobilePortraitMax: boolean,
    isCityReady: boolean,
): void => {
    const [{setIsMiniHeaderBarHidden}] = useContainer(AnimationHeaderBarStore);

    // анимация отвечает за переключения между mini-headerbar и filters и выстраиванием filters в одну линию
    useEffect(() => {
        if (!isMobilePortraitMax || !isCityReady) return;
        const filters = filtersRef?.current;
        const intersectionBlock = intersectionRef?.current;
        const container = filters.children[0] as HTMLDivElement;
        const hiddenContainer = filters.children[1] as HTMLDivElement;
        let lastScrollTop = 0;
        let isFiltersStatic = false;
        let isContainerHidden = false;
        let isStylesRemoved = true;

        const applySticky = () => {
            filters.style.position = 'sticky';
            filters.style.transform = 'translateY(-100%)';
            const id = setTimeout(() => {
                filters.style.transform = 'translateY(0)';
                filters.style.transition = 'transform 0.3s ease-out';
                isFiltersStatic = false;
                clearTimeout(id);
            }, 0);
        };

        const applyStatic = (value: number, callback?: () => void) => {
            filters.style.transform = `translateY(${value}%)`;
            filters.style.transition = 'transform 0.3s ease-out';
            const listener = () => {
                filters.style.position = 'static';
                filters.style.transform = 'translateY(0)';
                filters.style.transition = 'unset';
                // если проскроллены фильтры, вернуть их в первоначальное положение
                isFiltersStatic = true;
                callback?.();
                hiddenContainer.scroll({left: 0});
                filters.removeEventListener('transitionend', listener);
            };
            filters.addEventListener('transitionend', listener);
        };

        const hideContainer = () => {
            filters.style.pointerEvents = 'none';

            container.style.opacity = '0';
            container.style.pointerEvents = 'none';
            isContainerHidden = true;

            hiddenContainer.style.opacity = '1';
            hiddenContainer.style.pointerEvents = 'auto';
        };

        const showContainer = () => {
            filters.style.pointerEvents = 'auto';

            container.style.opacity = '1';
            container.style.transition = 'unset';
            container.style.pointerEvents = 'auto';
            isContainerHidden = false;

            hiddenContainer.style.opacity = '0';
            hiddenContainer.style.transition = 'unset';
            hiddenContainer.style.pointerEvents = 'none';
        };

        const removeStyles = () => {
            filters.style.removeProperty('position');
            filters.style.removeProperty('transform');
            filters.style.removeProperty('transition');
            isFiltersStatic = false;

            filters.style.removeProperty('overflow');
            filters.style.removeProperty('pointer-events');
            isContainerHidden = false;

            container.style.removeProperty('opacity');
            container.style.removeProperty('pointer-events');
            container.style.removeProperty('transition');

            hiddenContainer.style.removeProperty('opacity');
            hiddenContainer.style.removeProperty('pointer-events');
            hiddenContainer.style.removeProperty('transition');
            isStylesRemoved = true;
        };

        const handleScrollListener = () => {
            const scrollTop = Math.ceil(window.scrollY + 16);
            const isScrollUp = scrollTop < lastScrollTop;
            const isScrollDown = scrollTop > lastScrollTop;
            const offsetTop = intersectionBlock?.offsetTop - 8;

            if (scrollTop >= offsetTop) {
                isStylesRemoved = false;
                if (isScrollDown) {
                    setIsMiniHeaderBarHidden(true);
                    if (!isContainerHidden) hideContainer();
                    if (isFiltersStatic) applySticky();
                }
                if (isScrollUp) {
                    setIsMiniHeaderBarHidden(false);
                    const callback = isContainerHidden ? showContainer : undefined;
                    const ratio = ((offsetTop - scrollTop) / 48) * 100;
                    const value = ratio <= -100 ? -100 : Math.ceil(ratio);
                    if (!isFiltersStatic) applyStatic(value, callback);
                }
            }
            if (scrollTop <= offsetTop && !isStylesRemoved && isFiltersStatic) {
                removeStyles();
            }

            lastScrollTop = scrollTop;
        };

        window.addEventListener('scroll', handleScrollListener);
        return () => {
            window.removeEventListener('scroll', handleScrollListener);
            removeStyles();
            setIsMiniHeaderBarHidden(false);
        };
    }, [filtersRef, intersectionRef, isMobilePortraitMax, setIsMiniHeaderBarHidden, isCityReady]);
};
