export enum EventGoals {
    PaidEvent = '[TimePad] paid event', // просмотр события хотя бы с одниим платным билетом
    FreeEvent = '[TimePad] free event', // просмотр события с бесплатными билетами
    LoadedForm = '[TimePad] loaded form',
    OpenedForm = '[TimePad] opened form',
    EditedField = '[TimePad] edited field',
    ClickedButton = '[TimePad] clicked button',
    ShowedFormPayment = '[TimePad] show form Payment',
    ClickedButtonPayment = '[TimePad] clicked button PaymentTypes',
    VisitedPagePaymentConfirm = '[TimePad] visited page PaymentConfirm', //для платного заказа
    VisitedPageRegisterConfirm = '[TimePad] visited page RegisterConfirm', //для бесплатного заказа
}
