import {useEffect, useLayoutEffect} from 'react';
import {useContainer} from 'timepad-di';
import {useNavigate} from 'react-router';
import moment from 'moment';
import {Moment} from 'moment/moment';

import {SearchStore} from 'stores/Search';
import {CategoriesStore} from 'stores/Categories';
import {ICategory} from 'interfaces/models';
import {IFilterDate} from 'interfaces/misc';
import {IEventCityFilter, IEventPriceFilter, IEventFilters} from 'services/Api';
import {useFilterParams} from 'services/hooks';

interface IUseFiltersResult {
    handleResetFiltersClick: () => void;
    handleCategoryClick: (category: ICategory) => void;
    handleResetCategoryClick: () => void;
    handleDatePickerChange: (start: Moment, end: Moment) => void;
    handleResetDataPickerClick: () => void;
    handlePriceClick: (price: IEventPriceFilter) => void;
    handleResetPriceClick: () => void;
    handleCityClick: (city: IEventCityFilter) => void;
    filters: IEventFilters;
    categories: ICategory[];
    city: IEventCityFilter;
    category: ICategory;
    dateRange: IFilterDate;
    price: IEventPriceFilter;
    query: string;
}
export const useFilters = (): IUseFiltersResult => {
    const [
        {
            setCategory,
            setDateRange,
            setLoading,
            setPrice,
            filters,
            clearSearch,
            setCity,
            city,
            category,
            dateRange,
            price,
            query,
        },
    ] = useContainer(SearchStore);

    const navigate = useNavigate();
    const filterNavigate = (params: {city?: string; category?: number; searchParams?: string}) => {
        const {city, category, searchParams} = params;
        const baseUrl = category ? `/category/${category}` : '/search';
        navigate(`/${city}${baseUrl}?${searchParams.toString()}`);
    };

    const {computedCity, matchCategory, searchDate, searchPrice, pushHandle, deleteParam} = useFilterParams(
        filterNavigate,
    );
    const [{categories}] = useContainer(CategoriesStore);

    useLayoutEffect(() => {
        if (!matchCategory && !searchDate && !searchPrice) setLoading(true);
    }, []);

    // Установка категории
    useEffect(() => {
        if (categories.length) {
            setCategory(categories?.find((cat) => cat.id === matchCategory));
        }
    }, [setCategory, categories, matchCategory]);

    // Установка даты
    useEffect(() => {
        if (filters) {
            if (searchDate) {
                const dates = searchDate.split(',');
                setDateRange({start: moment(dates[0]), end: moment(dates[1])});
            } else {
                setDateRange(null);
            }
        }
    }, [setDateRange, filters, searchDate]);

    // Установка цены
    useEffect(() => {
        if (filters) {
            setPrice(filters?.priceFilters.find((price) => String(price.id) === searchPrice));
        }
    }, [setPrice, filters, searchPrice]);

    const handleCategoryClick = (category: ICategory): void => {
        setCategory(category);
        pushHandle({
            city: computedCity,
            category: category?.id,
        });
    };

    const handleResetCategoryClick = (): void => {
        setCategory(null);
        pushHandle({
            city: computedCity,
            category: null,
        });
    };

    const handleDatePickerChange = (start: Moment, end: Moment): void => {
        setDateRange({start, end});
        pushHandle({
            city: computedCity,
            category: matchCategory,
            param: ['date', [start.format(), end.format()].join(',')],
        });
    };

    const handleResetDataPickerClick = (): void => {
        setDateRange(null);
        deleteParam('date');
        pushHandle({
            city: computedCity,
            category: matchCategory,
        });
    };

    const handlePriceClick = (price: IEventPriceFilter): void => {
        setPrice(price);
        pushHandle({
            city: computedCity,
            category: matchCategory,
            param: ['price', price.id],
        });
    };

    const handleResetPriceClick = (): void => {
        setPrice(null);
        deleteParam('price');
        pushHandle({
            city: computedCity,
            category: matchCategory,
        });
    };

    const handleCityClick = (city: IEventCityFilter): void => {
        setCity(city);
        pushHandle({
            city: city.alias,
            category: matchCategory,
        });
    };

    const handleResetFiltersClick = () => {
        setLoading(true);
        clearSearch();
        ['date', 'price', 'query'].forEach((param) => deleteParam(param));
        pushHandle({
            city: computedCity,
        });
    };

    return {
        handleCategoryClick,
        handleResetCategoryClick,
        handleDatePickerChange,
        handleResetDataPickerClick,
        handlePriceClick,
        handleResetPriceClick,
        handleCityClick,
        handleResetFiltersClick,
        filters,
        categories,
        city,
        category,
        dateRange,
        price,
        query,
    };
};
