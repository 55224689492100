import {SpinLoader} from 'front-components';
import React, {FC} from 'react';
import {ISpinLoaderProps} from 'front-components/src/components/loader/SpinLoader';

export const Fallback: FC<ISpinLoaderProps> = ({style, ...props}) => {
    return (
        <SpinLoader
            {...props}
            style={{
                flexGrow: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...style,
            }}
        />
    );
};
