import * as Yup from 'yup';

import {FieldTag} from 'interfaces/forms/forms';
import {IFormField} from 'interfaces/models';
import {FieldType} from 'interfaces/models/Form';

const emailRegex = /^[-a-z0-9!#$&'*+=^_`{|}~]+(?:\.[-a-z0-9!_+=^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:[a-z]{2,})$/i;
const cyrillicRegex = /^[^\u0400-\u04FF]*$/;
export const nameRegexp = /^[A-ZА-ЯёЁ -]+$/i;
// Валидируется два формата номера, второй для получаемого из профиля пользователя
export const phoneRegexp = /\+7 \(\d{3}\) \d{3} \d{2} \d{2}|\+7\d{10}/;
export const strippedPhoneRegexp = /^(\d){10}$/i;

const getStrippedPhoneNumber = (phone: string) => phone.replace(/[\)\(_\- ]/g, '').slice(2);

export const validatePhoneNumber = (value: string) => {
    const changedValue = getStrippedPhoneNumber(value);

    let error: string;
    if (!changedValue) {
        error = 'Необходимо заполнить';
    } else if (!strippedPhoneRegexp.test(changedValue)) {
        error = 'Неверный формат';
    }
    return error;
};

const isValidStringNumberRuls = (value = '') => {
    const isNumber = !isNaN(Number(value));
    const forEmpty = value.length === 0;
    const forNumber = isNumber && value.length >= 1;
    const forString = !isNumber && value.length >= 2;
    return forEmpty || forNumber || forString;
};

export const isRequired = (value: string) => (!!value ? undefined : 'Необходимо заполнить поле');

// Yup.string().email() не запрещает ввода кириллицы, поэтому используется регулярные выражения для проверки email
export const emailSchema = Yup.string()
    .matches(cyrillicRegex, {
        message: 'Адреса с кириллицей не поддерживаются, проверьте правильность написания',
    })
    .matches(emailRegex, {
        message: 'Неверный email',
    });

const userProfileSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Слишком короткое имя')
        .matches(/^[A-ZА-ЯёЁ -]+$/i, {
            message: 'Имя содержит недопустимые символы',
        }),
    surname: Yup.string()
        .min(2, 'Слишком короткая фамилия')
        .matches(/^[A-ZА-ЯёЁ -]+$/i, {
            message: 'Фамилия содержит недопустимые символы',
        }),
    email: emailSchema,
    mail: emailSchema,
    // TODO: разобраться с беком, чтобы не было этого костыля
});
export {userProfileSchema as validationSchema}; // Сменил локальное имя схемы т.к. оно более не отражает суть

export const getCheckoutValidationSchema = (
    tickets: IFormField[],
    masterQuestions: IFormField[],
    isMultianket = false,
) => {
    const getInputValidationShape = (formFields: IFormField[]) => {
        return formFields.reduce(
            (
                acc: Record<string, Yup.Schema<string> | Yup.Ref | Yup.ArraySchema<string>>,
                {id, tag, type, isRequired}: IFormField,
            ) => {
                const shapeAtom: Record<string, Yup.Schema<string | string[]>> = {};
                let atomField: Yup.StringSchema<string> | Yup.ArraySchema<string>;
                switch (tag) {
                    case FieldTag.name:
                        shapeAtom.name = Yup.string()
                            .min(2, 'Слишком короткое имя')
                            .matches(nameRegexp, {
                                message: 'Имя содержит недопустимые символы',
                            })
                            .required('Это обязательное поле');
                        break;
                    case FieldTag.surname:
                        shapeAtom.surname = Yup.string()
                            .min(2, 'Слишком короткая фамилия')
                            .matches(nameRegexp, {
                                message: 'Фамилия содержит недопустимые символы',
                            })
                            .required('Это обязательное поле');
                        break;
                    case FieldTag.mail:
                    case FieldTag.order_mail:
                        shapeAtom[tag] = emailSchema.required('Это обязательное поле');
                        break;
                    case FieldTag.phone:
                        atomField = Yup.string()
                            .matches(phoneRegexp, 'Вы ввели некорректный номер телефона')
                            .length(12, 'Телефон должен быть заполнен')
                            .required('Это обязательное поле');
                        shapeAtom.phone = atomField;
                        break;
                    default:
                        switch (type) {
                            case FieldType.radio:
                                atomField = Yup.string();
                                break;
                            case FieldType.upload:
                                atomField = Yup.string().min(5);
                                break;
                            case FieldType.multivar:
                                atomField = Yup.array().of(Yup.string());
                                break;
                            case FieldType.text:
                            case FieldType.bigtext:
                                atomField = Yup.string().test(
                                    'is-valid-text/bigtext',
                                    'Поле не может содержать менее двух символов',
                                    isValidStringNumberRuls,
                                );
                                break;
                            default:
                                atomField = Yup.string();
                        }

                        if (isRequired) {
                            atomField = atomField.required('Это обязательное поле');
                        }
                        shapeAtom[id] = atomField;
                }
                return {...acc, ...shapeAtom};
            },
            {},
        );
    };

    const ticketShape = getInputValidationShape(isMultianket ? tickets : [...tickets, ...masterQuestions]);
    const masterQuestionsShape = isMultianket ? Yup.object().shape(getInputValidationShape(masterQuestions)) : null;

    return Yup.object().shape({
        tickets: Yup.array().of(
            Yup.object().shape({
                id: Yup.string(),
                values: Yup.array().of(Yup.object().shape(ticketShape)),
            }),
        ),
        masterQuestions: masterQuestionsShape,
    });
};

export const isAuthCodeValid = (code?: string) => {
    if (!code) return false;

    const trimmedCode = code?.replace(new RegExp(/[^\d]/gi), '');

    return trimmedCode?.length === 4;
};
