import {IModalInstance, ModalWindow, IModalConfigs, IModalResult} from './ModalStore';
import {observable, action} from 'mobx';

export class ModalInstance<T extends ModalWindow> implements IModalInstance<T> {
    type: ModalWindow;

    props: IModalConfigs[T] = {};

    @observable opened = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @observable resolve: (value?: IModalResult<any>) => void;

    @observable reject;

    constructor(type: T, props?: IModalConfigs[T]) {
        this.type = type;
        if (props) {
            this.props = props;
        }
    }

    @action.bound
    async open<V>(props: IModalConfigs[T], onOpen: () => void = undefined) {
        onOpen?.();
        document.documentElement.style.setProperty(
            '--scrollbar-width',
            window.innerWidth - document.documentElement.clientWidth + 'px',
        );
        this.props = props;
        return this.start<V>();
    }

    @action.bound
    close() {
        document.documentElement.style.setProperty('--scrollbar-width', '');
        this.opened = false;
        this.resolve({result: false});
    }

    @action.bound
    async start<V>() {
        this.opened = true;
        const promise = new Promise<IModalResult<V>>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        return promise;
    }
}
