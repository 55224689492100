import React, {useState, useRef, Fragment} from 'react';
import {component, useMedia} from 'front-components';

import {Controller} from 'components/common/Controller';
import {Dropdown} from 'front-components';

import IconArrowSolid from 'assets/svg/16/icon-arrow-solid-16.svg';
import CloseIcon from 'assets/svg/16/icon-close-alt-16.svg';

interface IFilterInputProps {
    label: string;
    value: string;
    white?: boolean;
    children?: (closeMethod: () => void) => React.ReactNode;
    onClick?: (ref: React.MutableRefObject<HTMLButtonElement>, showTooltipCallback: () => void) => void;
    onReset?: () => void;
}

export function FilterInput({label, value, children, onClick, onReset, ...props}: IFilterInputProps) {
    const [tooltipOpened, setTooltipOpened] = useState(false);
    const {isMobileMax} = useMedia();
    const controllerRef = useRef<HTMLButtonElement>();
    const arrowClasses = component('icon')({['rotate-180']: tooltipOpened, ['animatable-blink']: true});

    return (
        <>
            <Dropdown
                fixPositionOnScroll={isMobileMax}
                trigger={() => (
                    <Controller
                        buttonRef={controllerRef}
                        active={tooltipOpened}
                        onClick={() => {
                            if (onClick) {
                                onClick(controllerRef, () => setTooltipOpened(true));
                            } else {
                                setTooltipOpened(true);
                            }
                        }}
                        {...props}
                    >
                        <Fragment>
                            <span style={{marginLeft: '16px'}}>{value || label}</span>
                            {!!value && onReset ? (
                                <CloseIcon
                                    className={component('icon')()}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onReset();
                                        setTooltipOpened(false);
                                    }}
                                />
                            ) : (
                                <IconArrowSolid className={arrowClasses} />
                            )}
                        </Fragment>
                    </Controller>
                )}
                show={tooltipOpened}
                onClose={() => setTooltipOpened(false)}
                priorityPositions={['bottom-left']}
            >
                {children(() => setTooltipOpened(false))}
            </Dropdown>
        </>
    );
}
