import {useEffect, useMemo} from 'react';
import {useLocation, useParams} from 'react-router';
import {useContainer} from 'timepad-di';

import {isCollection} from 'routing/config';
import {SearchStore} from 'stores/Search';

interface IPushHandle {
    city: string;
    category?: number;
    param?: [string?, string?];
}

interface IUseFilterParams {
    computedCity: string;
    matchCategory: number;
    searchDate: string;
    searchPrice: string;
    queryParams: string;
    pushHandle: (params: IPushHandle) => void;
    deleteParam: (param: string) => void;
}

export const useFilterParams = (
    filterNavigate?: (params: {city?: string; category?: number; searchParams?: string}) => void,
): IUseFilterParams => {
    const [searchStorage] = useContainer(SearchStore);
    const {filters, city, cities} = searchStorage;
    const location = useLocation();
    const params = useParams<{city?: string; id?: string}>();
    // Search params
    const searchParams = new URLSearchParams(location.search);
    const searchDate = searchParams.get('date');
    const searchPrice = searchParams.get('price');
    const queryParams = searchParams.get('query');

    const isCollectionPage = isCollection(location.pathname);

    // Match params
    const matchCity = params.city;
    const matchCategory = isCollectionPage ? null : Number(params.id);

    const pushHandle = ({city, category = null, param: [name, value] = []}: IPushHandle): void => {
        if (name && value) searchParams.set(name, value);
        if (!value) searchParams.delete(name); // на случай пустого значения
        localStorage.setItem('userCity', city);
        filterNavigate?.({city, category, searchParams: searchParams.toString()});
    };

    // Порядок проверки важен!
    const computedCity = useMemo(
        () => city?.alias || matchCity || localStorage.getItem('userCity') || filters?.cityFilters[0].alias,
        [filters, matchCity, city],
    );

    const deleteParam = (param: string) => {
        searchParams.delete(param);
    };

    useEffect(() => {
        if (cities?.length && computedCity) {
            const currentCity = cities.find((city) => city.alias === computedCity);
            if (currentCity) {
                searchStorage.setCity(currentCity);
                localStorage.setItem('userCity', computedCity);
            }
        }
    }, [cities, computedCity]);

    return {computedCity, matchCategory, searchDate, searchPrice, queryParams, pushHandle, deleteParam};
};
