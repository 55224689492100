import React from 'react';
import {DatePicker, layout} from 'front-components';
import {Moment} from 'moment';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {RecommendedBlockQaAttrs} from 'interfaces/models';
import {IFilterDate} from 'interfaces/misc';
import {FilterInput} from '../../common/FilterInput';

const dataAnalyticsProps = {
    todayBtn: DataAnalytics.userDateToday,
    tomorrowBtn: DataAnalytics.userDateTomorrow,
    weekendBtn: DataAnalytics.userDateDayoff,
};
const getDateString = (start: Moment, end: Moment): null | string => {
    if (!start || !end) {
        return null;
    } else if (start.isSame(end, 'day')) {
        return start.format('D MMMM');
    } else if (start.isSame(end, 'month')) {
        return start.format('D') + '—' + end.format('D MMMM');
    } else if (start.isSame(end, 'year')) {
        return start.format('D MMMM') + '—' + end.format('D MMMM');
    } else {
        return start.format('D MMMM YYYY') + '—' + end.format('D MMMM YYYY');
    }
};
interface IDateFilterProps {
    onDatePickerChangeHandler: (start: Moment, end: Moment) => void;
    onResetDataPickerClickHandler: () => void;
    dateRange: IFilterDate;
}
export const DateFilter: React.FC<IDateFilterProps> = ({
    onDatePickerChangeHandler,
    onResetDataPickerClickHandler,
    dateRange,
}) => {
    return (
        <FilterInput
            label="Дата"
            value={getDateString(dateRange?.start, dateRange?.end)}
            onReset={onResetDataPickerClickHandler}
            data-analytics={DataAnalytics.userFeedDate}
            data-qa={RecommendedBlockQaAttrs.FilterDate}
        >
            {(close) => (
                <div onClick={(e) => e.stopPropagation()} className={layout('flex')({'justify-centered': true})}>
                    <DatePicker
                        initialStart={dateRange?.start}
                        initialEnd={dateRange?.end}
                        withShortcats
                        dateRange
                        onChange={(start, end) => {
                            if (!start && !end) return;
                            close();
                            onDatePickerChangeHandler(start, end);
                        }}
                        analytic={dataAnalyticsProps}
                    />
                </div>
            )}
        </FilterInput>
    );
};
