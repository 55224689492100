import {observable, flow, action, runInAction} from 'mobx';
import {diContainer} from 'timepad-di';

import {helperInitVarRelationType, normalizeOldOrganization} from 'services/Helpers/VideoHelper/VideoHelper';
import {IVideo, IContentQueryParams, IOrganizationContentResponse, IOrganization} from 'interfaces/models';
import {FavoritesApiService, OrganizationsApiService} from 'services/Api';
import {OrganizationEntity} from './Organization';
import {VideoEntity} from '../Video';
import {OrganizationsListStore} from './OrganizationsListStore';

export interface IOrganizationContentListResponse {
    contentCount: number;
    contentList: IOrganizationContentResponse[];
}

export class OrganizationStore {
    @observable loading = false;

    @observable loadingEvents = false;

    @observable error: Error = null;

    @observable errorEvents: Error = null;

    @observable organization: OrganizationEntity = null;

    @observable organizationContentCount?: number;

    @observable organizationContentList = [];

    @observable contentLoadLimit = 24;

    private readonly apiService: OrganizationsApiService;

    private readonly organizationsListStorage: OrganizationsListStore;

    private readonly favoritesApiService: FavoritesApiService;

    constructor() {
        this.apiService = diContainer.get(OrganizationsApiService);
        this.organizationsListStorage = diContainer.get(OrganizationsListStore);
        this.favoritesApiService = diContainer.get(FavoritesApiService);
    }

    sanitizeVideoData(data: IOrganizationContentResponse): IVideo {
        return {
            organization: new OrganizationEntity(normalizeOldOrganization(data?.organization)),
            isAvailableForUser: helperInitVarRelationType(data?.is_available_for_user, 'boolean', false),
            isFavorite: helperInitVarRelationType(data?.is_favorite, 'boolean', false),
            accessMessage: helperInitVarRelationType(data.access_message, 'string', ''),
            id: data.id,
            title: data.title,
            status: helperInitVarRelationType(data.status, 'string', ''),
            categoryId: data.category_id,
            minPrice: helperInitVarRelationType(data.min_price, 'number', 0),
            language: helperInitVarRelationType(data.language, 'string', ''),
            img: data.img,
            duration: data.duration,
            ageLimit: helperInitVarRelationType(data.age_limit, 'string', ''),
        };
    }

    createVideoModels(data: IOrganizationContentResponse[]): VideoEntity[] {
        return data.map((v) => new VideoEntity(this.sanitizeVideoData(v)));
    }

    @action.bound
    setIsFavorite(status?: boolean) {
        if (this.organization) {
            const newStatus = status || !this.organization.isFavorite;
            this.organization.isFavorite = newStatus;
            this.updateSubscribersCounter(newStatus);
        }
    }

    @action.bound
    updateSubscribersCounter(increment?: boolean) {
        if (increment) {
            this.organization.subscribersCount++;
        } else {
            this.organization.subscribersCount--;
        }
    }

    @action.bound
    getOrganization = flow(function*(this: OrganizationStore, id: string) {
        this.loading = true;
        this.error = null;

        try {
            const organization: IOrganization = yield this.apiService.getOrganization(id);
            this.organization = new OrganizationEntity(organization);
        } catch (err) {
            this.error = err;
        } finally {
            this.loading = false;
        }
    });

    async getOrganizationContentList(
        this: OrganizationStore,
        id: string,
        params?: IContentQueryParams,
        append = false,
    ) {
        runInAction(() => {
            this.loadingEvents = true;
            this.errorEvents = null;
            this.error = null;
        });

        try {
            const {
                contentCount,
                contentList,
            }: IOrganizationContentListResponse = await this.apiService.getAllContentList(id, {
                ...params,
                limit: this.contentLoadLimit,
            });
            const videoModels = this.createVideoModels(contentList);

            if (append) {
                runInAction(() => {
                    this.organizationContentList = this.organizationContentList.concat(videoModels);
                });
            } else {
                runInAction(() => {
                    this.organizationContentCount = contentCount;
                    this.organizationContentList = videoModels;
                });
            }
        } catch (err) {
            runInAction(() => {
                this.errorEvents = err;
            });
        } finally {
            runInAction(() => {
                this.loadingEvents = false;
            });
        }
    }

    async getMoreOrganizationContent(this: OrganizationStore) {
        const limit = this.contentLoadLimit;
        const offset = this.organizationContentList.length;
        runInAction(() => {
            this.errorEvents = null;
        });

        try {
            runInAction(() => {
                this.getOrganizationContentList(
                    String(this.organization?.id),
                    {
                        limit,
                        offset,
                    },
                    true,
                );
            });
        } catch (err) {
            runInAction(() => {
                this.errorEvents = err;
            });
        }
    }

    @action.bound
    async addOrgToFavorites(orgId: number): Promise<void> {
        this.error = null;

        try {
            const response = await this.favoritesApiService.addOrganizationToFavorites(orgId);
            if (response === 'ok') {
                this.setIsFavorite();
                this.organizationsListStorage.updateMemorizedFavoriteOrgs(orgId);
            }
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    }

    @action.bound
    async removeOrgFromFavorites(orgId: number): Promise<void> {
        this.error = null;

        try {
            const response = await this.favoritesApiService.removeOrganizationFromFavorites(orgId);
            if (response === 'ok') {
                this.setIsFavorite();
                this.organizationsListStorage.updateMemorizedFavoriteOrgs(orgId);
            }
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    }
}
