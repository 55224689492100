import {useLocation} from 'react-router';
import {useLayoutEffect} from 'react';
import {FacebookPixel} from 'services/Helpers/AnalyticsHelper';

export const FacebookPixelWatch = (): null => {
    const location = useLocation();

    useLayoutEffect(() => {
        FacebookPixel.pageView();
    }, [location]);

    return null;
};
