import Cookies from 'js-cookie';
import {EventGoals, IOrganizationAnalyticServices} from 'interfaces/models';
import {rbClickParamName} from './vkPixel';

export const gtagDataLayerName = 'gtagDataLayer';

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window {
        [gtagDataLayerName]: Array<string | boolean | number | object>;
    }
}

export interface IUTMCookies {
    utmcsr?: string;
    utmcmd?: string;
    utmccn?: string;
    utmcct?: string;
    utmctr?: string;
}

export const getUTMCookies = () => {
    const cookieString = Cookies.get('__utmzz');
    const entries = cookieString?.split('|') || [];

    return entries.reduce((acc, curr) => {
        const [key, value] = curr.split('=');
        return {...acc, [key]: value};
    }, {} as IUTMCookies);
};

export const clearUTMCookies = () => {
    Cookies.remove('__utmzz');
    Cookies.remove('__utmzzses');
};

export const loadGtag = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?l=${gtagDataLayerName}`;
    document.body.appendChild(script);
};

export const setUpGtag = () => {
    window[gtagDataLayerName] = window[gtagDataLayerName] || [];
    window.gtag = function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window[gtagDataLayerName].push(arguments);
    };
};

export const sendGtagEvent = (
    name: string,
    targetId: string,
    payload: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) => {
    window?.gtag('event', name, {...payload, ['send_to']: targetId});
};

export const initYandexMetrika = (id: string): void => {
    if (!id) return;
    window['ym'] =
        window['ym'] ||
        function() {
            // eslint-disable-next-line prefer-rest-params
            (window['ym'].a = window['ym'].a || []).push(arguments);
        };
    // @ts-ignore
    window['ym'].l = 1 * new Date();
    window['ym'](Number(id), 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    });
};

const sendGoalYandexMetrika = (id: string, goal: EventGoals): void => {
    window?.['ym']?.(Number(id), 'reachGoal', goal);
};

export const initGA4 = (id: string): void => {
    if (!id) return;
    window['dataLayer'] = window['dataLayer'] || [];
    window.gtag = function gtag(): void {
        // eslint-disable-next-line prefer-rest-params
        window['dataLayer'].push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', id);
};

const sendGoalGA4 = (id: string, goal: EventGoals, value?: any): void => {
    window?.gtag?.('event', goal, {
        send_to: id,
        event_category: 'user',
        event_action: goal,
        value: value,
    });
};

export const sendGoals = (services = {} as IOrganizationAnalyticServices, goal: EventGoals, value?: unknown): void => {
    const {yandexMetric = '', googleAnalytics = ''} = services;
    if (yandexMetric) {
        sendGoalYandexMetrika(yandexMetric, goal);
    }
    if (googleAnalytics) {
        sendGoalGA4(googleAnalytics, goal, value);
    }
};

interface IGAData {
    [trackerId: string]: {
        sessionId?: string;
        dt?: string;
        dl?: string;
    };
}
type TGaData = IGAData & {clientId?: string};

const getGaData = (trackerId): Promise<TGaData> => {
    return new Promise((resolve) => {
        const result = {};

        if (!window.gtag) {
            resolve(result);
        }

        // Делаем хак, чтобы процесс не завис навечно, если гугл не отвечает
        const timeout = setTimeout(() => {
            resolve(result);
            clearTimeout(timeout);
        }, 2000);

        const callback = () => {
            if (result['clientId'] && result['sessionId']) {
                resolve(result as TGaData);
            }
        };

        // Забираем clientId, он для всех счетчиков одинаковый
        // @ts-ignore
        window.gtag('get', trackerId, 'client_id', (clientId: string) => {
            result['clientId'] = clientId;
            callback();
        });
        // Формируем _ga_data document_title(dt), document_link(dl) и session_id(sid)
        // @ts-ignore
        window.gtag('get', trackerId, 'session_id', (sessionId: string) => {
            result['sessionId'] = sessionId;
            result['dt'] = document.title;
            result['dl'] = window.location.href;
            callback();
        });
    });
};

const getYandexMetrikaClientId = (yandexMetrika: string): Promise<string> => {
    return new Promise((resolve) => {
        window?.['ym']?.(Number(yandexMetrika), 'getClientID', function(clientID: string) {
            if (clientID) {
                return resolve(clientID);
            }
            resolve('');
        });
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const enrichWithAnalyticsData = async <T>(data: T, orgTrackers: IOrganizationAnalyticServices) => {
    const analyticsEnabled = process.env.ANALYTICS_ENABLE;
    if (!analyticsEnabled) {
        return data;
    }

    // _ga_data нужно сразу, так как заполнение асинхронно
    const analyticsData = {_ga_data: {}};

    // GA4
    const tpGaTracker = process.env.GOOGLE_ANALYTICS_4;
    if (tpGaTracker) {
        const {clientId: gaClientId, ...tpGaData} = await getGaData(tpGaTracker);
        analyticsData['_ga_cid'] = gaClientId;
        analyticsData._ga_data[tpGaTracker] = tpGaData;
    }
    if (orgTrackers.googleAnalytics) {
        // не берем clientId так как он одинаковый
        const {clientId: _, ...orgGaData} = await getGaData(orgTrackers.googleAnalytics);
        analyticsData._ga_data[orgTrackers.googleAnalytics] = orgGaData;
    }
    // VK Pixel rb_clickid
    const _vk_rb_clickid = Cookies.get(rbClickParamName);
    if (_vk_rb_clickid) {
        analyticsData[_vk_rb_clickid] = _vk_rb_clickid;
    }

    if (orgTrackers.yandexMetric) {
        const clientID = await getYandexMetrikaClientId(orgTrackers.yandexMetric);
        if (clientID) {
            analyticsData['_ym_cid'] = clientID;
        }
    }

    // тут проверяем что что-то кроме _ga_data добавилось
    const hasAnalyticsData = Object.keys(analyticsData).length > 1 && Object.keys(analyticsData._ga_data).length > 0;

    return {
        ...data,
        ...(hasAnalyticsData && {analytics_data: analyticsData}),
    };
};
