import {observable, action, computed, runInAction} from 'mobx';
import {diContainer} from 'timepad-di';

import {EventsApiService} from 'services/Api';
import {IRegistrationTicketData, ITicket} from 'interfaces/models';
import {TicketEntity} from './TicketEntity';

import {
    DiscountApiService,
    IDiscountPoliciesResponse,
    IDiscountPoliciesSituation,
    PolicyType,
} from 'services/Api/DiscountApiService';

export enum EventReg {
    'multianket' = 'multianket',
    'singlereg' = 'singlereg',
    'multireg' = 'multireg',
}

export interface ITicketResponse {
    eventRegType: EventReg;
    tickets: ITicket[];
    hasPromocode: boolean;
    hasPolicyPromocode: boolean;
    hasPolicyDiscount: boolean;
    promocodeOK: boolean;
    additionalComission: number;
    ticketsLimit: number | null;
}

export class Ticket {
    @observable isLoading = false;

    @observable eventRegType: EventReg = null;

    @observable tickets: TicketEntity[] = [];

    @observable ticketsLimit: number = null;

    @observable hasDiscount = false;
    @observable hasPolicyCode = false;
    @observable hasPolicyDiscount = false;

    @observable discountApplied = false;
    @observable appliedCode: string = null;

    @observable error: Error = null;

    @observable ratioFee = 0;

    // order
    @observable order: Map<string, TicketEntity> = new Map();

    // discount
    @observable isDiscountLoading = false;

    @observable policies: IDiscountPoliciesResponse = null;

    @observable code: string = null;

    // api
    private readonly apiService: EventsApiService;

    private readonly discountApiService: DiscountApiService;

    constructor() {
        this.apiService = diContainer.get(EventsApiService);
        this.discountApiService = diContainer.get(DiscountApiService);
    }

    createModels(data: ITicket[], eventId: number): TicketEntity[] {
        return data.map((v) => {
            const currentCount = this.order.get(v.id)?.count;
            return new TicketEntity(v, eventId, currentCount);
        });
    }

    @action.bound
    async getEventTickets(id: number, discountCode?: string, keepExisting?: boolean): Promise<void> {
        this.isLoading = true;
        this.error = null;
        this.appliedCode = null;

        if (!keepExisting) {
            this.tickets = [];
        }

        try {
            const {
                tickets,
                eventRegType,
                hasPromocode,
                hasPolicyPromocode,
                hasPolicyDiscount,
                promocodeOK,
                additionalComission = 0,
                ticketsLimit,
            } = await this.apiService.getTickets(id, discountCode);

            runInAction(() => {
                this.tickets = this.createModels(tickets, id);
                this.eventRegType = eventRegType;
                this.hasDiscount = hasPromocode;
                this.hasPolicyCode = hasPolicyPromocode;
                this.hasPolicyDiscount = hasPolicyDiscount;
                this.discountApplied = promocodeOK;
                this.ratioFee = additionalComission;
                this.ticketsLimit = ticketsLimit;
            });

            if (this.hasPolicyDiscount) {
                await this.getDiscountPolicies(id);
            }

            if (this.tickets.length === 1 && !this.tickets[0].isSeatmapTicket && this.orderTickets.length === 0) {
                this.selectTicket(this.tickets[0], this.tickets[0].minOrder);
            }

            if (this.discountApplied) {
                runInAction(() => {
                    this.appliedCode = discountCode;
                });

                this.tickets.forEach((ticket) => {
                    if (ticket.isPromocodeTicket) {
                        this.selectTicket(ticket, ticket.minOrder);
                    }
                });
            }
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @computed
    get isSingleReg(): boolean {
        return this.eventRegType === EventReg.singlereg;
    }

    @computed
    get isMultianket(): boolean {
        return this.eventRegType === EventReg.multianket;
    }

    @computed
    get eventTickets(): TicketEntity[] {
        return this.tickets.filter(({isSeatmapTicket}) => !isSeatmapTicket);
    }

    @computed
    get seatmapTickets(): TicketEntity[] {
        return this.tickets.filter(({isSeatmapTicket}) => isSeatmapTicket);
    }

    private isTicketForSameOrderEvent(ticket: TicketEntity): boolean {
        return this.orderTickets.every((orderTicket) => orderTicket.eventId === ticket.eventId);
    }

    private doesOrderTicketsHaveNoCount(): boolean {
        return this.orderTickets.every((ticket) => !ticket.count);
    }

    // order
    // управление(increment/decrement) обычными билетами в order
    @action.bound
    selectTicket(ticket: TicketEntity, count: number): void {
        if (!this.isTicketForSameOrderEvent(ticket)) {
            this.wipeOrder();
        }

        if (!this.order.has(ticket.id)) {
            this.order.set(ticket.id, ticket);
        }

        this.order.get(ticket.id).setCount(count);

        if (this.doesOrderTicketsHaveNoCount()) {
            this.wipeOrder();
        }
    }

    // управление обычными/seatmap билетами для singlereg регистрации
    @action.bound
    selectSingleregTicket(ticket: TicketEntity, count: number): void {
        if (!this.order.has(ticket.id)) {
            this.order.set(ticket.id, ticket);
        }
        this.order.forEach((ticket) => {
            ticket.setCount(0);
        });

        this.order.get(ticket.id).setCount(count);
    }

    // управление(increment/decrement) seatmap билетов в order
    @action.bound
    selectSeatmapTicket(ticket: TicketEntity, count: number): void {
        if (!this.order.has(ticket.id)) {
            this.order.set(ticket.id, ticket);
        }

        this.order.get(ticket.id).setCount(count);
    }

    // удаление seatmap билетов из order
    @action.bound
    deleteSeatmapTicket(ticket: TicketEntity): void {
        if (this.order.has(ticket.id)) {
            this.order.delete(ticket.id);
        }
    }

    @action.bound
    wipeOrder(): void {
        this.order.clear();
        this.tickets.forEach((ticket) => ticket.setCount(0));
    }

    @computed
    get orderTickets(): TicketEntity[] {
        return [...this.order.values()];
    }

    @computed
    get seatmapOrderTickets(): TicketEntity[] {
        return this.orderTickets.filter(({isSeatmapTicket}) => isSeatmapTicket);
    }

    @computed
    get eventOrderTickets(): TicketEntity[] {
        return this.orderTickets.filter(({isSeatmapTicket}) => !isSeatmapTicket);
    }

    @action.bound
    setOrder(order: Map<string, TicketEntity>): void {
        this.order = order;
    }

    // этот метод используется для аналитики
    @computed
    get joinedTicketsByTicketId(): TicketEntity[] {
        return Object.values(
            this.orderTickets.reduce((acc: Record<string, TicketEntity>, item) => {
                const {ticketId, count} = item;
                if (!acc[ticketId]) {
                    // Делаю клон объекта ибо получаю ошибку mobx
                    acc[ticketId] = Object.assign({}, item);
                } else {
                    acc[ticketId].count += count;
                }
                return acc;
            }, {}),
        );
    }

    // окончательно сформированный order, который отправляем на сервер
    @computed
    get finalOrderTickets(): IRegistrationTicketData[] {
        return this.orderTickets.flatMap(({count, seatKey, ticketId}) =>
            Array(count).fill({
                ticketCategoryId: Number(ticketId),
                bookingSeat: seatKey,
            }),
        );
    }

    @computed
    get orderEventId(): number {
        if (!!this.orderTickets.length) {
            return this.orderTickets[0].eventId;
        }
        if (!!this.tickets.length) {
            return this.tickets[0].eventId;
        }
        return null;
    }

    // discount
    @action.bound
    async getDiscountPolicies(eventId: number, code?: string): Promise<void> {
        //делаю проверку на эти поля чтобы дважды не отправлялся запрос
        if (!this.situation.selectedTicketCategories?.length || !this.situation.selectedTickets?.length) return;
        this.isDiscountLoading = true;
        this.error = null;
        this.code = code;

        try {
            const policies = await this.discountApiService.getDiscountPolicies(eventId, this.situation);
            runInAction(() => {
                this.policies = policies;
            });
            // TODO: отрефачить эту магию
            this.policies.pricing.ticket_types.forEach((category) => {
                const correspondingTicket = this.tickets.find(
                    (eventTicket) => eventTicket.id === String(category.cat_id),
                );
                const ticket = this.order.get(String(category.cat_id));
                if (
                    category.nominal !== correspondingTicket?.price ||
                    category.nominal !== correspondingTicket?.policiesPrice
                ) {
                    correspondingTicket.setPoliciesPrice(category.nominal);
                    ticket?.setPoliciesPrice(category.nominal);
                }

                if (!!category.discount) {
                    ticket?.setDiscountAmount(category.discount);
                } else {
                    ticket?.setDiscountAmount(null);
                }
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        } finally {
            runInAction(() => {
                this.isDiscountLoading = false;
            });
        }
    }

    @action.bound
    async refreshCurrentPolicies(): Promise<void> {
        if (!this.orderEventId) return;
        const promocodes = this.policies?.applied_promocodes || [];
        const promocode = promocodes.length ? promocodes[0] : undefined;
        await this.getDiscountPolicies(this.orderEventId, promocode);
    }

    @computed
    get situation(): IDiscountPoliciesSituation {
        return {
            promocodes: this.code ? [this.code] : [],
            selectedTicketCategories: this.tickets.map((eventTicket) => {
                const ticketsCount = this.orderTickets
                    .filter((ticket) => ticket.ticketId === eventTicket.id)
                    .reduce((acc, el) => acc + el?.count, 0);
                return {
                    ['category_id']: Number(eventTicket.id),
                    ['price']: eventTicket.price,
                    ['category_name']: eventTicket.name,
                    ['tickets_count']: ticketsCount || 0,
                };
            }),
            selectedTickets: this.orderTickets.reduce((acc, ticket) => {
                return [
                    ...acc,
                    ...[...Array(ticket.count).keys()].map(() => ({
                        ['category_id']: Number(ticket.ticketId),
                        ['price']: ticket.price,
                        ['category_name']: ticket.name,
                        ['meta']: '',
                    })),
                ];
            }, []),
        };
    }

    @computed
    get showPromocodeSummationError(): boolean {
        const discount = this.policies?.effects?.find((el) => el[0] === PolicyType.discount)?.[1];
        const alterCategoryPrice = this.policies?.effects?.find((el) => el[0] === PolicyType.alterCategoryPrice)?.[1];
        return discount && alterCategoryPrice && discount.amount < alterCategoryPrice.amount;
    }

    @computed
    get isMaxTicketsSelected(): boolean {
        const totalCount = this.orderTickets.reduce((acc, ticket) => {
            return acc + ticket.count;
        }, 0);

        return totalCount === this.ticketsLimit;
    }
}
