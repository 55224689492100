import {useCallback} from 'react';
import {useScript} from 'front-components';
import {IOrganizationAnalyticServices} from '../../../interfaces/models';
import {initGA4, initYandexMetrika} from '../../Helpers/AnalyticsHelper';

export const useAnalytics = (services: IOrganizationAnalyticServices): void => {
    const initYandexMetrikaCallback = useCallback(() => {
        initYandexMetrika(services.yandexMetric);
        //подключаем нашу янедкс метрику
        if (process.env.ANALYTICS_ENABLE) {
            initYandexMetrika(process.env.YANDEX_METRIKA_COUNTER);
        }
    }, [services.yandexMetric]);

    const initGA4Callback = useCallback(() => {
        initGA4(services.googleAnalytics);
        //подключаем нашу google аналитику
        if (process.env.ANALYTICS_ENABLE) {
            initGA4(process.env.GOOGLE_ANALYTICS_4);
        }
    }, [services.googleAnalytics]);

    //YandexMetrika
    useScript({
        src: 'https://mc.yandex.ru/metrika/tag.js',
        callback: initYandexMetrikaCallback,
    });

    //GA4
    useScript({
        src: `https://www.googletagmanager.com/gtag/js?id=${services.googleAnalytics}`,
        callback: initGA4Callback,
    });
};
