import {CancelToken, CancelTokenSource} from 'axios';
import {ITicketResponse} from 'stores/Ticket/Ticket';
import {IEvent, IRegistrationPayload, IRegistration, IRegistrationInfo} from 'interfaces/models';
import {BaseApiService, IPagingRequest, IResponseBase} from './base/BaseApiService';
import {IEventCityFilter, getLocation} from './DictionariesApiService';

export interface IEventQueryParams extends IPagingRequest {
    city: IEventCityFilter;
    startDate: string;
    endDate: string;
    minPrice: number;
    maxPrice: number;
    categoryIds: number[];
    searchQuery: string;
    orgIds: number[];
    ids: number[];
}

export interface IEventsResponse {
    events: IEvent[];
    total?: number;
    offset: IPagingRequest['offset'];
}

export class EventsApiService extends BaseApiService {
    private cancelTokenSources = new Map<string, CancelTokenSource>();
    constructor() {
        super('events');
    }

    async getEvent(id: number): Promise<IEvent> {
        const {data}: {data: IResponseBase<IEvent>} = await this.get({}, `/${id}`);
        return data.data;
    }

    async getSeeAlsoEvents(id: number): Promise<IEventsResponse> {
        const {data}: {data: IResponseBase<IEventsResponse>} = await this.get({}, `${id}/seealso`);
        return data.data;
    }

    async getTickets(id: number, discountCode?: string): Promise<ITicketResponse> {
        const {data} = await this.get({promocode: discountCode}, `/${id}/tickets`);
        return data as ITicketResponse;
    }

    async registerOnEvent(payload: IRegistrationPayload): Promise<IRegistration> {
        const {data} = await this.post(payload, `/${payload.eventId}/register`);
        return data as IRegistration;
    }

    async getEventRegistrationForm(eventId: number): Promise<IRegistrationInfo> {
        const {data} = await this.get({}, `/${eventId}/register-form`);
        return data as IRegistrationInfo;
    }

    async getEvents(params: Partial<IEventQueryParams>): Promise<IEventsResponse> {
        const cancelKey = JSON.stringify(params);

        this.cancelRequest(cancelKey);
        const token = this.getCancelToken(cancelKey);

        const {city = {} as Pick<IEventCityFilter, 'id' | 'type'>, ...rest} = params;
        const location = getLocation(city);
        const {data}: {data: IResponseBase<IEventsResponse>} = await this.get({...rest, ...location}, null, token);
        this.deleteCancelTokenSource(cancelKey);

        return data.data;
    }

    private cancelRequest(key: string): void {
        if (this.cancelTokenSources.has(key)) {
            this.cancelTokenSources.get(key).cancel();
            this.deleteCancelTokenSource(key);
        } else {
            this.cancelTokenSources.set(key, this.cancelAxiosToken.source());
        }
    }

    private getCancelToken(key: string): CancelToken | null {
        if (this.cancelTokenSources.has(key)) {
            return this.cancelTokenSources.get(key).token;
        }
        return null;
    }

    private deleteCancelTokenSource(key: string): void {
        this.cancelTokenSources.delete(key);
    }
}

export const eventsApi = new EventsApiService();
