import {IRefundModalProps} from '../../components/Modal/Refund/RefundModal';
import {ModalInstance} from './ModaInstance';
import {observable} from 'mobx';
import {IOrderModalProps} from 'components/Modal/Order/OrderModal';
import {IMapModalProps} from 'components/Modal/Map/MapModal';
import {IEventFeedbackModalProps} from 'components/Modal/EventFeedback';
import {ITimeslotsModalProps} from 'components/Modal/Timeslots/TimeslotsModal';
import {ITicketsModalProps} from 'components/Modal/Tickets/TicketsModal';
import {IQuickTicketsModalProps} from 'components/Modal/QuickTickets/QuickTicketsModal';
import {IQRModalProps} from 'components/Modal/QR/QRModal';
import {IRefundSuccessModalProps} from 'components/Modal/Refund/RefundSuccessModal';
import {IResendTicketsModalProps} from 'components/Modal/ResendTickets/ResendTicketsModal';
import {IOrgAdditionalInfoModalProps} from 'components/Modal/OrgAdditionalInfo';
import {IAccessToContentModalProps} from 'components/Modal/AccessToContent/AccessToContentModal';
import {IProfileModalProps} from '../../components/Form/Profile/Profile';
import {ISeatmapSchemeModalProps} from 'components/Modal/SeatmapScheme';

type IModalParameters = {
    [key in ModalWindow]: object;
};

export enum ModalWindow {
    profile = 'PROFILE_MODAL',
    auth = 'AUTH_MODAL',
    complaint = 'COMPLAINT_MODAL',
    eventFeedback = 'EVENT_FEEDBACK_MODAL',
    map = 'MAP_MODAL',
    timeslots = 'TIMESLOTS_MODAL',
    order = 'ORDER_MODAL',
    tickets = 'TICKETS_MODAL',
    quickTickets = 'QUICK_TICKETS_MODAL',
    checkout = 'CHECKOUT_MODAL',
    payment = 'PAYMENT_MODAL',
    qr = 'QR_MODAL',
    refund = 'REFUND_MODAL',
    refundSuccess = 'REFUND_SUCCESS_MODAL',
    resendTickets = 'RESEND_TICKETS_MODAL',
    orgUrInfo = 'ORG_UR_INFO_MODAL',
    accessToContent = 'ACCESS_TO_CONTENT_MODAL',
    seatmapScheme = 'SEATMAP_SCHEME',
}

export interface IModalConfigs extends IModalParameters {
    [ModalWindow.profile]: IProfileModalProps;
    [ModalWindow.auth]: {};
    [ModalWindow.complaint]: {};
    [ModalWindow.eventFeedback]: IEventFeedbackModalProps;
    [ModalWindow.map]: IMapModalProps;
    [ModalWindow.timeslots]: ITimeslotsModalProps;
    [ModalWindow.order]: IOrderModalProps;
    [ModalWindow.tickets]: ITicketsModalProps;
    [ModalWindow.quickTickets]: IQuickTicketsModalProps;
    [ModalWindow.checkout]: {};
    [ModalWindow.qr]: IQRModalProps;
    [ModalWindow.refund]: IRefundModalProps;
    [ModalWindow.refundSuccess]: IRefundSuccessModalProps;
    [ModalWindow.resendTickets]: IResendTicketsModalProps;
    [ModalWindow.orgUrInfo]: IOrgAdditionalInfoModalProps;
    [ModalWindow.accessToContent]: IAccessToContentModalProps;
    [ModalWindow.seatmapScheme]: ISeatmapSchemeModalProps;
}

export interface IModalResult<T> {
    result: T;
}

export interface IModalInstance<T extends ModalWindow> {
    type: ModalWindow;
    opened: boolean;
    props: IModalConfigs[T];
    open: <V>(props: IModalConfigs[T]) => Promise<IModalResult<V>>;
    close: () => void;
    start?: <V>() => Promise<IModalResult<V>>;
    resolve: (value?: IModalResult<unknown>) => void;
}

export class ModalStore {
    @observable modal: IModalInstance<ModalWindow>;

    @observable modals: {[T in ModalWindow]?: ModalInstance<T>} = {
        [ModalWindow.profile]: new ModalInstance(ModalWindow.profile),
        [ModalWindow.auth]: new ModalInstance(ModalWindow.auth),
        [ModalWindow.complaint]: new ModalInstance(ModalWindow.complaint),
        [ModalWindow.eventFeedback]: new ModalInstance(ModalWindow.eventFeedback),
        [ModalWindow.map]: new ModalInstance(ModalWindow.map),
        [ModalWindow.timeslots]: new ModalInstance(ModalWindow.timeslots),
        [ModalWindow.order]: new ModalInstance(ModalWindow.order),
        [ModalWindow.tickets]: new ModalInstance(ModalWindow.tickets),
        [ModalWindow.quickTickets]: new ModalInstance(ModalWindow.quickTickets),
        [ModalWindow.checkout]: new ModalInstance(ModalWindow.checkout),
        [ModalWindow.qr]: new ModalInstance(ModalWindow.qr),
        [ModalWindow.refund]: new ModalInstance(ModalWindow.refund),
        [ModalWindow.refundSuccess]: new ModalInstance(ModalWindow.refundSuccess),
        [ModalWindow.resendTickets]: new ModalInstance(ModalWindow.resendTickets),
        [ModalWindow.orgUrInfo]: new ModalInstance(ModalWindow.orgUrInfo),
        [ModalWindow.accessToContent]: new ModalInstance(ModalWindow.accessToContent),
        [ModalWindow.seatmapScheme]: new ModalInstance(ModalWindow.seatmapScheme),
    };
}
