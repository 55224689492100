import {EventRating} from 'interfaces/models';
import {BaseApiService, IResponseBase} from './base/BaseApiService';

export interface IFeedbackRequest {
    fb_user_role: string;
    fb_fromname: string;
    fb_from: string;
    fb_from_f: string;
    fb_subject: string;
    fb_message: string;
    fb_ata?: string;
}

export interface IFeedbackResponse {
    result: string;
    text?: string;
    ticket?: number;
}

export interface IReviewRequest {
    event_id: number;
    rating: EventRating;
    ip: string;
    comment?: string;
}

export class FeedbackApiService extends BaseApiService {
    constructor() {
        super('feedback');
    }

    async sendFeedback(request: IFeedbackRequest): Promise<IFeedbackResponse> {
        const {data} = await this.post(request);
        return data as IFeedbackResponse;
    }

    async sendReview(request: IReviewRequest): Promise<IResponseBase['result']> {
        const {data} = await this.post(request, 'event');
        return data as IResponseBase['result'];
    }
}
