// Мини-модернизр, можно расширять
export const browserSupportCheck = () => {
    let cssMaxSupport = false;
    try {
        cssMaxSupport = CSS.supports('width', 'max(0, 0');
    } catch (err) {
        cssMaxSupport = false;
    }
    if (!cssMaxSupport) {
        document.documentElement.className += ' no-max';
    }
};

export function isMobileAndroidOrIphone() {
    return /Mobile|Android|iPhone/i.test(navigator.userAgent);
}

// @see https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export function isIOS(): boolean {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

// maxTouchPoints works on IE10/11 and Surface
export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints;
