import axios, {AxiosInstance, AxiosResponse, CancelTokenSource, CancelTokenStatic, CancelToken} from 'axios';
import Sentry, {isSentrySetup} from 'init-sentry';
import {AccessTokenStorage} from 'services/TokenStorage';

type searchAfterType = Array<string | number>;

interface IResponseError {
    message: string;
    detail?: string;
}

export interface IResponseBase<T = Record<string, unknown>> {
    result: 'ok' | 'error';
    data?: T;
    error?: IResponseError;
}

export interface IPagingRequest {
    /* по-умолчанию можно ничего не отправлять,
	     тогда присылается весь список (если не стоит ограничения на сервере) */
    offset?: number | searchAfterType; // от какого элемента отсчет, обычно прибавляется размер страницы
    limit?: number; // обычно размер страницы
}

interface IHeaders {
    [key: string]: string | boolean;
}

interface IWindowWithApiExports extends Window {
    ntpApiServices?: {
        [serviceName: string]: {
            saveLocalBaseUrl: (url: string) => void;
            clearLocalbaseUrl: () => void;
            loadBaseUrl: () => void;
            getBaseUrl: () => string;
        };
    };
}

export class BaseApiService {
    private readonly httpService: AxiosInstance;
    cancelAxiosToken: CancelTokenStatic = axios.CancelToken;
    sourceAxios: CancelTokenSource;

    private baseUrl: string;

    private readonly endpoint: string;

    private errorHandlers: Array<Function>;

    constructor(endpoint: string, baseUrl?: string) {
        this.errorHandlers = [];
        this.endpoint = endpoint;

        this.loadBaseUrl(baseUrl);

        this.httpService = axios.create({
            baseURL: this.baseUrl,
        });

        this.exportToWindow();
    }

    handleCancelRequest = () => {
        this.sourceAxios.cancel('Request was canceled');
    };

    saveLocalBaseUrl(url: string) {
        localStorage.setItem(`ntp_${this.endpoint}_api_service__base_url`, url);
    }

    clearLocalbaseUrl() {
        localStorage.removeItem(`ntp_${this.endpoint}_api_service__base_url`);
    }

    loadBaseUrl(baseUrl?: string) {
        /* eslint-disable no-console */

        const localBaseUrl = localStorage.getItem(`ntp_${this.endpoint}_api_service__base_url`);

        if (localBaseUrl) {
            console.warn(`[${this.endpoint} api]: WARNING! CUSTOM api url is using now: ${localBaseUrl}`);
        }

        this.baseUrl = localBaseUrl || baseUrl || process.env.REACT_APP_API_BASE_URL || '/';
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    exportToWindow() {
        const w = window as IWindowWithApiExports;

        if (w) {
            if (!w.ntpApiServices) {
                w.ntpApiServices = {};
            }

            w.ntpApiServices[this.endpoint] = {
                saveLocalBaseUrl: this.saveLocalBaseUrl.bind(this),
                clearLocalbaseUrl: this.clearLocalbaseUrl.bind(this),
                loadBaseUrl: this.loadBaseUrl.bind(this),
                getBaseUrl: this.getBaseUrl.bind(this),
            };
        }
    }

    //если меняешь тут что-то — не забудь поправить resetHeadersData!
    createHeaders(): IHeaders {
        const headers: IHeaders = {},
            accessToken = AccessTokenStorage.accessToken;

        if (accessToken) {
            headers.Authorization = accessToken;
        }

        return headers;
    }

    resetHeadersData() {
        AccessTokenStorage.removeAccessToken();
    }

    addErrorHandler(handler: Function) {
        this.errorHandlers.push(handler);

        return () => {
            this.errorHandlers = this.errorHandlers.filter((_handler) => _handler !== handler);
        };
    }

    //TODO ебать, откуда такая уверенность что сюда попадёт именно AxiosResponse, мы же просто обернули в try-catch целую огромную фигню
    _invokeErrorHandlers(error: AxiosResponse) {
        return Promise.all(this.errorHandlers.map((handler) => handler(error))).then(() => Promise.resolve(error));
    }

    getCompositeURL(postfix?: string): string {
        const fragments = [this.endpoint];

        if (postfix) {
            fragments.push(postfix);
        }

        return fragments.join('/').replace(/\/\//g, '/');
    }

    cloneParams(params) {
        return JSON.parse(JSON.stringify(params));
    }

    async processError(e) {
        //убеждаемся, что у ошибки есть status, data и headers
        //в этом случае это скорее всего AxiosResponse
        //
        //Как это можно улучшить? AxiosResponse — это интерфейс, поэтому использовать instanceof не выйдет
        //Можно было бы написаь схему для yup,
        //но тогда всё может сломаться при обновлении Axios, а мы и не заметим без юнит-тестов
        //
        //поэтому пока так
        if (isSentrySetup) {
            Sentry.captureException(e);
        }
        if ((e && e.response && e.response.status && e.response.data && e.response.headers) || e.response.status) {
            await this._invokeErrorHandlers(e);

            //TODO сейчас не все сторы, вызывающие API, нормально обрабатывают эти ошибки, нужно это починить
            //TODO наверх надо баблить ошибку целиком, потому что это может быть не только AxiosError и из-за этого возникает куча вопросов
            throw e.response;
        } else {
            //если мы оказались тут — скорее всего что-то серьезно не так, попробуем понять что
            if (
                e.name == 'TypeError' &&
                e.message &&
                e.message.indexOf &&
                e.message.indexOf('Value is not a valid ByteString') !== -1
            ) {
                //это проблема с заголовками, скорее всего испорченный accessToken
                //пробуем отправить сообщение об испорченном accessToken в грейлог напрямую

                //чистим всё, что отправляется в заголовки
                this.resetHeadersData();

                //TODO сейчас не все сторы, вызывающие API, нормально обрабатывают эти ошибки, нужно это починить
                throw e;
            } else {
                //случилась проблема непонятного рода, отправляем ошибку в консоль, просим отправить скриншот в саппорт
                console.error(
                    'Пожалуйста, сделайте скриншот и напишите к нам в службу поддержки на support@timepad.ru.',
                    `API endpoint ${this.endpoint}:`,
                    e,
                );

                //TODO сейчас не все сторы, вызывающие API, нормально обрабатывают эти ошибки, нужно это починить
                throw e;
            }
        }
    }

    async get(params, postfix?: string, token?: CancelToken, headers?: IHeaders, withCredentials = false) {
        try {
            const url = this.getCompositeURL(postfix);

            const {data, status} = await this.httpService.get(url, {
                params,
                headers: {...this.createHeaders(), ...headers},
                cancelToken: token,
                withCredentials,
            });

            return {data, status};
        } catch (e) {
            await this.processError(e);
        }
    }

    async post(payload, postfix?: string) {
        try {
            const url = this.getCompositeURL(postfix);

            return await this.httpService.post(url, payload, {
                headers: this.createHeaders(),
            });
        } catch (e) {
            await this.processError(e);
        }
    }

    async put(payload, postfix?: string) {
        try {
            const url = this.getCompositeURL(postfix);

            return await this.httpService.put(url, payload, {
                headers: this.createHeaders(),
            });
        } catch (e) {
            await this.processError(e);
        }
    }

    async delete(postfix?: string) {
        try {
            const url = this.getCompositeURL(postfix);

            const {data, status} = await this.httpService.delete(url, {
                headers: this.createHeaders(),
            });

            return {data, status};
        } catch (e) {
            await this.processError(e);
        }
    }
}
