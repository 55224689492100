import {configure} from 'mobx';

import {AuthStore} from './Auth';
import {ModalStore} from './Modal';
import {
    UserApiService,
    EventsApiService,
    FavoritesApiService,
    TrackApiService,
    CategoriesApiService,
    CollectionApiService,
    RefundApiService,
    UtilApiService,
    OrganizationsApiService,
    FeedbackApiService,
    PaymentApiService,
    DictionariesApiService,
    OrdersApiService,
} from 'services/Api';
import {DiscountApiService} from 'services/Api/DiscountApiService';
import {ContentApiService} from 'services/Api/ContentApiService';
import {diContainer} from 'timepad-di';

configure({
    enforceActions: 'observed',
    // observableRequiresReaction: true, // TODO: провести ревизию по приложению с этим флагом
});

export class RootStore {
    modalStorage: ModalStore;

    constructor() {
        const authStorage = diContainer.get(AuthStore);

        const collectionApiService = diContainer.get(CollectionApiService);
        const categoriesApiService = diContainer.get(CategoriesApiService);
        const userApiService = diContainer.get(UserApiService);
        const eventsApiService = diContainer.get(EventsApiService);
        const favoritesApiService = diContainer.get(FavoritesApiService);
        const trackApiService = diContainer.get(TrackApiService);
        const contentApiService = diContainer.get(ContentApiService);
        const refundApiService = diContainer.get(RefundApiService);
        const utilApiService = diContainer.get(UtilApiService);
        const organizationApiService = diContainer.get(OrganizationsApiService);
        const feedbackApiService = diContainer.get(FeedbackApiService);
        const discountApiService = diContainer.get(DiscountApiService);
        const paymentApiService = diContainer.get(PaymentApiService);
        const dictionariesApiService = diContainer.get(DictionariesApiService);
        const ordersApiService = diContainer.get(OrdersApiService);

        // const errorStorage = new ErrorStore()

        // add all api objects error handler to logout on forbidden and unauthorized status
        [
            userApiService,
            eventsApiService,
            favoritesApiService,
            trackApiService,
            contentApiService,
            categoriesApiService,
            collectionApiService,
            refundApiService,
            utilApiService,
            organizationApiService,
            feedbackApiService,
            discountApiService,
            paymentApiService,
            dictionariesApiService,
            ordersApiService,
        ].forEach((api) =>
            api.addErrorHandler((err) => {
                err?.response?.status == 401 && authStorage.logout();
            }),
        );
        //если уж даже пользователя получить не можем, то что-то явно не то
        userApiService.addErrorHandler((err) => {
            err?.response?.status == 403 && authStorage.logout();
        });

        this.modalStorage = new ModalStore();
    }
}

export const stores = new RootStore();
