/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/interface-name-prefix */
declare global {
    interface Window {
        fbq: any;
        _fbq: any;
    }
}

export class FacebookPixel {
    static init(pixelId) {
        if (typeof window !== 'undefined' && !!window.fbq) {
            return;
        }
        // @ts-ignore
        !(function(f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        window.fbq.disablePushState = true;
        window.fbq('init', pixelId);
    }
    static pageView() {
        window.fbq('track', 'Pageview');
    }
    static track(eventName, data) {
        window.fbq('track', eventName, data);
    }
}
