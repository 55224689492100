import React, {FC, PropsWithChildren} from 'react';
import {Link, LinkProps} from 'react-router-dom';

export interface IRichLinkProps extends PropsWithChildren<LinkProps> {
    cancelScroll?: boolean;
    external?: boolean;
}

export const RichLink: FC<IRichLinkProps> = (props) => {
    const {cancelScroll, external, ...linkProps} = props;

    const pathname = typeof linkProps.to === 'string' ? linkProps.to : linkProps.to.pathname;

    return external ? (
        <a
            href={pathname}
            className={linkProps.className}
            target={linkProps.target}
            rel={linkProps.target === '_blank' ? 'noopener noreferrer' : ''}
            data-qa={linkProps['data-qa']}
        >
            {linkProps.children}
        </a>
    ) : (
        <Link {...linkProps} to={pathname} state={{cancelScroll}} />
    );
};
