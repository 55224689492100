import {IOrganization, IContentQueryParams, IRecommendedOrganization} from 'interfaces/models';
import {IOrganizationContentListResponse} from 'stores/Organization/OrganizationStore';
import {BaseApiService, IResponseBase} from './base/BaseApiService';
import {orgContentApi} from './OrgContentApiService';

export class OrganizationsApiService extends BaseApiService {
    constructor() {
        super('organizations');
    }

    async getOrganization(id: string): Promise<IOrganization> {
        const {data}: {data: IResponseBase<IOrganization>} = await this.get({}, `/${id}`);
        return data.data;
    }

    async getAllContentList(id: string, params: IContentQueryParams = {}): Promise<IOrganizationContentListResponse> {
        const requestParams = this.cloneParams(params);
        requestParams.organizationId = id;

        const content = await orgContentApi.getOrganizationContent(requestParams);

        const data = {contentCount: content.total_count, contentList: content.list};

        return data as IOrganizationContentListResponse;
    }

    async getRecommendedOrganizations(): Promise<IRecommendedOrganization[]> {
        const {data}: {data: IResponseBase<IRecommendedOrganization[]>} = await this.get({}, 'recommended');
        return data.data;
    }
}
