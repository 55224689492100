import {observable, computed, action} from 'mobx';
import {CSSProperties} from 'react';
import {OrganizationEntity} from 'stores/Organization';

import {IVideo} from 'interfaces/models';
import {getVideoDuration, getVideoCardDuration} from 'services/Helpers/DateHelper';
import moment from 'moment';

export class VideoEntity implements IVideo {
    constructor(data: IVideo) {
        Object.assign(this, data);
    }

    @observable organization?: OrganizationEntity;
    @observable isAvailableForUser = false;
    @observable isFavorite = false;
    @observable accessMessage?: string;
    @observable id?: string;
    @observable title?: string;
    @observable description?: string;
    @observable status?: string;
    @observable categoryId?: string;
    @observable minPrice?: number;
    @observable language?: string;
    @observable img?: string;
    @observable duration?: string;
    @observable ageLimit?: string;
    @observable isSoftPublished = false;
    @observable type?: string;
    @observable categoryName?: string;
    @observable languageName?: string;
    @observable isBlocked = false;
    @observable ofertaLink = '';
    @observable src = '';
    @observable private = false;

    @computed
    get ageInfo() {
        switch (this.ageLimit) {
            case '1':
                return {title: 'Без ограничений', desc: 'Все могут смотреть'};
            case '2':
                return {title: '6+', desc: 'Подходит для зрителей старше 6 лет'};
            case '3':
                return {title: '12+', desc: 'Подходит для зрителей старше 12 лет'};
            case '4':
                return {title: '16+', desc: 'Подходит для зрителей старше 16 лет'};
            case '5':
                return {title: '18+', desc: 'Только для тех, кому есть 18'};
            default:
                return {title: 'Необходимо уточнение', desc: 'Необходимо уточнение'};
        }
    }

    @computed
    get humanDuration() {
        const duration = moment.duration(this.duration);
        return getVideoDuration(duration);
    }

    get videoDuration() {
        const duration = moment.duration(this.duration);
        return getVideoCardDuration(duration);
    }

    @computed
    get posterStyle(): CSSProperties {
        if (!this.img) {
            return {};
        }

        return {
            backgroundImage: `url(${this.img}-/format/jpeg/-/quality/smart/-/preview/1120x544/`,
        };
    }

    @action.bound
    setIsFavorite(val: boolean) {
        this.isFavorite = val;
    }
}
