export const rbClickParamName = '_vk_rb_clickid';
export const vkPixelToCookie = () => {
    // собираем параметр rb_clickid
    const queryParams = new URLSearchParams(document.location.search);
    const rbClickIdParam = queryParams.get('rb_clickid');

    /* Почему в куках?
       Нам нужно хранение в течение 30 дней, неважно на какое событие пользователь потом пойдет.
       Так же кука ставится на домен орга или афишу, не на основной, что не так засоряет */
    if (rbClickIdParam) {
        const maxAge = 30 * 24 * 60 * 60; // 30 дней в секундах
        document.cookie = `${rbClickParamName}=${rbClickIdParam}; path=/; max-age=${maxAge};`;
    }
};
