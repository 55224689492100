import {BaseApiService} from './base/BaseApiService';


export class OrgContentApiService extends BaseApiService {
    constructor() {
        super('organization');
    }

    async getOrganizationContent(params) {
        const {data} = await this.get(params, `/${params.organizationId}/content`);
        return data;
    }
}

export const orgContentApi = new OrgContentApiService();
